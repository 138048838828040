import React, {Dispatch, Reducer, SetStateAction, createContext, useContext, useEffect, useReducer, useState} from "react";
import { CreatorListItemPayload, CreatorListItemType, CreatorListType, ListInfoUpdatePayload, ListItemPayload, ListItemType, ListVisibilityKey, PopulatedList, UpdateListPayload } from "../types/SharedTypes";
import listCreatorReducer from "../reducers/listCreatorReducer";
import { LIST_CREATOR_ACTIONS } from "../reducers/listCreatorReducerActions";
import { useGetListById, useUpdateList } from "../api/data-access";
import { ToastContext } from "./ToastContext";
import useDisplayServerError from "../hooks/useDisplayServerError";
import { demoListData, localStorageKeys } from "../constants/general";
import {cloneDeep} from "lodash";

export type CreatorState = {
  on: CreatorListType["onList"];
  off: CreatorListType["offList"];
  unplaced: CreatorListType["unplaced"];
}

export enum CREATOR_PAGES {
  on,
  off,
  unplaced
};

type ListCreatorDraft = {
  postId: string;
  creatorState: CreatorState;
  listInfoState?: ListInfoUpdatePayload
}

type CreatorContextValue = {
  creatorState: CreatorState;
  currentTab: Tabs;
  setCurrentTab: Dispatch<SetStateAction<Tabs>>;
  listInfoState?: ListInfoUpdatePayload;
  setListInfoState: Dispatch<SetStateAction<ListInfoUpdatePayload | undefined>>;
  updateItemInformation: (list: CreatorListItemType, page: CREATOR_PAGES) => void;
  createNewItem: (item: CreatorListItemPayload) => void;
  addItemOn: (item: CreatorListItemPayload) => void;
  addItemOff: (item: CreatorListItemPayload) => void;
  addItemUnplaced: (item: CreatorListItemPayload) => void;
  importBatchItems: (items: ListItemType[]) => void;
  moveItemOn: (id: string | undefined, toPosition?: number) => void;
  moveItemOff: (id: string | undefined) => void;
  moveItemUnplaced: (id: string | undefined) => void;
  moveManyItemsOff: (ids: string[]) => void;
  removeItem: (id: string | undefined, page: CREATOR_PAGES) => void;
  reorderItem: (id: string|undefined, newIndex: number, moveItemOn?: boolean) => void;
  updateList: (visibility: ListVisibilityKey, onSuccess?: () => void, onError?: () => void) => void;
  getList: (id?: string) => void;
  clearDraftFromLocal: () => void;
  list: PopulatedList | undefined;
  isListSuccess: boolean;
  isListPending: boolean;
};

export const CreatorContext = createContext<CreatorContextValue>({
  creatorState: {
    on: [],
    off: [],
    unplaced: []
  },
  currentTab: 'on',
  setCurrentTab: () => null,
  listInfoState: undefined,
  setListInfoState: () => null,
  updateItemInformation: () => null,
  createNewItem: () => null,
  addItemOn: () => null,
  addItemOff: () => null,
  addItemUnplaced: () => null,
  importBatchItems: () => null,
  moveItemOn: () => null,
  moveItemOff: () => null,
  moveItemUnplaced: () => null,
  moveManyItemsOff: () => null,
  removeItem: () => null,
  reorderItem: () => null,
  updateList: () => null,
  getList: () => null,
  clearDraftFromLocal: () => null,
  list: undefined,
  isListSuccess: false,
  isListPending: false
});

type Tabs = "on" | "off";

export const CreatorContextProvider = ({children, demo}: {children: React.ReactNode, demo?: boolean}) => {
  
  const {requestToast} = useContext(ToastContext);
  const {handleError} = useDisplayServerError();
  
  const [creatorState, creatorDispatch] = useReducer<Reducer<CreatorState, any>>(listCreatorReducer, {
    on: [],
    off: [],
    unplaced: []
  });
  const [currentTab, setCurrentTab] = useState<Tabs>("on");
  const [listInfoState, setListInfoState] = useState<ListInfoUpdatePayload>();
  const [postId, setPostId] = useState<string | null>(null);
  
  const {data: list, isSuccess: isListSuccess, isPending: isListPending} = useGetListById(postId || "", (!!postId && !demo));
  
  const updateListMutation = useUpdateList();

  useEffect(() => {
    if (demo) {
      initListData({...demoListData})
    }
  }, [demo]);
  
  useEffect(() => {
    if (isListSuccess) {
      const listNew = cloneDeep(list);
      initListData(listNew);
    }
  }, [isListSuccess]);
  
  useEffect(() => {
    console.log('creatorstate', creatorState);
  }, [creatorState]);
  
   useEffect(() => {
    console.log('listinfochange', listInfoState);
  }, [listInfoState]);
  
  useEffect(() => {
    console.log("saving to local drafts");
    saveDraftToLocal();
  }, [creatorState, listInfoState]);
  
  const saveDraftToLocal = () => {
    if (!postId) return;
    const update: ListCreatorDraft = {postId, creatorState, listInfoState};
    window.localStorage.setItem(localStorageKeys.listCreatorDraft, JSON.stringify(update));
  }
  
  const getDraftFromLocal = (postId: string) => {
    const _currentDraft = window.localStorage.getItem(localStorageKeys.listCreatorDraft);
    if (!_currentDraft) return null;
    const currentDraft: ListCreatorDraft = JSON.parse(_currentDraft);
    if (currentDraft.postId === postId) {
      requestToast('Changes restored from local draft. Make sure to save your changes.', 'warn');
      return currentDraft;
    }
    else if (currentDraft.postId === 'demo') {
      //intent is set if user selects "sign up to save list" in demo
      const intent = window.localStorage.getItem(localStorageKeys.intentToSaveDemo);
      if (!intent) return null;
      window.localStorage.removeItem(localStorageKeys.intentToSaveDemo);
      requestToast('Demo restored. Make sure to save your changes.', 'warn');
      return currentDraft;
    }
    return null;
  }
  
  const clearDraftFromLocal = () => {
    const _currentDraft = window.localStorage.getItem(localStorageKeys.listCreatorDraft);
    if (!postId || !_currentDraft) return;
    const currentDrafts: ListCreatorDraft = JSON.parse(_currentDraft);
    if (currentDrafts.postId === postId) window.localStorage.removeItem(localStorageKeys.listCreatorDraft);
  }
  
  const getList = (id?: string) => {
    setPostId(id || null);
  }
  
  const initListData = (list: PopulatedList) => {
    
    const draftData = getDraftFromLocal(list._id);
    
    const listInfo: ListInfoUpdatePayload = {
      isDraft: list.isDraft,
      isPublic: list.isPublic,
      ascending: list.ascending,
      ordered: list.ordered,
      description: list.description,
      title: list.title,
      category: list.category,
      subCategories: list.subCategories,
      theme: list.theme,
      thumbnailEntry: list.thumbnailEntry,
      image: list.image
    }
    
    setListInfoState(draftData?.listInfoState || listInfo);
    creatorDispatch({
      type: LIST_CREATOR_ACTIONS.INIT_DATA, 
      payload: {list: draftData?.creatorState || list, isLocalDraft: !!draftData}
    });
  }
  
  const createNewItem = (item: CreatorListItemPayload) => {
    if (currentTab === 'on') creatorDispatch({type: LIST_CREATOR_ACTIONS.ADD_ITEM_ON, payload: item});
    else creatorDispatch({type: LIST_CREATOR_ACTIONS.ADD_ITEM_OFF, payload: item});
  }
  const addItemOn = (item: CreatorListItemPayload) => {
    creatorDispatch({type: LIST_CREATOR_ACTIONS.ADD_ITEM_ON, payload: item});
  }
  const addItemOff = (item: CreatorListItemPayload) => {
    creatorDispatch({type: LIST_CREATOR_ACTIONS.ADD_ITEM_OFF, payload: item});
  }
  const addItemUnplaced = (item: CreatorListItemPayload) => {
    creatorDispatch({type: LIST_CREATOR_ACTIONS.ADD_ITEM_UNPLACED, payload: item});
  }
  const importBatchItems = (items: ListItemType[]) => {
    creatorDispatch({type: LIST_CREATOR_ACTIONS.IMPORT_BATCH_ITEMS, payload: items});
  }
  
  const updateItemInformation = (item: CreatorListItemType, page: CREATOR_PAGES) => {
    const id = item._id || item.localId || undefined;
    if (!id) return;
    creatorDispatch({type: LIST_CREATOR_ACTIONS.UPDATE_ITEM, payload: {item, page, id}});
  }
  
  const moveItemOn = (id?: string, toPosition?: number) => {
    if (!id) return;
    creatorDispatch({type: LIST_CREATOR_ACTIONS.MOVE_ITEM_ON, payload: {id, toPosition}});
  }
  const moveItemOff = (id?: string) => {
    if (!id) return;
    creatorDispatch({type: LIST_CREATOR_ACTIONS.MOVE_ITEM_OFF, payload: id});
  };
  const moveItemUnplaced = (id?: string) => {
    if (!id) return;
    creatorDispatch({type: LIST_CREATOR_ACTIONS.MOVE_ITEM_UNPLACED, payload: id});
  };
  const moveManyItemsOff = (ids: string[]) => {
    creatorDispatch({type: LIST_CREATOR_ACTIONS.MOVE_MANY_ITEMS_OFF, payload: ids});
  };
  
  const removeItem = (id: string | undefined, page: CREATOR_PAGES) => {
    creatorDispatch({type: LIST_CREATOR_ACTIONS.REMOVE_ITEM, payload: {id, page}});
  }
  const reorderItem = (id: string | undefined, newIndex: number, moveToOn?: boolean) => {
    creatorDispatch({type: LIST_CREATOR_ACTIONS.REORDER_ON_LIST, payload: {id, newIndex, moveToOn}})
  }

  const updateList = (visibility: ListVisibilityKey, onSuccessEffects?: () => void, onErrorEffects?: () => void) => {
    
    if (!postId || !listInfoState || demo) return;
    
    const newVisibility = {
      isDraft: visibility === 'publish' ? false : true,
    };
    const newImage = {
      image: listInfoState.image || creatorState.on.find(item => item.position === listInfoState.thumbnailEntry)?.image || undefined
    }
    
    setListInfoState(prev => !prev ? prev : ({...prev, ...newVisibility}));
    
    const listItemChanges: ListItemPayload[] = [...creatorState.on, ...creatorState.off, ...creatorState.unplaced];
    const updatedList: UpdateListPayload = {
      id: postId,
      update: {
        info: {...listInfoState, ...newVisibility, ...newImage},
        items: listItemChanges
      }
    };
    
    updateListMutation.mutate(updatedList, {
      onSuccess(data, variables, context) {
        console.log("Update list success");
        onSuccessEffects && onSuccessEffects();
        clearDraftFromLocal();
      },
      onError: (err) => {
        onErrorEffects && onErrorEffects();
        handleError(err);
      }
    });
  };
  
  return (
    <CreatorContext.Provider value={{
      creatorState,
      currentTab,
      setCurrentTab,
      listInfoState,
      setListInfoState,
      updateItemInformation,
      createNewItem,
      addItemOn,
      addItemOff,
      addItemUnplaced,
      importBatchItems,
      moveItemOn,
      moveItemOff,
      moveItemUnplaced,
      moveManyItemsOff,
      removeItem,
      reorderItem,
      updateList,
      getList,
      clearDraftFromLocal,
      list,
      isListSuccess: demo ? true : isListSuccess,
      isListPending: demo ? false : isListPending
    }}>
      {children}
    </CreatorContext.Provider>
  )
}