import { LogoutOptions } from "@auth0/auth0-react";
import axios from "axios";

const authClient = axios.create();
export const openClient = axios.create();

export const setAxiosAuthHeader = (getToken: () => Promise<string>, doLogout: (options?: LogoutOptions) => void) => {

  authClient.interceptors.request.use(
    async (config) => {
      try {
        const token = await getToken();
        //@ts-ignore
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      } catch (err) {
        doLogout({logoutParams: {returnTo: process.env.REACT_APP_HOME_URL}});
      }
      return config;
    }
  );
  
  authClient.interceptors.response.use(res => res, error => {
    if (error.response.status === 401) {
      console.log('401 happened, will logout');
      doLogout({logoutParams: {returnTo: process.env.REACT_APP_HOME_URL}});
    }
    return Promise.reject(error);
  })
}

export default authClient;