import { useContext, useEffect, useState } from "react";
import themes, { ThemeType, dark, defaultLightTheme, defaultDarkTheme, light } from "../assets/themes";
import { ThemeContext } from "../contexts/ThemeContext";

const rs = document.documentElement.style;
function useThemed() {
  
  const {isDark} = useContext(ThemeContext);
  const theme = isDark ? dark : light;

  useEffect(() => {
    rs.setProperty('--colour-body-base', theme.body);
    rs.setProperty('--colour-text-base', theme.text);
    rs.setProperty('--colour-line-base', theme.text);
    rs.setProperty('--colour-text-secondary-base', theme.textSecondary);
    rs.setProperty('--colour-element-base', theme.element);
    rs.setProperty('--colour-element-text-base', theme.elementText);
    rs.setProperty('--colour-element-subtle-base', theme.elementSubtle);
    rs.setProperty('--colour-subtle-base', theme.subtle);
    rs.setProperty('--colour-disabled', theme.disabled);
    rs.setProperty('--colour-overlay', theme.overlay);
    
    rs.setProperty('--colour-body-blackout-constant', light.body);
    rs.setProperty('--colour-text-blackout-constant', light.text);
    rs.setProperty('--colour-line-blackout-constant', light.text);
    rs.setProperty('--colour-text-secondary-blackout-constant', light.textSecondary);
    rs.setProperty('--colour-element-blackout-constant', light.element);
    rs.setProperty('--colour-element-text-blackout-constant', light.elementText);
    rs.setProperty('--colour-element-subtle-blackout-constant', light.elementSubtle);
    rs.setProperty('--colour-subtle-blackout-constant', light.subtle);
    rs.setProperty('--colour-overlay-light', dark.overlay);
    rs.setProperty('--colour-overlay-dark', light.overlay);
  },[isDark]);
};

export type SpecialThemeConfig = {
  [key: string]: string;
}

export function useSpecialThemed(themeName?: string) {

  const {isDark} = useContext(ThemeContext);
  
  const [specialTheme, setSpecialTheme] = useState<SpecialThemeConfig>();
  
  const setProperties = (theme: ThemeType) => {
    setSpecialTheme({
      '--themed-highlight': theme.highlight,
      '--themed-body': theme.body,
      '--themed-text': theme.text,
      '--themed-text-highlight': theme.highlightText,
      '--themed-line': theme.line,
      '--themed-overlay': theme.isDark ? dark.overlay : light.overlay,
      '--themed-border': theme?.highlightHasBorder 
        ? `1px solid ${theme.text}`
        : "none"
    })
  };

  useEffect(() => {
    let theme: ThemeType | undefined;
    const defaultTheme = isDark ? defaultDarkTheme : defaultLightTheme;
    
    if (!themeName || themeName === 'default') theme = defaultTheme;
    else theme = themes.find(t => t.slug === themeName);
    
    setProperties(theme || defaultTheme);
  },[themeName]);
  
  return specialTheme;
  
};
export default useThemed;