import { PeakLogo } from "../assets/icons";
import PageWrapper from "./PageWrapper";

const Support = () => {
    return (
      <PageWrapper>
        <div className="item-list large padding">
          <div className="logo">
            <PeakLogo />
          </div>
          <p className="heading-small">Questions or concerns?</p>
          <p className="body">Contact support@peaklist.app for help.</p>
        </div>
      </PageWrapper>
    )
  }
  export default Support;