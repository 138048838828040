import { useContext } from "react";
import { useUpdateUserPinned } from "../api/data-access";
import { UserPinnedPayload } from "../types/SharedTypes";
import { ToastContext } from "../contexts/ToastContext";
import useDisplayServerError from "./useDisplayServerError";

const usePinToProfile = () => {
  const updateUser = useUpdateUserPinned();
  const {requestToast} = useContext(ToastContext);
  const {handleError} = useDisplayServerError();
  
  const updatePin = async (entityId: string, onSuccessEffects?: () => void) => {
    const updateObj: UserPinnedPayload = {
      pinned: entityId,
    }
    updateUser.mutate(updateObj, {
      onSuccess: () => {
        requestToast('List pinned.', 'success');
        onSuccessEffects && onSuccessEffects()
      },
      onError: (err) => handleError(err)
    })
  }
  const removePin = async (onSuccessEffects?: () => void) => {
    const updateObj: UserPinnedPayload = {
      pinned: null,
    }
    
    updateUser.mutate(updateObj, {
      onSuccess: () => {
        requestToast('Pinned item removed.', 'success');
        onSuccessEffects && onSuccessEffects()
      },
      onError: (err) => handleError(err)
    })
  }
  return {updatePin, removePin};
}
export default usePinToProfile;