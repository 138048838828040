export const LIST_CREATOR_ACTIONS = {
  ADD_ITEM_ON: "add-on",
  ADD_ITEM_OFF: "add-off",
  REMOVE_ITEM: 'remove-item',
  ADD_ITEM_UNPLACED: "add-unplaced",
  IMPORT_BATCH_ITEMS: 'import-batch-items',
  MOVE_ITEM_ON: "move-on",
  MOVE_ITEM_OFF: "move-off",
  MOVE_ITEM_UNPLACED: 'move-unplaced',
  MOVE_MANY_ITEMS_OFF: "move-many-off",
  ADD_ITEMS_ON: "add-multi-on",
  ADD_ITEMS_OFF: "add-multi-off",
  ADD_ITEMS_UNPLACED: "add-multi-unplaced",
  REORDER_ON_LIST: 'reorder-on-list',
  INIT_DATA: "init-data",
  UPDATE_ITEM: 'update-item'
};