import { baseAPIUrl } from "../constants/urls";
import { Save } from "../types/SharedTypes";
import createAxios from "./authInterceptor";

const savesUrl = `${baseAPIUrl}/saves`;

const savesApi = createAxios;

export const getUserSaves = async () => {
  const res = await savesApi.get<Save[]>(savesUrl + "/user");
  return res.data;
}

export const createSave = async (postId: string) => {
  const res = await savesApi.post<{listId: string, collectionId: string}>(`${savesUrl}/${postId}`);
  return res.data;
}
export const removeSave = async (postId: string) => {
  const res = await savesApi.delete<{listId: string, collectionId: string}>(`${savesUrl}/${postId}`);
  return res.data;
}