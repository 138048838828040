import { useSpecialThemed } from "../../../hooks/useThemed";
import { BaseCollectionType, PopulatedCollection } from "../../../types/SharedTypes";
import ContextMenu, { ContextMenuContent } from "../modals/ContextMenu";
import { DraftIcon, PinIcon, PinIconSolid, PublishIcon, PublishPersonalIcon, ReportIcon, DeleteIcon } from "../../../assets/icons";
import { MouseEvent, useContext, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import usePinToProfile from "../../../hooks/usePinToProfile";
import FeedCardBody from "./FeedCardBody";
import { useGetCollectionById, useRevertPostToDraft, useUpdatePostVisibility } from "../../../api/data-access";
import useHandleDeleteList from "../../../hooks/useHandleDeleteList";
import { DeleteListModal } from "./ListFeedCard";
import useDisplayServerError from "../../../hooks/useDisplayServerError";
import { makePlural } from "../../../tools/Helpers";
import ReportModal from "../modals/ReportModal";

type ListFeedCardProps = {
  badgeBanner?: React.ReactNode;
  hideHeader?: boolean;
  small?: boolean;
  isPinned?: boolean;
  isInFeed?: boolean;
  themedHeader?: boolean;
  returnRecentInSmartSet?: boolean;
} & ({
  collection: PopulatedCollection | BaseCollectionType;
  link?: string;
  collectionId?: never;
} | {
  collectionId: string;
  link?: string;
  collection?: never;
});

const CollectionFeedCard = ({collection: _collection, collectionId, badgeBanner, hideHeader, small, link, isPinned, isInFeed, themedHeader, returnRecentInSmartSet: _returnRecent}: ListFeedCardProps) => {
  
  const {authUserId} = useContext(AuthContext);
  const {data: fetchedCollection, isError, isLoading} = useGetCollectionById(collectionId || "", undefined, !!collectionId);
  
  const collection = _collection || fetchedCollection;
  const themeOverride = useSpecialThemed(collection?.theme);
  const {updatePin, removePin} = usePinToProfile();
  const updateVisibility = useUpdatePostVisibility();
  const revertPostToDraft = useRevertPostToDraft();
  const {handleConfirmDelete, handleRequestDelete, isConfirmingDelete, handleCancelDelete} = useHandleDeleteList();
  const {handleError} = useDisplayServerError();
  
  const thumbImage = collection?.image;
  const returnRecentInSmartSet = _returnRecent && !!collection?.smartCollection;
  
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);
  
  const handleVisibility = () => {
    if (!collection) return;
    updateVisibility.mutate({id: collection._id, update: collection.isPublic ? 'personal' : 'public'}, {
      onError: handleError
    });
  }
  
  const handleDraft = () => {
    if (!collection) return;
    revertPostToDraft.mutate({id: collection._id}, {
      onError: handleError
    });
  }
  
  const isAuth = collection?.author && typeof collection?.author !== "string" ? authUserId === collection?.author._id : authUserId === collection?.author;
  const contextMenuContent: ContextMenuContent = {
    focusedItem: {
      image: collection?.image || null,
      title: `${collection?.title}`,
      subtitle: "Collection"
    },
    title: `Options`,
    menuContent: [
      {
        title: 'Pin to Profile',
        icon: PinIconSolid,
        visible: !!collection && (!isPinned || !isAuth) && collection.isPublic && !collection.isDraft,
        handleClick: () => collection && updatePin(collection._id)
      },
      {
        title: 'Remove Pin',
        icon: PinIcon,
        visible: (!!collection && !!isPinned && isAuth),
        handleClick: () => removePin()
      },
      {
        title: "Report",
        visible: !isAuth,
        handleClick: () => setReportModalVisible(true),
        icon: ReportIcon
      },
      {
        title: `Change to ${collection?.isPublic ? "Personal" : "Public"}`,
        visible: isAuth && !collection?.isAutoGenerated && !collection?.smartCollection && !!collection && !collection.isDraft,
        handleClick: handleVisibility,
        icon: collection?.isPublic ? PublishPersonalIcon : PublishIcon
      },
      {
        title: `Revert to Draft`,
        visible: isAuth && !collection?.isAutoGenerated && !collection?.smartCollection && !!collection && !collection.isDraft,
        handleClick: handleDraft,
        icon: DraftIcon
      },
      {
        title: "Delete Collection",
        disabled: false,
        visible: isAuth && !collection?.isAutoGenerated && !collection?.smartCollection,
        handleClick: handleRequestDelete,
        icon: DeleteIcon,
        alert: true
      }
    ]
  }
  
  const handleContextMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setContextMenuVisible(true);
  }
  
  const linkQueryParams = collection ? `?theme=${collection.theme || 'default'}${returnRecentInSmartSet ? '&returnRecent=true' : ''}` : '';
  
  if (isLoading) return <div data-small={small} className="skeleton-card" />;
  else if (isError && collectionId) return <p>Error</p>
  else if (!collection) return null;

  const entriesLength = (returnRecentInSmartSet && typeof collection?.smartCollection !== 'string' 
    ? collection.smartCollection?.publishedCount
    : collection.entriesCount) || 0;

  return (
    <>
      {reportModalVisible && <ReportModal setModalVisible={setReportModalVisible} contentId={collection._id} contentType={'ListPost'} />}
      {authUserId && isConfirmingDelete && 
        <DeleteListModal 
          handleConfirm={() => collection && handleConfirmDelete(collection._id)}
          handleCancel={handleCancelDelete}
          title={collection.title}
          image={thumbImage}
        />
      }
      {contextMenuVisible && <ContextMenu setModalVisible={setContextMenuVisible} menuData={contextMenuContent} />}
      <FeedCardBody
        type={'Collection'}
        handleContextMenu={handleContextMenu}
        hideHeader={hideHeader}
        badgeBanner={badgeBanner}
        small={small}
        isPinned={isPinned}
        isInFeed={isInFeed}
        themedHeader={themedHeader}
        themeOverride={themeOverride}
        author={collection.author}
        isDraft={collection.isDraft && !collection.hasPublishedLists}
        createdAt={collection.createdAt}
        thumbImage={thumbImage}
        link={link ? link+linkQueryParams : `/collection/${collection._id}${linkQueryParams}`}
        entriesTerm={returnRecentInSmartSet ? makePlural(entriesLength, 'Period') : makePlural(entriesLength, 'List')}
        entriesLength={entriesLength}
        title={collection.title}
        category={collection.category}
        isAutoGenerated={collection.isAutoGenerated}
        period={typeof collection?.smartCollection === 'string' ? undefined : collection.smartCollection?.period}
      />
    </>
  )
}

export default CollectionFeedCard;