import { Dispatch, SetStateAction, useContext, useState } from 'react'
import Modal from '../Modal';
import { DraftIcon, PublishIcon, PublishPersonalIcon, DeleteIcon, InfoIcon } from '../../assets/icons';
import { CreateCollectionForm } from '../createList/CreateListModal';
import CreateCollectionTab, { ThumbnailOption } from '../createList/CreateCollectionTab';
import { CollectionUpdatePayload, DecoratedCollection, ListVisibilityKey, SmartCollection } from '../../types/SharedTypes';
import { AuthContext } from '../../contexts/AuthContext';
import useHandleDeleteList from '../../hooks/useHandleDeleteList';
import { DeleteListModal } from '../shared/cards/ListFeedCard';
import useHandleUpdateCollection from '../../hooks/useHandleUpdateCollection';
import GeneralConfirmationModal from '../shared/modals/GeneralConfirmationModal';
import TabBar from '../navigation/TabBar';
import { basicDateFormat, makePlural } from '../../tools/Helpers';
import { smartPeriodSingular } from '../../constants/general';
import TextInput from '../shared/inputs/TextInput';
import { fallbackImage } from '../../constants/urls';
import MediaSelectorModal from '../shared/modals/MediaSelectorModal';
import usePreviewImage from '../../hooks/usePreviewImage';

type Props = {
  setModalVisible: (prev: boolean) => void;
  collection: DecoratedCollection;
}

type Action = {
  icon: React.ReactNode;
  title: string;
  onClick: () => void;
  visible?: boolean;
  alert?: boolean;
}

const EditCollectionModal = ({setModalVisible, collection}: Props) => {
  
  const {authUserId} = useContext(AuthContext);
  const {handleConfirmDelete, handleRequestDelete, isConfirmingDelete, handleCancelDelete} = useHandleDeleteList();
  const {updateCollection, isLoading, updateCollectionsInSet, updateCollectionSimple, updateSmartSetDefaults} = useHandleUpdateCollection(() => setModalVisible(false));
  
  const [collectionForm, setCollectionForm] = useState<CreateCollectionForm>({
    title: collection.title,
    description: collection.description,
    category: collection.category,
    subCategories: collection.subCategories || [],
    isPublic: collection.isPublic,
    isSmart: !!collection.smartCollection,
    period: collection.smartCollection?.period || 'weekly',
    startingKey: collection.smartCollection?.startingKey || 1,
    isSmartDisabled: collection.smartCollection?.isDisabled || false,
    image: collection.image
  });
  
  const [newImage, setNewImage] = useState<File>();
  
  const isCollectionValid = !!(collectionForm.title && collectionForm.description && collectionForm.category);
  
  const handleUpdateCollection = async (scope?: "period" | "set") => {
    if (!isCollectionValid) return;
    if (scope === 'period') {
      await updateCollectionSimple({
        description: collectionForm.description, image: collectionForm.image
      }, collection._id);
    }
    else if (scope === 'set') {
      updateCollection({
        ...collectionForm, description: collection.description, image: collection.image
      }, collection, newImage);
    }
    else updateCollection(collectionForm, collection, newImage);
  }

  const handleUpdateVisibility = async (visibility: ListVisibilityKey) => {
    await updateCollectionSimple({isDraft: visibility === 'draft'}, collection._id, false);
  }

  const actions: Action[] = [
    {
      title: `Revert Collection to Draft`,
      icon: <DraftIcon />,
      onClick: () => handleUpdateVisibility('draft'),
      visible: !collection.isDraft
    },
    {
      title: `Publish Collection as ${collection.isPublic ? "Public" : "Personal"}`,
      icon: collection.isPublic ? <PublishIcon /> : <PublishPersonalIcon />,
      onClick: () => handleUpdateVisibility('publish'),
      visible: collection.isDraft
    },
    {
      title: `Delete Collection`,
      icon: <DeleteIcon />,
      onClick: handleRequestDelete,
      alert: true
    },
  ];
  
  return (
    <>
      {authUserId && isConfirmingDelete &&
        <DeleteListModal
          handleConfirm={() => handleConfirmDelete(collection._id, false)}
          handleCancel={handleCancelDelete}
          title={collection.title}
          image={collection?.image}
        />
      }
      
      <Modal
        scroll
        setModalVisible={setModalVisible}
        isLoading={isLoading}
        modalTitle={'Edit Collection Settings'}
        controls
        removePadding
        fixedBottomItem={!collection.smartCollection}
        fixedBottomItemChildren={
          <div className='item-row padding-small'>
            <button className='subtle full-width' onClick={() => setModalVisible(false)}>
              Cancel
            </button>
            <button className='primary outline full-width' onClick={() => handleUpdateCollection()}>
              Save
            </button>
          </div>
        }
      >
        <div className="item-list no-gap">
          {collection.smartCollection &&
            <EditSmartCollection
              authUserId={authUserId}
              collection={collection}
              form={collectionForm}
              setForm={setCollectionForm}
              setNewImage={setNewImage}
              updateCollectionsInSet={updateCollectionsInSet}
              isLoading={isLoading}
              handleUpdateVisibility={handleUpdateVisibility}
              closeModal={() => setModalVisible(false)}
              handleUpdateCollection={handleUpdateCollection}
              updateSmartSetDefaults={updateSmartSetDefaults}
            />
          }

          {!collection.smartCollection &&
            <>
              <CreateCollectionTab isEdit form={collectionForm} setForm={setCollectionForm} setNewImage={setNewImage} collectionId={collection._id} />
              <div className='item-list large top-border padding'>
                <p className='subtitle-large weight-bold'>Actions</p>
                <Actions actions={actions} />
              </div>
            </>
          }
        </div>
      </Modal>
    </>
  )
}

const Actions = ({actions}: {actions: Action[]}) => {
  return (
    <div className='item-list'>
      {actions.map(({alert, onClick, title, icon, visible}, i) => visible !== false &&
        <button key={i} className={`icon-text ${alert ? 'alert' : 'subtle'} small`} onClick={onClick}>
          {icon}
          {title}
        </button>
      )}
    </div>
  )
}

type SmartCollectionProps = {
  authUserId: string|undefined;
  collection: DecoratedCollection;
  form: CreateCollectionForm;
  setForm: Dispatch<SetStateAction<CreateCollectionForm>>
  setNewImage: (prev: File|undefined) => void;
  updateCollectionsInSet: (update: CollectionUpdatePayload, smartId: string) => Promise<true | undefined>;
  isLoading: boolean;
  handleUpdateVisibility: (key: ListVisibilityKey) => void;
  closeModal: () => void;
  handleUpdateCollection: (scope?: "period"|"set") => void;
  updateSmartSetDefaults: (description: string | undefined, image: string | undefined, smartId: string) => void;
}

const EditSmartCollection = ({authUserId, collection, setNewImage, updateCollectionsInSet, isLoading, form, setForm, handleUpdateVisibility, closeModal, handleUpdateCollection, updateSmartSetDefaults}: SmartCollectionProps) => {

  const {clearImage, handleImage, imagePreview} = usePreviewImage(setNewImage);
  const {handleConfirmDelete, handleRequestDelete, isConfirmingDelete, handleCancelDelete} = useHandleDeleteList();

  const [currentTab, setCurrentTab] = useState<"period"|"set"|"default">("period");
  const [batchVisibilityModalVisible, setBatchVisibilityModalVisible] = useState<string>();
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [defaults, setDefaults] = useState<SmartCollection['defaults']>({
    description: collection?.smartCollection?.defaults?.description || '',
    image: collection?.smartCollection?.defaults?.image || ''
  });

  const handleUpdateBatchVisibility = async () => {
    if (!collection.smartCollection) return;
    updateCollectionsInSet({isDraft: batchVisibilityModalVisible === 'draft'}, collection.smartCollection._id);
    setBatchVisibilityModalVisible(undefined);
  }
  const cancelBatchVis = () => {
    setBatchVisibilityModalVisible(undefined);
  }

  const handleSave = () => {
    if (!collection.smartCollection) return;
    if (currentTab === 'default') updateSmartSetDefaults(defaults.description, defaults.image, collection.smartCollection._id);
    else handleUpdateCollection(currentTab);
  }

  const relativeTitle = `${smartPeriodSingular(collection.smartCollection?.period, true)} of ${basicDateFormat(collection.createdAt)}`;

  const actions: Action[] = [
    {
      title: "Revert All Collections to Draft",
      icon: <DraftIcon />,
      onClick: () => setBatchVisibilityModalVisible('draft'),
      visible: collection.smartCollection && currentTab === 'set'
    },
    {
      title: `Revert Current ${smartPeriodSingular(collection.smartCollection?.period, true)} to Draft`,
      icon: <DraftIcon />,
      onClick: () => handleUpdateVisibility('draft'),
      visible: collection.smartCollection && currentTab === 'period' && !collection.isDraft
    },
    {
      title: `Publish All Collections as ${collection.isPublic ? "Public" : "Personal"}`,
      icon: collection.isPublic ? <PublishIcon /> : <PublishPersonalIcon />,
      onClick: () => setBatchVisibilityModalVisible('publish'),
      visible: collection.smartCollection && currentTab === 'set'
    },
    {
      title: `Publish Current ${smartPeriodSingular(collection.smartCollection?.period, true)} as ${collection.isPublic ? "Public" : "Personal"}`,
      icon: collection.isPublic ? <PublishIcon /> : <PublishPersonalIcon />,
      onClick: () => handleUpdateVisibility('publish'),
      visible: collection.smartCollection && currentTab === 'period' && collection.isDraft
    },
    {
      title: `Delete ${collection.smartCollection 
        ? `Smart Collection (${collection.smartCollection.periodsCount} in set)` 
        : 'Collection'}`,
      icon: <DeleteIcon />,
      visible: currentTab === 'set',
      onClick: handleRequestDelete,
      alert: true
    },
    {
      title: `Delete Current ${smartPeriodSingular(collection.smartCollection?.period, true)}`,
      icon: <DeleteIcon />,
      visible: currentTab === 'period',
      onClick: handleRequestDelete,
      alert: true
    },
  ];

  if (!collection.smartCollection) return null;
  return (
    <>
      {authUserId && isConfirmingDelete &&
        <DeleteListModal
          handleConfirm={() => handleConfirmDelete(collection._id, currentTab === 'set')}
          handleCancel={handleCancelDelete}
          title={currentTab === 'set' && collection.smartCollection
            ? `${collection.title} (${collection.smartCollection.periodsCount} ${makePlural(collection.smartCollection.periodsCount, "collection")})`
            : `${collection.title}: ${relativeTitle}`
          }
          image={collection?.image}
        />
      }
      {batchVisibilityModalVisible &&
        <GeneralConfirmationModal
          setModalVisible={cancelBatchVis}
          onConfirm={handleUpdateBatchVisibility}
          alert
          isLoading={isLoading}
        >
          <div className="item-list">
            <p className='subtitle-large'>{"This will update all collections in this smart collection and cannot be undone."}</p>
            {batchVisibilityModalVisible === 'publish' && 
              <p className='subtitle-large secondary'>{"Collections with no published lists will not be updated."}</p>
            }
          </div>
        </GeneralConfirmationModal>
      }
      {showMediaModal &&
        <MediaSelectorModal
          postId={collection._id}
          imagePreview={imagePreview}
          clearPreviewImage={clearImage}
          originalImage={fallbackImage}
          currentImage={defaults.image}
          setNewImage={(image) => setDefaults(prev => ({...prev, image}))}
          setModalVisible={setShowMediaModal}
          handleImage={handleImage}
        />
      }

      <TabBar tabData={[
        {title: `Current Period`, onClick: () => setCurrentTab('period')},
        {title: "Smart Collection", onClick: () => setCurrentTab('set')},
        {title: "Defaults", onClick: () => setCurrentTab('default')}
      ]} />

      {currentTab !== 'default' && 
        <CreateCollectionTab isCurrent={currentTab === 'period'} isSmart={!!collection.smartCollection} isEdit form={form} setForm={setForm} setNewImage={setNewImage} collectionId={collection._id} />
      }
      {currentTab === 'default' &&
        <div className='item-list large padding'>
          <div className="item-row tile padding-small center-vertical" data-small={true}>
            <div className="icon-display secondary small">
              <InfoIcon />
            </div>
            <p className="subtitle-large spaced">Set the default values for new periods in this smart collection.</p>
          </div>
          <div className='left-item-layout large'>
            <ThumbnailOption 
              imageSrc={defaults.image || fallbackImage}
              imagePreview={imagePreview}
              clearImage={clearImage}
              setShowMediaModal={setShowMediaModal}
            />
            <div className='item-list'>
              <TextInput
                value={defaults.description}
                onChange={(val) => setDefaults(prev => ({...prev, description: val}))}
                label={'description'}
                id={'descriptioninput'}
                placeholder={"Description..."}
              />
            </div>
          </div>
        </div>
      }
      <div className='item-row padding-small sticky sticky-bottom top-border secondary-border'>
        <button className='subtle full-width' onClick={closeModal}>
          Cancel
        </button>
        <button className='primary outline full-width' onClick={handleSave}>
          Save
        </button>
      </div>
      {currentTab !== "default" && 
        <div className='item-list large top-border padding'>
          <div className='item-row'>
            <p className='subtitle-large weight-bold'>Actions</p>
            {currentTab === 'set' &&
              <p className='body secondary'>(Applies to all {collection.smartCollection.periodsCount} {makePlural(collection.smartCollection.periodsCount, "collection")} in this set)</p>
            }
          </div>
          <Actions actions={actions} />
        </div>
      }
    </>
  )
}

export default EditCollectionModal;
