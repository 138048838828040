import "./styles/main.scss";
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import { AuthContextProvider } from './contexts/AuthContext';
import { ThemeContextProvider } from './contexts/ThemeContext';
import { Auth0Provider } from '@auth0/auth0-react';

const queryClient = new QueryClient({defaultOptions: {
  queries: {
    staleTime: Infinity
  }
}});

const root = createRoot(document.getElementById('root')!);
root.render(
  <BrowserRouter>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_URL || ''}
      clientId={process.env.REACT_APP_AUTH_ID || ''}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH_AUDIENCE
      }}
      useRefreshTokens={true}
      cacheLocation={"localstorage"}
    >
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <ThemeContextProvider>
            <App />
          </ThemeContextProvider>
        </AuthContextProvider>
        {/* <ReactQueryDevtools initialIsOpen position={'right'} /> */}
      </QueryClientProvider>
    </Auth0Provider>
  </BrowserRouter>
);
