import { ListPostType, PopulatedListPostType } from "../../../types/SharedTypes";
import CollectionFeedCard from "./CollectionFeedCard";
import ListFeedCard from "./ListFeedCard";

type Props = {
  badgeBanner?: React.ReactNode;
  hideHeader?: boolean;
  small?: boolean;
  isPinned?: boolean;
  isInFeed?: boolean;
  themedHeader?: boolean;
  post: PopulatedListPostType | ListPostType;
  link?: string;
  returnRecentInSmartSet?: boolean;
};

const FeedCard = (props: Props) => {
  if (props.post.type === 'list') return (
    <ListFeedCard list={props.post} {...props} />
  )
  else if (props.post.type === 'collection') return (
    <CollectionFeedCard collection={props.post} {...props} />
  )
  return null;
}
export default FeedCard;