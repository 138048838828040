import { CREATOR_PAGES, CreatorState } from "../contexts/CreatorContext";
import { CreatorListItemType, ListItemType } from "../types/SharedTypes";
import { LIST_CREATOR_ACTIONS } from "./listCreatorReducerActions";

const findItemIndex = (searchIn: CreatorListItemType[], query: string): number | undefined => {
  const index = searchIn.findIndex(item => (query === item?._id || query === item?.localId));
  if (index === -1) return undefined;
  return index;
}

const updatePositions = (onList: CreatorListItemType[]) => {
  
  for (let i = 0; i < onList.length; i++) {
    onList[i].position = i + 1;
  }
  return onList;
}

const moveItemToNewList = (searchInFirst: CreatorListItemType[], searchInSecond: CreatorListItemType[], query: string) => {
  //search off, then search unplaced  
  let requestedItem: CreatorListItemType | undefined;
  let index: number | undefined;
  index = findItemIndex(searchInFirst, query);
  
  if (index === undefined) index = findItemIndex(searchInSecond, query);
  else {
    requestedItem = [...searchInFirst][index];
    searchInFirst.splice(index, 1);
  }

  if (index === undefined) {
    console.warn("move item on: no item found");
    return {requestedItem: null, searchInFirst, searchInSecond};
  }
  else if (!requestedItem) {
    requestedItem = searchInSecond[index];
    searchInSecond.splice(index, 1);
  }
  
  return {requestedItem, searchInFirst, searchInSecond};
}

//TODO: how to type action?
export default function listCreatorReducer(state: CreatorState, action: any) {
  switch (action.type) {
    case LIST_CREATOR_ACTIONS.INIT_DATA: {
      const _state = {...state};
      if (action.payload.isLocalDraft) return action.payload.list;
      _state.on = action.payload.list.onList || [];
      _state.off = action.payload.list.offList || [];
      _state.unplaced = [];
      return _state;
    }
    case LIST_CREATOR_ACTIONS.ADD_ITEM_ON: {
      const on = [...state.on];
      const newItem = {...action.payload};
      if (!newItem.position) {
        newItem.position = on.length + 1;
      }
      on.push(newItem);
      updatePositions(on);
      return {...state, on};
    }
    case LIST_CREATOR_ACTIONS.ADD_ITEM_OFF: {
      const newState = {...state};
      const newItem = {...action.payload};
      if (!newItem.position) {
        newItem.position = newState.off.length + 1;
      }
      newState.off.push(newItem);
      return newState;
    }
    case LIST_CREATOR_ACTIONS.ADD_ITEM_UNPLACED: {
      const unplaced = [...state.unplaced];
      unplaced.push(action.payload);
      return {...state, unplaced};
    }
    case LIST_CREATOR_ACTIONS.IMPORT_BATCH_ITEMS: {
      const unplaced = [...state.unplaced];
      
      for (const item of action.payload) {
        delete item.parentList;
        delete item.author;
        delete item._id;
        const formattedItem: CreatorListItemType = {
          ...item,
          position: 0,
          isPublic: false,
          localId: `${item.title}${Date.now()}`
        }
        unplaced.push(formattedItem);
      }
      
      return {...state, unplaced};
    }
    case LIST_CREATOR_ACTIONS.MOVE_ITEM_ON: {
      const newState = {...state};
      
      const {requestedItem, searchInFirst: off, searchInSecond: unplaced} = moveItemToNewList(newState.off, newState.unplaced, action.payload.id);
      if (!requestedItem) return state;
      
      const newPosition: number | undefined = action.payload.toPosition;
      const newIndex = newPosition === undefined ? newState.on.length : newPosition - 1;
      
      requestedItem.isPublic = true;
      newState.on.splice(newIndex, 0, requestedItem);
      updatePositions(newState.on);
      
      return {on: newState.on, off, unplaced};
    }
    case LIST_CREATOR_ACTIONS.MOVE_ITEM_OFF: {
      const newState = {...state};
      
      const {requestedItem, searchInFirst: on, searchInSecond: unplaced} = moveItemToNewList(newState.on, newState.unplaced, action.payload);
      if (!requestedItem) return state;
      
      requestedItem.isPublic = false;
      newState.off.push(requestedItem);
      updatePositions(newState.on);
      
      return {off: newState.off, on, unplaced};
    }
    case LIST_CREATOR_ACTIONS.MOVE_ITEM_UNPLACED: {
      const newState = {...state};
      
      const {requestedItem, searchInFirst: on, searchInSecond: off} = moveItemToNewList(newState.on, newState.off, action.payload);
      if (!requestedItem) return state;
      
      requestedItem.isPublic = false;
      newState.unplaced.push(requestedItem);
      updatePositions(newState.on);
      
      return {unplaced: newState.unplaced, on, off};
    }
    case LIST_CREATOR_ACTIONS.MOVE_MANY_ITEMS_OFF: {
      const newState = {...state};
      
      for (const item of action.payload) {
        const {requestedItem, searchInFirst: on, searchInSecond: unplaced} = moveItemToNewList(newState.on, newState.unplaced, item);
        if (!requestedItem) return state;
        
        requestedItem.isPublic = false;
        newState.off.push(requestedItem);
        updatePositions(newState.on);
        
        newState.on = on;
        newState.unplaced = unplaced;
      }
      
      return newState;
    }
    case LIST_CREATOR_ACTIONS.REMOVE_ITEM: {
      const newState = {...state};
      
      let relevantList: CreatorListItemType[];
      let index: number | undefined;

      if (action.payload.page === CREATOR_PAGES.on) relevantList = newState.on;
      else if (action.payload.page === CREATOR_PAGES.off) relevantList = newState.off;
      else relevantList = newState.unplaced;
      
      console.log(relevantList, action.payload.id);
      
      index = findItemIndex(relevantList, action.payload.id);
      
      if (index === undefined) {
        console.warn("no item found");
        return state;
      }

      relevantList.splice(index, 1);
      
      updatePositions(newState.on);
      
      return newState;
    }
    case LIST_CREATOR_ACTIONS.REORDER_ON_LIST: {
      const newState = {...state};
      
      //handle if item came from other list
      if (action.payload.moveToOn) {
        const {requestedItem, searchInFirst: off, searchInSecond: unplaced} = moveItemToNewList(newState.off, newState.unplaced, action.payload.id);
        if (!requestedItem) return state;
        
        requestedItem.isPublic = true;
        newState.on.push(requestedItem);
        newState.off = off;
        newState.unplaced = unplaced;
        updatePositions(newState.on);
      }
      
      const startIndex = findItemIndex(newState.on, action.payload.id);
      if (startIndex === undefined) {
        console.warn("No item found");
        return state;
      }
      const endIndex = action.payload.newIndex;
      const item = newState.on[startIndex];
      
      newState.on.splice(startIndex, 1);
      newState.on.splice(endIndex, 0, item);
      
      updatePositions(newState.on);
      
      return newState;
    }
    case LIST_CREATOR_ACTIONS.UPDATE_ITEM: {
      const newState = {...state};
      
      let relevantList: CreatorListItemType[];

      if (action.payload.page === CREATOR_PAGES.on) relevantList = newState.on;
      else if (action.payload.page === CREATOR_PAGES.off) relevantList = newState.off;
      else relevantList = newState.unplaced;
      
      const index = findItemIndex(relevantList, action.payload.id);
      
      if (index === undefined) return state;
      
      relevantList[index] = action.payload.item;
      
      return newState;
    }
    default:
      return state;
  }
}
