export type ThemeType = {
  title: string;
  slug: string;
  body: string;
  highlight: string;
  highlightText: string;
  text: string;
  line: string;
  isDark: boolean;
  highlightHasBorder?: boolean;
}

export const light = {
  body: "hsl(0,0%,100%)",
  text: "hsl(0,0%,0%)",
  element: "hsl(0,0%,0%)",
  elementText: "hsl(0,0%,100%)",
  elementSubtle: "hsl(0,0%,15%)",
  subtle: "hsl(0,0%,89%)",
  textSecondary: "hsl(0,0%,45%)",
  disabled: 'hsl(0,0%,45%',
  overlay: 'hsla(0,0%,0%,0.15)'
}

export const dark = {
  body: "hsl(0,0%,5%)",
  text: "hsl(0,0%,95%)",
  element: "hsl(0,0%,100%)",
  elementText: "hsl(0,0%,0%)",
  elementSubtle: "hsl(0,0%,89%)",
  subtle: "hsl(0,0%,15%)",
  textSecondary: "hsl(0,0%,65%)",
  disabled: 'hsl(0,0%,45%',
  overlay: 'hsla(0,0%,100%,0.1)'
}

export const defaultLightTheme: ThemeType = {
  title: "Default",
  slug: "default",
  body: "hsl(0,0%,89%)",
  highlight: "hsl(0,0%,0%)",
  highlightText: "hsl(0,0%,100%)",
  text: "hsl(0,0%,0%)",
  line: "hsla(0,0%,0%,30%)",
  isDark: false
}
export const defaultDarkTheme: ThemeType = {
  title: "Default",
  slug: "default",
  body: "hsl(0,0%,10%)",
  highlight: "hsl(0,0%,4%)",
  highlightText: "hsl(0,0%,100%)",
  text: "hsl(0,0%,92%)",
  line: "hsla(0,0%,100%,11%)",
  isDark: true
}

const themes: ThemeType[] = [
  {...defaultLightTheme},
  {
    title: "Firebrick",
    slug: "firebrick",
    body: "hsl(7, 91%, 67%)",
    highlight: "hsl(6,56%,47%)",
    highlightText: "hsl(0,0%,100%)",
    text: "hsl(7,90%,15%)",
    line: "hsla(7,67%,14%,30%)",
    isDark: false
  },
  {
    title: "Boyfriend",
    slug: "boyfriend",
    body: "hsl(10,49%,5%)",
    highlight: "hsl(10,81%,55%)",
    highlightText: "hsl(10,81%,20%)",
    text: "hsl(0,30%,90%)",
    line: "hsla(10,80%,60%,30%)",
    isDark: true,
    highlightHasBorder: false
  },
  {
    title: "Bag",
    slug: "bag",
    body: "hsl(15,74%,75%)",
    highlight: "hsl(16,93%,70%)",
    highlightText: "hsl(130,92%,3%)",
    text: "hsl(6,52%,29%)",
    line: "hsla(16,63%,60%,70%)",
    isDark: false,
    highlightHasBorder: false
  },
  {
    title: "Simply Sunny",
    slug: "sunny",
    body: "hsl(26, 80%, 60%)",
    highlight: "hsl(28,100%,40%)",
    highlightText: "hsl(30,100%,93%)",
    text: "hsl(20,100%,27%)",
    line: "hsla(20,100%,20%,40%)",
    isDark: false,
    highlightHasBorder: false
  },
  {
    title: "Sunset Serenity",
    slug: "sunset",
    body: "hsl(25, 60%, 95%)",
    highlight: "hsl(40, 80%, 50%)",
    highlightText: "hsl(25, 100%, 95%)",
    text: "hsl(25, 80%, 20%)",
    line: "hsla(25, 80%, 20%,40%)",
    isDark: false,
    highlightHasBorder: false
  },
  {
    title: "Moonmilk",
    slug: "moonmilk",
    body: "hsl(19, 100%, 93%)",
    highlight: "hsl(32,100%,74%)",
    highlightText: "hsl(0,0%,0%)",
    text: "hsl(19,13%,20%)",
    line: "hsla(19,13%,20%,30%)",
    isDark: false
  },
  {
    title: "Editorial",
    slug: "editorial",
    body: "hsl(39, 21%, 80%)",
    highlight: "hsl(39,15%,70%)",
    highlightText: "hsl(39,20%,7%)",
    text: "hsl(39,20%,7%)",
    line: "hsla(210,10%,70%,20%)",
    isDark: false,
    highlightHasBorder: false
  },
  {
    title: "e-world",
    slug: "eworld",
    body: "hsl(22, 8%, 86%)",
    highlight: "hsl(68,77%,68%)",
    highlightText: "hsl(0,0%,0%)",
    text: "hsl(0,0%,19%)",
    line: "hsla(0,0%,0%,30%)",
    isDark: false,
    highlightHasBorder: true
  },
  {
    title: "Cyberal",
    slug: "cyberal",
    body: "hsl(194,5%,10%)",
    highlight: "hsl(60,85%,60%)",
    highlightText: "hsl(39,20%,7%)",
    text: "hsl(194,22%,69%)",
    line: "hsla(194,22%,69%,20%)",
    isDark: true,
    highlightHasBorder: false
  },
  {
    title: "Eye Burner",
    slug: "eyeburner",
    body: "hsl(104, 80%, 74%)",
    highlight: "hsl(0,0%,0%)",
    highlightText: "hsl(0,0%,100%)",
    text: "hsl(299,100%,51%)",
    line: "hsla(0,0%,0%,30%)",
    isDark: false,
    highlightHasBorder: true
  },
  {
    title: "In The Reeds",
    slug: "reeds",
    body: "hsl(61, 56%, 74%)",
    highlight: "hsl(151,22%,24%)",
    highlightText: "hsl(61,56%,74%)",
    text: "hsl(151,22%,24%)",
    line: "hsla(0,0%,0%,30%)",
    isDark: false
  },
  {
    title: "Mystic Forest",
    slug: "mysticforest",
    body: "hsl(157, 50%, 23%)",
    highlight: "hsl(40, 70%, 70%)",
    highlightText: "hsl(150, 50%, 15%)",
    text: "hsl(150, 30%, 90%)",
    line: "hsla(150, 30%, 90%, 30%)",
    isDark: true,
    highlightHasBorder: false
  },
  {
    title: "Forest",
    slug: "forest",
    body: "hsl(29, 31%, 85%)",
    highlight: "hsl(130,30%,20%)",
    highlightText: "hsl(0,0%,100%)",
    text: "hsl(29,10%,13%)",
    line: "hsla(0,0%,0%,30%)",
    isDark: false
  },
  {
    title: "Room Service",
    slug: "room",
    body: "hsl(192, 43%, 21%)",
    highlight: "hsl(39,92%,57%)",
    highlightText: "hsl(0,0%,0%)",
    text: "hsl(0,0%,100%)",
    line: "hsla(0,0%,100%,30%)",
    isDark: true
  },
  {
    title: "Aurora Glow",
    slug: "aurora",
    body: "hsl(260, 40%, 9%)",
    highlight: "hsl(170, 45%, 50%)",
    highlightText: "hsl(260, 70%, 15%)",
    text: "hsl(260, 30%, 90%)",
    line: "hsl(200, 30%, 60%)",
    isDark: true,
    highlightHasBorder: false
  },
  {
    title: "Negative",
    slug: "negative",
    body: "hsl(185,84%,5%)",
    highlight: "hsl(180,85%,3%)",
    highlightText: "hsl(130,92%,91%)",
    text: "hsl(130,92%,91%)",
    line: "hsla(130,22%,92%,20%)",
    isDark: true,
    highlightHasBorder: false
  },
  {
    title: "Dark Realm",
    slug: "realm",
    body: "hsl(159, 22%, 10%)",
    highlight: "hsl(189,100%,19%)",
    highlightText: "hsl(0,0%,95%)",
    text: "hsl(0,0%,95%)",
    line: "hsla(0,0%,95%,40%)",
    isDark: true,
    highlightHasBorder: false
  },
  {
    title: "Midnight Oasis",
    slug: "oasis",
    body: "hsl(210, 50%, 20%)",
    highlight: "hsl(190, 50%, 30%)",
    highlightText: "hsl(210, 100%, 95%)",
    text: "hsl(210, 100%, 90%)",
    line: "hsla(210, 100%, 90%, 40%)",
    isDark: true,
    highlightHasBorder: false
  },
  {
    title: "Chain",
    slug: "chain",
    body: "hsl(200,23%,20%)",
    highlight: "hsl(200,23%,10%)",
    highlightText: "hsl(6,2%,79%)",
    text: "hsl(334,20%,83%)",
    line: "hsla(200,40%,9%,60%)",
    isDark: true,
    highlightHasBorder: false
  },
  {
    title: "Deep Ocean",
    slug: "deepocean",
    body: "hsl(220, 61%, 10%)",
    highlight: "hsl(210,50%,24%)",
    highlightText: "hsl(230,10%,93%)",
    text: "hsl(230,20%,77%)",
    line: "hsla(210,10%,70%,20%)",
    isDark: true,
    highlightHasBorder: false
  },
  {
    title: "Sumo Wrestler",
    slug: "sumo",
    body: "hsl(219, 37%, 17%)",
    highlight: "hsl(12,100%,68%)",
    highlightText: "hsl(12,59%,12%)",
    text: "hsl(0,0%,100%)",
    line: "hsla(0,0%,100%,30%)",
    isDark: true
  },
  {
    title: "Shleek",
    slug: "shleek",
    body: "hsl(250, 40%, 95%)",
    highlight: "hsl(245, 40%, 90%)",
    highlightText: "hsl(230, 90%, 60%)",
    text: "hsl(230, 90%, 60%)",
    line: "hsl(230, 60%, 80%)",
    isDark: false,
    highlightHasBorder: true
  },
  {
    title: "Mellow World",
    slug: "mellowworld",
    body: "hsl(234, 39%, 54%)",
    highlight: "hsl(238,67%,75%)",
    highlightText: "hsl(0,0%,100%)",
    text: "hsl(0,0%,100%)",
    line: "hsla(0,0%,0%,30%)",
    isDark: true
  },
  {
    title: "Smooth Purple",
    slug: "smoothpurple",
    body: "hsl(252,15%,79%)",
    highlight: "hsl(252,66%,60%)",
    highlightText: "hsl(0,0%,100%)",
    text: "hsl(252,31%,17%)",
    line: "hsla(0,0%,0%,30%)",
    isDark: false
  },
  {
    title: "Purple Night",
    slug: "purplenight",
    body: "hsl(273,31%,19%)",
    highlight: "hsl(256,37%,64%)",
    highlightText: "hsl(0,0%,0%)",
    text: "hsl(0,0%,100%)",
    line: "hsla(0,0%,100%,30%)",
    isDark: true
  },
  {
    title: "Vengeance",
    slug: "vengeance",
    body: "hsl(235, 21%, 21%)",
    highlight: "hsl(353,86%,54%)",
    highlightText: "hsl(0,0%,100%)",
    text: "hsl(0,0%,100%)",
    line: "hsla(0,0%,0%,30%)",
    isDark: true
  },
  {
    title: "Mercury",
    slug: "mercury",
    body: "hsl(248, 9%, 9%)",
    highlight: "hsl(241,12%,5%)",
    highlightText: "hsl(263,50%,75%)",
    text: "hsl(256,11%,68%)",
    line: "hsla(256,11%,68%,40%)",
    isDark: true,
    highlightHasBorder: true
  },
  {
    title: "Y1K",
    slug: "y1k",
    body: "hsl(277, 87%, 92%)",
    highlight: "hsl(263,81%,77%)",
    highlightText: "hsl(0,0%,0%)",
    text: "hsl(300,8%,23%)",
    line: "hsla(0,0%,0%,30%)",
    isDark: false,
    highlightHasBorder: true
  },
  {
    title: "Forn",
    slug: "forn",
    body: "hsl(270,24%,66%)",
    highlight: "hsl(10,85%,80%)",
    highlightText: "hsl(39,20%,7%)",
    text: "hsl(20,22%,9%)",
    line: "hsla(250,22%,2%,20%)",
    isDark: false,
    highlightHasBorder: true
  },
  {
    title: "Mink",
    slug: "mink",
    body: "hsl(1, 74%, 88%)",
    highlight: "hsl(6,90%,69%)",
    highlightText: "hsl(0,0%,100%)",
    text: "hsl(0,0%,20%)",
    line: "hsla(0,0%,20%,30%)",
    isDark: false
  },
  {
    title: "Opium",
    slug: "opium",
    body: "hsl(350, 71%, 95%)",
    highlight: "hsl(350,56%,59%)",
    highlightText: "hsl(257,28%,95%)",
    text: "hsl(257,28%,5%)",
    line: "hsla(257,28%,5%,40%)",
    isDark: false,
    highlightHasBorder: false
  },
  {
    title: "Wine Time",
    slug: "winetime",
    body: "hsl(349, 36%, 19%)",
    highlight: "hsl(349,36%,32%)",
    highlightText: "hsl(360,70%,89%)",
    text: "hsl(349,45%,78%)",
    line: "hsla(349,45%,78%,40%)",
    isDark: true,
    highlightHasBorder: false
  },
  {
    title: "Antique",
    slug: "antique",
    body: "hsl(20, 35%, 85%)",
    highlight: "hsl(0,18%,62%)",
    highlightText: "hsl(0,0%,100%)",
    text: "hsl(0,0%,20%)",
    line: "hsla(0,0%,20%,30%)",
    isDark: false
  },
]

export default themes;