import React, { useContext, useState } from 'react'
import Modal from '../Modal';
import {  CloseIcon, UploadImageIcon } from '../../assets/icons';
import { ToastContext } from '../../contexts/ToastContext';
import { useUpdateUserInfo } from '../../api/data-access';
import { DecoratedUser, UserUpdatePayload } from '../../types/SharedTypes';
import TextInput from '../shared/inputs/TextInput';
import { fallbackImage } from '../../constants/urls';
import { formatSecureLink, validateLink } from '../../tools/Helpers';
import useUploadMedia from '../../hooks/useUploadMedia';
import usePreviewImage from '../../hooks/usePreviewImage';

type PreviewImages = {
  picture: string | null;
  banner: string | null;
}
type Props = {
  setModalVisible: (prev: boolean) => void;
  user: DecoratedUser;
}

const EditProfileModal = ({setModalVisible, user}: Props) => {
  
  const {requestToast} = useContext(ToastContext);
  
  const updateUser = useUpdateUserInfo();
  const {handleMedia} = useUploadMedia();
  
  const [newUserData, setNewUserData] = useState<UserUpdatePayload>({
    displayName: user.displayName,
    biography: user.biography,
    link: user.link,
  });
  const [newProfileImage, setNewProfileImage] = useState<File>();
  const [newBannerImage, setNewBannerImage] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  
  const {
    handleImage: handleProfileImage,
    imagePreview: profileImagePreview,
    clearImage: clearProfileImage
  } = usePreviewImage(setNewProfileImage);
  const {
    handleImage: handleBannerImage,
    imagePreview: bannerImagePreview,
    clearImage: clearBannerImage
  } = usePreviewImage(setNewBannerImage);
  
  const handleUpdate = async () => {
    
    const userUpdate: UserUpdatePayload = {};
    
    const formattedLink = newUserData.link ? validateLink(newUserData.link) : undefined;
    if (formattedLink === false) {
      requestToast("Your link isn't valid.", "error");
      return;
    }

    setIsLoading(true);
    
    if (newProfileImage) {
      const newMedia = await handleMedia(newProfileImage);
      if (!newMedia) {
        console.log('Profile picture failed to update.');
        requestToast('Profile picture failed to update.', 'error');
        setIsLoading(false);
        return;
      }
      Object.assign(userUpdate, {picture: newMedia.url});
    }
    if (newBannerImage) {
      const newMedia = await handleMedia(newBannerImage);
      if (!newMedia) {
        console.log('Banner picture failed to update.');
        requestToast('Banner picture failed to update.', 'error');
        setIsLoading(false);
        return;
      }
      Object.assign(userUpdate, {banner: newMedia.url});
    }

    newUserData.displayName && Object.assign(userUpdate, {displayName: newUserData.displayName});
    newUserData.biography && Object.assign(userUpdate, {biography: newUserData.biography});
    newUserData.link && formattedLink && Object.assign(userUpdate, {link: formattedLink});
    
    updateUser.mutate(userUpdate, {
      onSuccess: () => {
        console.log('user updated.');
        requestToast('Updated profile.', 'success');
        setIsLoading(false);
        setModalVisible(false);
      },
      onError: () => {
        console.log('user failed to update.');
        setIsLoading(false);
      }
    });
  };
  
  const handleSocialLinkChange = (value: string) => {
    const secureLink = formatSecureLink(value);
    setNewUserData(prev => ({...prev, link: secureLink}))
  };
  
  return (
    <Modal 
      fixedBottomItem
      controls
      fixedBottomItemChildren={
        <div className="padding-small item-list">
          <button onClick={handleUpdate} aria-disabled={false} disabled={false} className='primary'>{"Update"}</button>
        </div>
      }
      modalTitle='Edit Profile'
      setModalVisible={setModalVisible}
      isLoading={isLoading}
      children={
        <div className='item-list large'>
          <div className='item-list'>
            <p className='indent subtitle-medium'>Images</p>
            <div className='left-item-layout'>
              <div className='item-list'>
                <label className='file-input' htmlFor='profileImage'>
                  <div className='floating-indicator'>
                    <UploadImageIcon />
                  </div>
                  <div className='user-avatar med'>
                    <img src={profileImagePreview || user.picture} />
                  </div>
                </label>
                {profileImagePreview && 
                  <button className="subtle icon-text tiny xxs" onClick={clearProfileImage}>
                    Clear
                    <CloseIcon />
                  </button>
                }
              </div>
              <div className='item-list'>
                <label className='file-input full-width' htmlFor='bannerImage'>
                  <div className='floating-indicator'>
                    <UploadImageIcon />
                  </div>
                  <div className='floating-banner-image'>
                    <img src={bannerImagePreview || user.banner || fallbackImage} />
                  </div>
                </label>
                {bannerImagePreview && 
                  <button className="subtle icon-text tiny xxs" onClick={clearBannerImage}>
                    Clear
                    <CloseIcon />
                  </button>
                }
              </div>
              <input 
                id={"profileImage"} 
                className='file-input' 
                type='file' 
                accept='image/*' 
                onChange={(e) => e.target.files && handleProfileImage(e.target.files[0])}
              />
              <input 
                id={"bannerImage"} 
                className='file-input' 
                type='file' 
                accept='image/*' 
                onChange={(e) => e.target.files && handleBannerImage(e.target.files[0])}
              />
            </div>
          </div>
          <TextInput 
            value={newUserData?.displayName}
            onChange={(val) => setNewUserData(prev => ({...prev, displayName: val}))}
            label={"Display Name"}
            id={'displayname'}
          />
          <TextInput 
            value={newUserData?.biography}
            onChange={(val) => setNewUserData(prev => ({...prev, biography: val}))}
            label={"Biography"}
            id={'biography'}
            placeholder='Nothing set...'
          />
          <TextInput 
            value={newUserData?.link}
            onChange={(val) => handleSocialLinkChange(val)}
            label={"Link"}
            id={'link'}
            placeholder='https://...'
          />
        </div>
      }
    />
  )
}

export default EditProfileModal;
