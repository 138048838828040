import { useState } from "react";
import { FeedFilterOptions, useGetUserAutoPosts, useGetUserPrivatePosts } from "../../api/data-access";
import LoadingIcon from "../../components/shared/LoadingIcon";
import FeedCard from "../../components/shared/cards/FeedCard";
import { UserListsFilters } from "../UserLists";

const PersonalTab = () => {
  
  const [filters, setFilters] = useState<FeedFilterOptions>({
    category: undefined,
    type: undefined,
    period: undefined
  });

  const {data, isSuccess} = useGetUserPrivatePosts(filters);
  const {data: auto, isSuccess: isAutoSuccess} = useGetUserAutoPosts();
  
  return (
    <section data-padding-small={true}>
      <div className="item-list xl">
        <UserListsFilters filters={filters} setFilters={setFilters} />
        {isAutoSuccess && auto.length > 0 &&
          <div className="item-list large">
            <p className="indent subtitle-large">Auto-Generated</p>
            <div className="item-list">
              {auto.map((l) => 
                <FeedCard key={l._id} post={l} small />
              )}
            </div>
          </div>
        }
        <div className="item-list large">
          <p className="indent subtitle-large">Your Personal Collections and Lists</p>
          {isSuccess
            ? <div className="item-list">
              {data.map((l) => 
                <FeedCard key={l._id} post={l} small returnRecentInSmartSet />
              )}
            </div>
            :
            <div className="item-row center-horizontal padding">
              <LoadingIcon />
            </div>
          }
        </div>
        {isSuccess && data.length === 0 && 
          <p className="subtitle-large secondary center">
            Nothing to show
          </p>
        }
      </div>
    </section>
  )
}
export default PersonalTab;