import { useEffect, useRef } from "react";

const rs = document.documentElement.style;

const useHideHeader = () => {
  
  const prevValues = useRef({
    opacity: "",
    height: ""
  })
  
  useEffect(() => {
    
    prevValues.current.opacity = rs.getPropertyValue('--topbar-opacity');
    prevValues.current.height = rs.getPropertyValue('--topbar-height');
    
    rs.setProperty('--topbar-opacity', "0");
    rs.setProperty('--topbar-height', "0");
    
    return () => {
      rs.setProperty('--topbar-opacity', prevValues.current.opacity);
      rs.setProperty('--topbar-height', prevValues.current.height);
    }
  }, []);
}
export default useHideHeader;