type SwitchOptionButtonProps = {
  options: {
    text: string;
    onClick: () => void;
    selected: boolean;
  }[];
  label?: string;
  required?: boolean;
  note?: string;
  disabled?: boolean;
}

const SwitchOptionButton = ({options, label, required, note, disabled}: SwitchOptionButtonProps) => {
  return (
    <div className="item-list" aria-disabled={disabled}>
      <div className="item-list med">
        {label && <label aria-required={required} className="subtitle-medium indent">{label}</label>}
        <div className="option-switch">
          <button className="secondary" aria-selected={options[0].selected} onClick={options[0].onClick}>
            {options[0].text}
          </button>
          <button className="secondary" aria-selected={options[1].selected} onClick={options[1].onClick}>
            {options[1].text}
          </button>
        </div>
      </div>
      {note && <p className="subtitle-small weight-light indent secondary">{note}</p>}
    </div>
  )
}
export default SwitchOptionButton;