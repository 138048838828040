import ComingSoon from "../components/shared/ComingSoon";
import PageWrapper from "./PageWrapper";

const Recommend = () => {
  return (
    <PageWrapper>
      <ComingSoon />
    </PageWrapper>
  )
}
export default Recommend;