import React, { useContext, useState } from 'react'
import {Categories, CollectionPeriods, CreateCollectionPayload, CreateListPayload, Media, TabItem } from '../../types/SharedTypes';
import { useCreateNewCollection, useCreateNewList, useCreateSmartCollection } from '../../api/data-access';
import TabBar from '../navigation/TabBar';
import Modal from '../Modal';
import { CloseIcon } from '../../assets/icons';
import { useNavigate } from 'react-router';
import CreateListTab from './CreateListTab';
import CreateCollectionTab from './CreateCollectionTab';
import LoadingIcon from '../shared/LoadingIcon';
import useUploadMedia from '../../hooks/useUploadMedia';
import { ToastContext } from '../../contexts/ToastContext';

export type CreateListForm = {
  title: string;
  description: string;
  ascending: boolean;
  isPublic: boolean;
  ordered: boolean;
  category: Categories | '';
  thumbnailEntry?: number;
  subCategories?: string[];
  image?: string;
}
export type CreateCollectionForm = {
  title: string;
  description: string;
  isPublic: boolean;
  category: Categories | '';
  subCategories?: string[];
  period: CollectionPeriods;
  startingKey?: number;
  image?: string;
  isSmart: boolean;
  isSmartDisabled?: boolean;
}
type Tabs = "list" | "collection";

type Props = {
  setModalVisible: (prev: boolean) => void;
  forCollection?: string;
}

const CreateListModal = ({setModalVisible, forCollection}: Props) => {
  
  const {requestToast} = useContext(ToastContext);
  
  const navigate = useNavigate();
  const createList = useCreateNewList();
  const createCollection = useCreateNewCollection();
  const {handleMedia} = useUploadMedia();
  
  const [listForm, setListForm] = useState<CreateListForm>({
    title: "",
    description: "",
    ascending: true,
    ordered: true,
    category: "",
    subCategories: [],
    isPublic: true
  });
  const [collectionForm, setCollectionForm] = useState<CreateCollectionForm>({
    title: "",
    description: "",
    category: "",
    subCategories: [],
    isPublic: true,
    period: 'weekly',
    startingKey: 1,
    isSmart: false
  });
  const [newListImage, setNewListImage] = useState<File>();
  const [newCollectionImage, setNewCollectionImage] = useState<File>();
  
  const isListValid = !!(forCollection 
    ? (listForm.title && listForm.description) 
    : (listForm.title && listForm.description && listForm.category)
  );
  const isCollectionValid = !!(collectionForm.title && collectionForm.description && collectionForm.category);
  
  const [currentTab, setCurrentTab] = useState<Tabs>("list");
  
  const tabs: TabItem[] = forCollection ? [
    {title: "List", onClick: () => setCurrentTab("list")},
  ] : [
    {title: "List", onClick: () => setCurrentTab("list")},
    {title: "Collection", onClick: () => setCurrentTab("collection")}
  ];
  
  const validateAndPost = () => {
    if (currentTab === "list" && isListValid) handlePostList();
    else if (currentTab === "collection" && isCollectionValid) handlePostCollection();
  }
  
  const handlePostList = async () => {
    
    const newList: CreateListPayload = {
      type: 'list',
      title: listForm.title,
      description: listForm.description,
      category: listForm.category as Categories,
      subCategories: listForm.subCategories,
      ascending: listForm.ascending,
      ordered: listForm.ordered,
      isPublic: listForm.isPublic,
      isDraft: true,
      parentCollection: forCollection
    }
    
    if (newListImage) {
      const newMedia = await handleMedia(newListImage);
      if (!newMedia) {
        requestToast('Failed to upload list image.', 'error');
        return;
      }
      Object.assign(newList, {image: newMedia.url});
    }
    console.log(newList);
    
    createList.mutate(newList, {
      onSuccess(data) {
        if (!data) return;
        setModalVisible(false);
        if (!forCollection) navigate(`/creator/${data._id}`);
      },
    });
  }
  
  const createSmart = useCreateSmartCollection();
  
  const handlePostCollection = async () => {
    if (!collectionForm.category) return;

    let newMedia: Media | false = false;
    let smartCollection: string | undefined = undefined;

    if (newCollectionImage) {
      newMedia = await handleMedia(newCollectionImage);
      if (!newMedia) {
        requestToast('Failed to upload collection image.', 'error');
        return;
      }
    };
    
    if (collectionForm.isSmart && collectionForm.startingKey) {
      try {
        smartCollection = await createSmart.mutateAsync({
          period: collectionForm.period,
          startingKey: collectionForm.startingKey,
          defaults: {
            description: collectionForm.description,
            image: newMedia ? newMedia.url : undefined
          }
        });
      } catch (err) {
        requestToast("Failed to create smart collection, try again.", "error");
        return;
      }
    }
    
    const newCollection: CreateCollectionPayload = {
      type: 'collection',
      title: collectionForm.title,
      description: collectionForm.description,
      category: collectionForm.category,
      subCategories: collectionForm.subCategories,
      isPublic: collectionForm.isPublic,
      isDraft: true,
      smartCollection
    }
    
    if (newMedia) {
      Object.assign(newCollection, {image: newMedia.url});
    }
    
    console.log(newCollection);
    
    createCollection.mutate(newCollection, {
      onSuccess(data, variables, context) {
        if (!data) return;
        setModalVisible(false);
        navigate(`/edit/collection/${data._id}`);
      },
    });
  }
  
  if (createList.isPending || createCollection.isPending) return (
    <Modal>
      <div className='item-list center-horizontal padding-large'>
        <LoadingIcon />
      </div>
    </Modal>
  )
  return (
    <Modal fixedBottomItem>
      <div className='bottom-item-layout no-gap'>
        <div className="item-list no-gap">
          <div className='right-item-layout padding remove-padding-bottom center-horizontal'>
            <h1 className="heading-medium">{forCollection ? 'Add List to Collection' : 'Create'}</h1>
            <button className='icon xs' onClick={() => setModalVisible(false)}>
              <CloseIcon />
            </button>
          </div>
          <TabBar tabData={tabs} sticky />
          {currentTab === "list" && <CreateListTab forCollection={forCollection} form={listForm} setForm={setListForm} setNewImage={setNewListImage} />}
          {currentTab === "collection" && <CreateCollectionTab form={collectionForm} setForm={setCollectionForm} setNewImage={setNewCollectionImage} />}
        </div>
        <div className="padding-small item-list top-border">
          {currentTab === 'list' 
            ? <button onClick={validateAndPost} aria-disabled={!isListValid} disabled={!isListValid} className='primary'>{"Create List"}</button>
            : <button onClick={validateAndPost} aria-disabled={!isCollectionValid} disabled={!isCollectionValid} className='primary'>{"Create Collection"}</button>
          }
        </div>
      </div>
    </Modal>
  )
}

export default CreateListModal;
