import { useContext, useState } from "react";
import { useDeleteList } from "../api/data-access";
import { ToastContext } from "../contexts/ToastContext";
import useDisplayServerError from "./useDisplayServerError";
import { useNavigate } from "react-router-dom";

const useHandleDeleteList = () => {
  const doDelete = useDeleteList();
  const {requestToast} = useContext(ToastContext);
  const {handleError} = useDisplayServerError();
  const navigate = useNavigate();
  
  const [isConfirming, setIsConfirming] = useState(false);
  
  const handleRequest = () => {
    setIsConfirming(true);
  }
  const handleCancel = () => {
    setIsConfirming(false);
  }
  
  const handleConfirm = async (listId: string, deleteSmartSet?: boolean) => {
    if (!isConfirming) return;
    
    doDelete.mutate({listId, deleteSmartSet}, {
      onSuccess: () => {
        requestToast('List deleted.', 'success');
        setIsConfirming(false);
        navigate('/');
      },
      onError: (err) => {
        setIsConfirming(false);
        handleError(err);
      }
    })
  }
  return {
    handleRequestDelete: handleRequest,
    handleConfirmDelete: handleConfirm,
    isConfirmingDelete: isConfirming,
    handleCancelDelete: handleCancel
  };
}
export default useHandleDeleteList;