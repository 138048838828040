import { Link, useLocation } from "react-router-dom";
import { AddIcon, FeedIcon, HomeIcon, RecommendIcon } from "../../assets/icons";
import { useEffect, useState } from "react";
import useCurrentUser from "../../hooks/useCurrentUser";
import { PopulatedUser } from "../../types/SharedTypes";
import CreateListModal from "../createList/CreateListModal";

const MainNavigationBar = ({auth}: {auth: boolean}) => {
  return auth
    ? <AuthNavigationBar />
    : <NoAuthNavigationBar />;
}

const NoAuthNavigationBar = () => {
  
  return (
    <>
      <nav 
        className="bottom-floating always-black"
        aria-label={"global navigation"}
      >
        <div>
          <div className="padding-small-horizontal right-item-layout center-horizontal full-height">
            <p className="subtitle-large">Experiment with the Peaklist list creator!</p>
            <Link to={'/creator/demo'}>
              <button className="primary">
                Try it out
              </button>
            </Link>
          </div>
        </div>
      </nav>
    </>
  )
}

const AuthNavigationBar = () => {
  
  const location = useLocation();
  const {user, isSuccess} = useCurrentUser();
  
  const [current, setCurrent] = useState("");
  const [createModalVisible, setCreateModalVisible] = useState(false);
  
  useEffect(() => {
    setCurrent(`${location.pathname.split('/')[1]}`);
  });
  
  if (!isSuccess) return null;
  return (
    <>
      {createModalVisible && <CreateListModal setModalVisible={setCreateModalVisible} />}
      <nav 
        className="bottom-floating always-black"
        aria-label={"global navigation"}
      >
        <DefaultNavBar current={current} user={user} handleCreate={() => setCreateModalVisible(true)} />
      </nav>
    </>
  )
}

type DefaultNavBarProps = {
  current: string;
  handleCreate: () => void;
  user: PopulatedUser;
};

const DefaultNavBar = ({current, user, handleCreate}: DefaultNavBarProps) => {
  return (
    <div className="main-nav-layout">
      <div className="item-row">
        <Link to={"/"} data-active={!current}>
          <button className="icon nav">
            <HomeIcon />
          </button>
        </Link>
        <Link to={"/recommend"} data-active={current === "recommend"}>
          <button className="icon nav">
            <RecommendIcon />
          </button>
        </Link>
      </div>
      
      <div className="item-row center-horizontal">
        <button className="icon hero background-subtle nav small" onClick={handleCreate}>
          <AddIcon />
        </button>
      </div>
      
      <div className="item-row">
        <Link to={"/lists"} data-active={current === "lists"}>
          <button className="icon nav">
            <FeedIcon />
          </button>
        </Link>
        <Link to={`/user/${user?.username}`} data-active={current === "profile"}>
          <button className="icon nav">
            <div className="user-avatar">
              <img src={user.picture} />
            </div>
          </button>
        </Link>
      </div>
    </div>
  )
};

export default MainNavigationBar;