import { useContext } from "react";
import { useCreateMedia } from "../api/data-access";
import { Media } from "../types/SharedTypes";
import { ToastContext } from "../contexts/ToastContext";

const useUploadMedia = () => {
  
  const createMedia = useCreateMedia();
  const {requestToast} = useContext(ToastContext);
  
  const handleMedia = async (file: File): Promise<Media | false> => {
    
    const form = new FormData();
    
    form.append('file', file);
    let resData: Media | false = false;
    
    await createMedia.mutateAsync(form, {
      onSuccess: (data, variables, context) => {
        if (!data) resData = false;
        else resData = data;
      },
      onError: () => {
        console.log('media failed to upload');
        requestToast('Media failed to upload.', 'error');
      }
    })
    return resData;
  }
  return {handleMedia};
}
export default useUploadMedia;