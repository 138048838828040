const ComingSoon = () => {
  return (
    <div className="coming-soon item-list">
      <h2 className="heading-medium center">coming soon</h2>
      <div className="item-list large center-horizontal center-vertical">
        <p className="body weight-light">Have a suggestion or feature request?</p>
        <p className="heading-medium weight-light">support@peaklist.app</p>
      </div>
    </div>
  )
}
export default ComingSoon;