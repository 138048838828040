import {createContext, useEffect, useRef, useState} from 'react';
import Toast from '../components/shared/Toast';

type ToastState = 'error' | 'success' | 'warn' | undefined;

type ToastConfig = {
  visible: boolean;
  state?: ToastState;
  content: string;
}

type ToastContextValue = {
  toastConfig: ToastConfig;
  requestToast: (content: string, state?: ToastState) => void;
}

export const ToastContext = createContext<ToastContextValue>({
  toastConfig: {
    visible: false,
    state: undefined,
    content: ''
  },
  requestToast: () => null,
});

const TOAST_LIFE_LENGTH = 3600;

const ToastContextProvider = ({children}: {children: React.ReactNode}) => {
  const [toastConfig, setToastConfig] = useState<ToastConfig>({
    visible: false,
    state: undefined,
    content: ''
  })
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | void>();

  const clearToast = () => {
    console.log("clear");
    
    setToastConfig({
      visible: false,
      state: undefined,
      content: ''
    });
  };

  const startToast = () => {
    timeoutRef.current = setTimeout(() => {
      clearToast();
    }, TOAST_LIFE_LENGTH);
  };

  const requestToast = (content: string, state: ToastState = undefined) => {
    if (!content) return;
    if (timeoutRef.current) {
      //toast is already active
      console.log('existing toast');
      
      timeoutRef.current = clearTimeout(timeoutRef.current);
      clearToast();
    }
    setToastConfig({
      visible: true,
      state,
      content
    });
    startToast();
  };

  return (
    <ToastContext.Provider value={{
      toastConfig,
      requestToast,
    }}>
      <Toast />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastContextProvider;
