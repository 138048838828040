import { useContext } from "react";
import { useReportContent } from "../../../api/data-access";
import { InfoIcon } from "../../../assets/icons";
import { ToastContext } from "../../../contexts/ToastContext";
import useDisplayServerError from "../../../hooks/useDisplayServerError";
import { ReportPayload } from "../../../types/SharedTypes";
import Modal from "../../Modal";

type Props = {
  setModalVisible: (prev: boolean) => void;
  contentId: string;
  contentType: ReportPayload['ref'];
}

const reasons: ReportPayload["reason"][] = ['harmful', 'inappropriate', 'misinformation', 'impersonation', 'spam', 'hate-speech', 'harassment'];

const ReportModal = ({setModalVisible, contentId, contentType}: Props) => {

  const {requestToast} = useContext(ToastContext);

  const reportContent = useReportContent();
  const {handleError} = useDisplayServerError();

  const handleReport = (reason: ReportPayload["reason"]) => {
    reportContent.mutate({reason, content: contentId, ref: contentType}, {
      onSuccess: () => {
        setModalVisible(false);
        requestToast('Your report was sent.', 'success');
      },
      onError: (err) => handleError(err)
    });
  }

  const text = contentType === 'User' ? 'user' : contentType === 'ListPost' ? 'list' : 'list item';

  return (
    <Modal
      setModalVisible={setModalVisible}
      modalTitle={`Report ${text}`}
      controls
      bottomPane
      scroll
      children={
        <>
          <div className="item-list large">
            <div className="tile padding-small item-row">
              <InfoIcon />
              <p className="body spaced">You can only report a {text} once. If you report a second time, the first will be overwritten.</p>
            </div>
            <p className="subtitle-large secondary">Reason</p>
            <div className="item-list">
              {reasons.map(reason =>
                <button key={reason} className="text" data-alert={true} onClick={() => handleReport(reason)}>
                  <p className="heading-small header weight-light">{reason}</p>
                </button>
              )}
            </div>
          </div>
        </>
      }
    />
  )
}
export default ReportModal;