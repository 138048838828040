import { ChangeEvent, KeyboardEvent, KeyboardEventHandler, useContext, useState } from "react";
import { PublishIcon } from "../../../assets/icons";
import { ToastContext } from "../../../contexts/ToastContext";

type TextInputChange = ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>;

type TextInputProps = {
  value: string | undefined | null;
  onChange: (value: string) => void;
  id: string;
  isNumber?: boolean;
  label?: string;
  textarea?: boolean;
  placeholder?: string;
  required?: boolean;
  secondary?: boolean;
  overrideType?: string;
  onEnterKey?: () => void;
  note?: string;
  copyButton?: boolean;
};

const TextInput = ({
  value,
  onChange,
  id,
  label,
  placeholder,
  textarea,
  required,
  secondary,
  overrideType,
  onEnterKey,
  isNumber,
  note,
  copyButton
}: TextInputProps) => {

  const {requestToast} = useContext(ToastContext);

  const handleCopy = () => {
    navigator.clipboard.writeText(value || '');
    requestToast("Copied link.", 'success');
  }
  
  const onInputChange = (e: TextInputChange) => {
    if (isNumber) {
      const value = e.target.value.replace(/\D/g,'');
      console.log(value);
      onChange(value);
      return;
    };
    onChange(e.target.value);
  }
  
  const handleEnterKey = (code: KeyboardEvent<HTMLInputElement>['key']) => {
    if (!onEnterKey) return;
    if (code === 'Enter') {
      onEnterKey();
    }
  }
  
  if (textarea) return (
    <div className={`text-input multiline ${secondary ? "secondary" : ''}`} aria-required={required}>
      {label && <label htmlFor={id} className="subtitle-medium">{label}</label>}
      <textarea onKeyDown={(e) => handleEnterKey(e.key)} placeholder={placeholder} id={id} value={value || ""} onChange={onInputChange} />
        {note && <p className="subtitle-small weight-light indent secondary">{note}</p>}
    </div>
  )
  return (
    <div className={`text-input ${secondary ? "secondary" : ''} ${copyButton ? "right-item-layout small": ""}`} aria-required={required} data-label={!!label}>
      {label && <label htmlFor={id} className="subtitle-medium">{label}</label>}
      <input onKeyDown={(e) => handleEnterKey(e.key)} type={overrideType || "text"} placeholder={placeholder} id={id} value={value || ""} onChange={onInputChange} />
      {copyButton && <button aria-disabled={!value} onClick={handleCopy} className="subtle">Copy</button>}
      {note && <p className="subtitle-small weight-light indent secondary">{note}</p>}
    </div>
  )
}
export default TextInput;