import { PeakLogo } from "../assets/icons";
import PageWrapper from "./PageWrapper";

const Legal = ({ page }: { page: "privacy" | "terms" }) => {
  return (
    <PageWrapper>
      <div className="item-list large padding">
        <div className="logo">
          <PeakLogo />
        </div>
        <div className="item-list large">
          {page === 'privacy' && <Privacy />}
          {page === 'terms' && <Terms />}
        </div>
      </div>
    </PageWrapper>
  )
}

const Terms = () => {
  return (
    <>
      <p className="heading-small">TERMS AND CONDITIONS</p>
      <p className="body spaced">These terms and conditions (the "Terms and Conditions") govern the use of <a className="body link" href="https://peaklist.app">https://peaklist.app</a> (the "Site"). This Site is owned and operated by Aaron DeRoche.</p>
      <p className="body spaced">By using this Site, you indicate that you have read and understand these Terms and Conditions and agree to abide by them at all times.</p>
      <p className="body spaced">THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION CLAUSE THAT IMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE READ IT CAREFULLY.</p>
      <p className="heading-small">Intellectual Property</p>
      <p className="body spaced">All content published and made available on our Site is the property of Aaron DeRoche and the Site's creators. This includes, but is not limited to images, text, logos, documents, downloadable files and anything that contributes to the composition of our Site.</p>
      <p className="heading-small">Age Restrictions</p>
      <p className="body spaced">The minimum age to use our Site is 13 years old. By using this Site, users agree that they are over 13 years old. We do not assume any legal responsibility for false statements about age.</p>
      <p className="heading-small">User Contributions</p>
      <p className="body spaced">Users may post the following information on our Site: 		</p>
      <ul className="dotted">
        <li><p className="body spaced">Photos; and  </p>
        </li>
        <li><p className="body spaced">Public comments.</p>
        </li>
      </ul>
      <p className="body spaced">By posting publicly on our Site, you agree not to act illegally or violate these Terms and Conditions.</p>
      <p className="heading-small">Accounts</p>
      <p className="body spaced">When you create an account on our Site, you agree to the following: 	</p>
      <ol>
        <li>You are solely responsible for your account and the security and privacy of your account, including passwords or sensitive information attached to that account</li>
      </ol>
      <p className="body spaced">We reserve the right to suspend or terminate your account if you are using our Site illegally or if you violate these Terms and Conditions.</p>
      <p className="heading-small">Links to Other Websites</p>
      <p className="body spaced">Our Site contains links to third party websites or services that we do not own or control. We are not responsible for the content, policies, or practices of any third party website or service linked to on our Site. It is your responsibility to read the terms and conditions and privacy policies of these third party websites before using these sites.</p>
      <p className="heading-small">Limitation of Liability</p>
      <p className="body spaced">Aaron DeRoche and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses, damages, liabilities and expenses including legal fees from your use of the Site.</p>
      <p className="heading-small">Indemnity</p>
      <p className="body spaced">Except where prohibited by law, by using this Site you indemnify and hold harmless Aaron DeRoche and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions, claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our Site or your violation of these Terms and Conditions.</p>
      <p className="heading-small">Applicable Law</p>
      <p className="body spaced">These Terms and Conditions are governed by the laws of the Province of Ontario.</p>
      <p className="heading-small">Dispute Resolution</p>
      <p className="body spaced">Subject to any exceptions specified in these Terms and Conditions, if you and Aaron DeRoche are unable to resolve any dispute through informal discussion, then you and Aaron DeRoche agree to submit the issue first before a non-binding mediator and to an arbitrator in the event that mediation fails. The decision of the arbitrator will be final and binding. Any mediator or arbitrator must be a neutral party acceptable to both you and Aaron DeRoche. The costs of any mediation or arbitration will be paid for by the losing party.</p>
      <p className="body spaced">Notwithstanding any other provision in these Terms and Conditions, you and Aaron DeRoche agree that you both retain the right to bring an action in small claims court and to bring an action for injunctive relief or intellectual property infringement.</p>
      <p className="heading-small">Severability</p>
      <p className="body spaced">If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent or invalid under applicable laws, those provisions will be deemed void and will be removed from these Terms and Conditions. All other provisions will not be affected by the removal and the rest of these Terms and Conditions will still be considered valid.</p>
      <p className="heading-small">Changes</p>
      <p className="body spaced">These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to reflect any changes to the way we operate our Site and the way we expect users to behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site.</p>
      <p className="heading-small">Contact Details</p>
      <p className="body spaced">Please contact us if you have any questions or concerns. Our contact details are as follows:</p>
      <p className="body spaced">(905) 741-9335</p>
      <p className="body spaced">aaron@deroche.design</p>
      <p className="body spaced">639 Ridge Rd W, Grimsby, Ontario, L3M4E7</p>
    </>
  )
}

const Privacy = () => {
  return (
    <>
      <h1 className="heading-medium weight-bold">Privacy Policy</h1>
      <p className="body spaced">Last updated: November 07, 2024</p>
      <p className="body spaced">This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
      <p className="body spaced">We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
      <h2 className="heading-small weight-bold">Interpretation and Definitions</h2>
      <h3 className="heading-small">Interpretation</h3>
      <p className="body spaced">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
      <h3 className="heading-small">Definitions</h3>
      <p className="body spaced">For the purposes of this Privacy Policy:</p>
      <ul className="dotted">
        <li><p className="body spaced">Account means a unique account created for You to access our Service or parts of our Service.</p>
        </li>
        <li><p className="body spaced">Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
        </li>
        <li><p className="body spaced">Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Peaklist.</p>
        </li>
        <li><p className="body spaced">Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
        </li>
        <li><p className="body spaced">Country refers to: Ontario, Canada</p>
        </li>
        <li><p className="body spaced">Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
        </li>
        <li><p className="body spaced">Personal Data is any information that relates to an identified or identifiable individual.</p>
        </li>
        <li><p className="body spaced">Service refers to the Website.</p>
        </li>
        <li><p className="body spaced">Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
        </li>
        <li><p className="body spaced">Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>
        </li>
        <li><p className="body spaced">Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
        </li>
        <li><p className="body spaced">Website refers to Peaklist, accessible from <a className="body link" href="https://peaklist.app">https://peaklist.app</a></p>
        </li>
        <li><p className="body spaced">You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
        </li>
      </ul>
      <h2 className="heading-small weight-bold">Collecting and Using Your Personal Data</h2>
      <h3 className="heading-small">Types of Data Collected</h3>
      <h4 className="subtitle-large weight-bold ">Personal Data</h4>
      <p className="body spaced">While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
      <ul className="dotted">
        <li><p className="body spaced">Email address</p></li>
        <li><p className="body spaced">Usage Data</p></li>
      </ul>
      <h4 className="subtitle-large weight-bold ">Usage Data</h4>
      <p className="body spaced">Usage Data is collected automatically when using the Service.</p>
      <p className="body spaced">Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
      <p className="body spaced">When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
      <p className="body spaced">We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
      <h4 className="subtitle-large weight-bold ">Information from Third-Party Social Media Services</h4>
      <p className="body spaced">The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:</p>
      <ul className="dotted">
        <li><p className="body spaced">Google</p></li>
      </ul>
      <p className="body spaced">If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.</p>
      <p className="body spaced">You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.</p>
      <h4 className="subtitle-large weight-bold ">Tracking Technologies and Cookies</h4>
      <p className="body spaced">We do not use Cookies to track your activity or provide necessary functionality on this Website. We are committed to offering a cookie-free experience to respect your privacy and avoid intrusive tracking.</p>
      <p className="body spaced">However, please note that some cross-site cookies may be introduced depending on how You use the Website. Specifically:</p>
      <ul className="dotted">
        <li><p className="body spaced">External Resources: The Website may display images hosted by third-party services. While we do not directly use cookies, these external providers may place or read cookies to facilitate the delivery of their assets or for their own purposes. This is beyond our control.</p></li>
      </ul>
      <p className="body spaced">If you have concerns about these third-party cookies, we recommend consulting the privacy policies of the respective providers for additional details. By using the Website, you acknowledge that external resources may introduce cookies, depending on how you interact with the site.</p>
      <h3 className="heading-small">Use of Your Personal Data</h3>
      <p className="body spaced">The Company may use Personal Data for the following purposes:</p>
      <ul className="dotted">
        <li><p className="body spaced">To provide and maintain our Service, including to monitor the usage of our Service.</p>
        </li>
        <li><p className="body spaced">To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
        </li>
        <li><p className="body spaced">For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
        </li>
        <li><p className="body spaced">To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
        </li>
        <li><p className="body spaced">To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
        </li>
        <li><p className="body spaced">To manage Your requests: To attend and manage Your requests to Us.</p>
        </li>
        <li><p className="body spaced">For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
        </li>
        <li><p className="body spaced">For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
        </li>
      </ul>
      <p className="body spaced">We may share Your personal information in the following situations:</p>
      <ul className="dotted">
        <li><p className="body spaced">With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</p></li>
        <li><p className="body spaced">For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</p></li>
        <li><p className="body spaced">With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</p></li>
        <li><p className="body spaced">With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</p></li>
        <li><p className="body spaced">With Your consent: We may disclose Your personal information for any other purpose with Your consent.</p></li>
      </ul>
      <h3 className="heading-small">Retention of Your Personal Data</h3>
      <p className="body spaced">The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
      <p className="body spaced">The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
      <h3 className="heading-small">Transfer of Your Personal Data</h3>
      <p className="body spaced">Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
      <p className="body spaced">Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
      <p className="body spaced">The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
      <h3 className="heading-small">Delete Your Personal Data</h3>
      <p className="body spaced">You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
      <p className="body spaced">Our Service may give You the ability to delete certain information about You from within the Service.</p>
      <p className="body spaced">You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
      <p className="body spaced">Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
      <h3 className="heading-small">Disclosure of Your Personal Data</h3>
      <h4 className="subtitle-large weight-bold ">Law enforcement</h4>
      <p className="body spaced">Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
      <h4 className="subtitle-large weight-bold ">Other legal requirements</h4>
      <p className="body spaced">The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
      <ul className="dotted">
        <li><p className="body spaced">Comply with a legal obligation</p></li>
        <li><p className="body spaced">Protect and defend the rights or property of the Company</p></li>
        <li><p className="body spaced">Prevent or investigate possible wrongdoing in connection with the Service</p></li>
        <li><p className="body spaced">Protect the personal safety of Users of the Service or the public</p></li>
        <li><p className="body spaced">Protect against legal liability</p></li>
      </ul>
      <h3 className="heading-small">Security of Your Personal Data</h3>
      <p className="body spaced">The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
      <h2 className="heading-small weight-bold">Children's Privacy</h2>
      <p className="body spaced">Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
      <p className="body spaced">If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
      <h2 className="heading-small weight-bold">Links to Other Websites</h2>
      <p className="body spaced">Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
      <p className="body spaced">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
      <h2 className="heading-small weight-bold">Changes to this Privacy Policy</h2>
      <p className="body spaced">We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
      <p className="body spaced">We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
      <p className="body spaced">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
      <h2 className="heading-small weight-bold">Contact Us</h2>
      <p className="body spaced">If you have any questions about this Privacy Policy, You can contact us:</p>
      <ul className="dotted">
        <li><p className="body spaced">By email: aaron@deroche.design</p></li>
      </ul>
    </>
  )
}

export default Legal;