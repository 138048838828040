import PageWrapper from "./PageWrapper";
import { Outlet } from "react-router-dom";
import useHideHeader from "../hooks/useHideHeader";
import TabBar from "../components/navigation/TabBar";
import useCurrentUser from "../hooks/useCurrentUser";
import LoadingContainer from "../components/shared/LoadingContainer";
import Dropdown from "../components/shared/inputs/Dropdown";
import { FeedFilterOptions } from "../api/data-access";
import { Dispatch, SetStateAction } from "react";
import { categories } from "../assets/categories";
import { CloseIcon } from "../assets/icons";
import { Categories, CollectionPeriods, ListPostTypes } from "../types/SharedTypes";
import { collectionPeriods } from "../assets/general";

const tabs = [
  {title: "Public", link:'public'},
  {title: "Personal", link:'personal'},
  {title: "Drafts", link:'drafts'},
  {title: "Likes", link:'likes'}
];

const UserLists = () => {
  
  useHideHeader();
  const {user, isSuccess} = useCurrentUser();
  
  if (!isSuccess) return <LoadingContainer />;
  return (
    <PageWrapper padding={false}>
      <div data-padding={true} className="item-list">
        <h1 className="heading-medium weight-light">
          Your Lists
        </h1>
        <div className="item-row">
          <p className="subtitle-medium secondary">{`${user.listCount} List${user.listCount === 1 ? '' : 's'}`}</p>
          <p className="subtitle-medium secondary">{`${user.collectionCount} Collection${user.collectionCount === 1 ? '' : 's'}`}</p>
        </div>
      </div>
      <TabBar tabData={tabs} sticky />
      <Outlet />
    </PageWrapper>
  )
};

type FilterProps = {
  filters: FeedFilterOptions;
  setFilters: Dispatch<SetStateAction<FeedFilterOptions>>;
}

const typeOptions = [{label: "All", value: 'all'}, {label: "Lists", value: 'list'}, {label: "Collections", value: 'collection'}];
const categoryOptions = [{label: "All", value: 'all'}, ...categories];
const periodOptions = [{label: "All", value: 'all'}, ...collectionPeriods];

export const UserListsFilters = ({filters, setFilters}: FilterProps) => {

  const setTypeFilter = (val: string | undefined) => {
    setFilters(prev => ({...prev, type: val as ListPostTypes}));
  }
  const setPeriodFilter = (val: string | undefined) => {
    setFilters(prev => ({...prev, period: val as CollectionPeriods}));
  }
  const setCategoryFilter = (val: string | undefined) => {
    setFilters(prev => ({...prev, category: val as Categories}));
  }
  const clearFilters = () => {
    setFilters({category: undefined, type: undefined});
  }

  return (
    <div className="horizontal-scroll-container end-spacer">
      <div className="item-row">
        {(filters.category || filters.type || filters.period) && 
          <button className="icon background xs" onClick={clearFilters}>
            <CloseIcon />
          </button>
        }
        <Dropdown
          value={categories.find(t => t.value === filters.category) || null}
          options={categoryOptions}
          onChange={(opt) => setCategoryFilter(opt?.value === 'all' ? undefined : opt?.value)}
          error={false}
          placeholder={'Category'}
          isSearchable={false}
          classNames="fit-width bright"
          usePortal
        />
        <Dropdown
          value={typeOptions.find(t => t.value === filters.type) || null}
          options={typeOptions}
          onChange={(opt) => setTypeFilter(opt?.value === 'all' ? undefined : opt?.value)}
          error={false}
          placeholder={'Type'}
          isSearchable={false}
          classNames="fit-width bright"
          usePortal
        />
        <Dropdown
          value={periodOptions.find(t => t.value === filters.period) || null}
          options={periodOptions}
          onChange={(opt) => setPeriodFilter(opt?.value === 'all' ? undefined : opt?.value)}
          error={false}
          placeholder={'Period'}
          isSearchable={false}
          classNames="fit-width bright"
          usePortal
        />
      </div>
    </div>
  )
}

export default UserLists;