import { useEffect } from "react";

export const useDocumentTitle = (title: string, dependency?: any) => {
  useEffect (() => {
    const prevTitle = document.title;
    document.title = title ? `${title} | Peaklist` : 'Peaklist';

    return () => {
      document.title = prevTitle;
    };
  }, [dependency]);
};