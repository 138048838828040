import { baseAPIUrl } from "../constants/urls";
import { ReportPayload } from "../types/SharedTypes";
import createAxios from "./authInterceptor";

const reportUrl = `${baseAPIUrl}/report`;

const reportApi = createAxios;

export const reportContent = async (payload: ReportPayload) => {
  await reportApi.post<void>(reportUrl, payload);
}