import { baseAPIUrl } from "../constants/urls";
import { Like } from "../types/SharedTypes";
import authClient from "./authInterceptor";

const likesUrl = `${baseAPIUrl}/likes`;

const likesApi = authClient;

export const getUserLikes = async () => {
  const res = await likesApi.get<Like[]>(likesUrl + "/user");
  return res.data;
}

export const createLike = async (postId: string) => {
  const res = await likesApi.post<void>(`${likesUrl}/${postId}`);
  return res.data;
}
export const removeLike = async (postId: string) => {
  const res = await likesApi.delete<void>(`${likesUrl}/${postId}`);
  return res.data;
}