import { useGetUserLikes } from "../../api/data-access/generalHooks";
import LoadingIcon from "../../components/shared/LoadingIcon";
import FeedCard from "../../components/shared/cards/FeedCard";

const LikesTab = () => {
  
  const {data, isSuccess} = useGetUserLikes();

  if (!isSuccess || !data) return (
    <div className="item-row center-horizontal padding">
      <LoadingIcon />
    </div>
  )
  return (
    <section data-padding-small={true}>
      <div className="item-list">
        {data.map((like) => 
          <FeedCard key={like._id} post={like.item} small link={`/list/${like.item._id}`} isInFeed={false} themedHeader />
        )}
        {data.length === 0 && 
          <p className="subtitle-large secondary center">
            Nothing to show
          </p>
        }
      </div>
    </section>
  )
}
export default LikesTab;