import { useContext } from "react";
import { ToastContext } from "../contexts/ToastContext";
import { AxiosError } from "axios";

const useDisplayServerError = () => {
  const {requestToast} = useContext(ToastContext);
  
  const handleError = (_error: any) => {
    const error = _error as AxiosError;
    requestToast(error.response?.data as string || "Something went wrong", 'error');
  }
  return {handleError};
}
export default useDisplayServerError;