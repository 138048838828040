import { useContext, useState } from "react";
import { ToastContext } from "../contexts/ToastContext";
import useUploadMedia from "./useUploadMedia";
import useDisplayServerError from "./useDisplayServerError";
import { Categories, CollectionUpdatePayload, DecoratedCollection, SmartCollectionUpdatePayload, UpdateCollectionPayload } from "../types/SharedTypes";
import { useCreateSmartCollection, useUpdateCollection, useUpdateCollectionsInSmartSet, useUpdateSmartCollection } from "../api/data-access";
import { CreateCollectionForm } from "../components/createList/CreateListModal";

const useHandleUpdateCollection = (onSuccess?: () => void) => {
  
  const {requestToast} = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);

  const updateCollectionMutation = useUpdateCollection();
  const updateCollectionsInSetMutation = useUpdateCollectionsInSmartSet();
  const updateSmartSet = useUpdateSmartCollection();
  const createSmart = useCreateSmartCollection();
  
  const {handleMedia} = useUploadMedia();
  const {handleError} = useDisplayServerError();
  
  const updateCollectionSimple = async (update: CollectionUpdatePayload, collectionId: string, callOnSuccess: boolean = true) => {
    const updatedList: UpdateCollectionPayload = {id: collectionId, update};
    setIsLoading(true);
    try {
      const res = await updateCollectionMutation.mutateAsync(updatedList);
      requestToast('Updated collection.', 'success');
      setIsLoading(false);
      onSuccess && callOnSuccess && onSuccess();
      return res;
    } catch (err) {
      setIsLoading(false);
      handleError(err);
    }
  }
  
  const updateCollection = async (collectionForm: CreateCollectionForm, collection: DecoratedCollection, newImage?: File) => {
    
    setIsLoading(true);
    
    let image = collectionForm.image;
    let smartCollection: string | undefined = undefined;
    
    if (newImage) {
      const newMedia = await handleMedia(newImage);
      if (!newMedia) {
        console.log('Failed to update image.');
        setIsLoading(false);
        return;
      }
      image = newMedia.url;
    }
    
    if (collectionForm.isSmart && collection.smartCollection) {
      //update smart collection
      const update: SmartCollectionUpdatePayload = {
        isDisabled: collectionForm.isSmartDisabled || false,
        period: collectionForm.period,
        startingKey: collectionForm.startingKey || 1
      }
      try {
        const res = await updateSmartSet.mutateAsync({id: collection.smartCollection._id, update});
      } catch (err) {
        handleError(err);
        setIsLoading(false);
        return;
      }
    }
    else if (collectionForm.isSmart && collectionForm.startingKey) {
      //converting an existing collection to smart
      try {
        smartCollection = await createSmart.mutateAsync({
          period: collectionForm.period,
          startingKey: collectionForm.startingKey,
          defaults: {
            description: collectionForm.description,
            image
          }
        });
      } catch (err) {
        requestToast("Failed to create smart collection, try again.", "error");
        return;
      }
    }
    
    const update: CollectionUpdatePayload = {
      title: collectionForm.title,
      description: collectionForm.description,
      category: collectionForm.category as Categories,
      subCategories: collectionForm.subCategories || [],
      isPublic: collectionForm.isPublic,
      isDraft: collection.isDraft,
      image,
      smartCollection
    }
    
    const updatedList: UpdateCollectionPayload = {id: collection._id, update};
    
    updateCollectionMutation.mutate(updatedList, {
      onSuccess() {
        requestToast('Updated collection.', 'success');
        setIsLoading(false);
        onSuccess && onSuccess();
      },
      onError: (err) => {
        handleError(err);
        setIsLoading(false);
      }
    });
  }
  
  const updateCollectionsInSet = async (update: CollectionUpdatePayload, smartId: string) => {
    
    setIsLoading(true);
    const updatedList: UpdateCollectionPayload = {id: smartId, update};
    try {
      await updateCollectionsInSetMutation.mutateAsync(updatedList);
      requestToast('Updated entire collection.', 'success');
      setIsLoading(false);
      return true;
    } catch (err) {
      setIsLoading(false);
      handleError(err);
    }
  }

  const updateSmartSetDefaults = async (description: string | undefined, image: string | undefined, smartId: string) => {
    setIsLoading(true);
    try {
      await updateSmartSet.mutateAsync({id: smartId, update: {
        defaults: {description, image}
      }});
      requestToast("Defaults updated for smart collection.", "success");
      setIsLoading(false);
      onSuccess && onSuccess();
    } catch (err) {
      setIsLoading(false);
      handleError(err);
    }
  }
  
  return {updateCollection, updateCollectionSimple, updateCollectionsInSet, isLoading, updateSmartSetDefaults}
}
export default useHandleUpdateCollection;