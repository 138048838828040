import { baseAPIUrl } from "../constants/urls";
import authClient, {openClient} from "./authInterceptor";
import { DecoratedAuthUser, DecoratedUser, User, UserPinnedPayload, UserUpdatePayload } from "../types/SharedTypes";

const userUrl = `${baseAPIUrl}/user`;
const openUserUrl = userUrl + '/open';

const openUserApi = openClient;
const userApi = authClient;

//OPEN
export const getUserByUsernameOpen = async (username: string) => {
  const res = await openUserApi.get<DecoratedUser>(`${openUserUrl}/username/${username}`);
  return res.data;
}

//AUTH
export const getAuthUser = async () => {
  const res = await userApi.get<DecoratedAuthUser>(`${userUrl}/auth`);
  return res.data;
}

export const getUserById = async (userId: string) => {
  const res = await userApi.get<DecoratedUser>(`${userUrl}/${userId}`);
  return res.data;
}
export const getUserByUsername = async (username: string) => {
  const res = await userApi.get<DecoratedUser>(`${userUrl}/username/${username}`);
  return res.data;
}

export const updateUserInfo = async (update: UserUpdatePayload) => {
  const res = await userApi.put<User>(`${userUrl}/info`, update);
  return res.data;
}

export const updateUserPinned = async (update: UserPinnedPayload) => {
  const res = await userApi.put<User>(`${userUrl}/pinned`, update);
  return res.data;
}

export const getUserData = async (csv?: boolean) => {
  const res = await userApi.put<ReadableStream>(`${baseAPIUrl}/exportdata/${csv ? 'csv' : 'json'}`);
  return res.data;
}