import React, {createContext, useState} from "react";
import themes, { ThemeType, defaultLightTheme, defaultDarkTheme, light, dark } from "../assets/themes";
import { getLocalPref } from "../tools/Helpers";
import { localPrefKeys } from "../constants/general";

type ThemeContextValue = {
  setNewTheme: (themeName?: string) => void;
  resetTheme: () => void;
  setFullPage: (state: boolean) => void;
  isDark: boolean
}

export type ThemeProperties = Omit<ThemeType, 'title' | 'slug' | 'isDark'> & {
  isDark?: boolean;
};
const rs = document.documentElement.style;

export const ThemeContext = createContext<ThemeContextValue>({
  setNewTheme: () => null,
  resetTheme: () => null,
  setFullPage: () => null,
  isDark: true
});

export const ThemeContextProvider = ({children}: {children: React.ReactNode}) => {
  
  const [isDark, setIsDark] = useState(true);
  
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    setIsDark(event.matches);
  });
  
  const updateProperties = (theme: ThemeProperties) => {
    rs.setProperty('--themed-highlight', theme.highlight);
    rs.setProperty('--themed-body', theme.body);
    rs.setProperty('--themed-text', theme.text);
    rs.setProperty('--themed-text-highlight', theme.highlightText);
    rs.setProperty('--themed-line', theme.line);
    rs.setProperty('--themed-overlay', theme.isDark ? dark.overlay : light.overlay);
    rs.setProperty('--themed-border', theme?.highlightHasBorder 
      ? `1px solid ${theme.text}`
      : "unset"
    )
  }
  
  const setFullPage = (state: boolean) => {
    const root = document.getElementById("root");
    if (!root) return;
    
    if (state) root.dataset.themed = "true";
    else root.dataset.themed = "false";
  }
  
  const setNewTheme = (themeName?: string) => {
    
    const forceDark = getLocalPref(localPrefKeys.forceDarkTheme);
    
    let theme: ThemeType | undefined;
    const defaultTheme = isDark ? defaultDarkTheme : defaultLightTheme;
    
    if (!themeName || themeName === 'default') theme = defaultTheme;
    else theme = themes.find(t => t.slug === themeName);
    
    if (!theme) {
      console.warn("No theme found with name: ", themeName);
    }
    if (!theme?.isDark && forceDark) theme = defaultTheme;
    updateProperties(theme || defaultTheme);
  }
  
  const resetTheme = () => {
    updateProperties({
      highlight: "unset",
      body: "unset",
      highlightText: "unset",
      text: "unset",
      line: "unset",
      isDark: false
    })
  }
  
  return (
    <ThemeContext.Provider value={{
      setNewTheme,
      resetTheme,
      setFullPage,
      isDark
    }}>
      {children}
    </ThemeContext.Provider>
  )
}