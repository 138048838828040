import { useGetAuthUser } from "../api/data-access";
import { DecoratedUser } from "../types/SharedTypes";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import useAuthentication from "./useAuthentication";

type Return = {
  user: undefined;
  isSuccess: false;
} | {
  user: DecoratedUser;
  isSuccess: true;
}

const useCurrentUser = (): Return => {
  
  const {authUserId} = useContext(AuthContext);
  const {isAuthenticated} = useAuthentication();
  
  const {data: user, isSuccess} = useGetAuthUser(isAuthenticated && !!authUserId);
  
  const returnValue = {user, isSuccess} as Return; //typecast to keep isSuccess is data attached
  return returnValue;
}
export default useCurrentUser;