import html2canvas from 'html2canvas';
import QRCode from 'qrcode';
import themes from '../assets/themes';
import { hslToHex } from '../tools/Helpers';

export function useGenerateListImage() {

  const createAndDownload = async (listElementId: string, websiteUrl: string, includeQRCode: boolean, title: string, theme?: string) => {
    const listElement = document.getElementById(listElementId);
    if (!listElement) {
      console.error("List element not found");
      return;
    }

    function fnIgnoreElements(el: Element) {
      if (typeof el.shadowRoot == 'object' && el.shadowRoot !== null) return true;
      return false;
    }

    try {
      // Capture the list as a canvas
      const listCanvas = await html2canvas(listElement, { scale: 2, useCORS: true, allowTaint: true, ignoreElements: fnIgnoreElements});

      // Create a new canvas to combine list and QR code
      const finalCanvas = document.createElement('canvas');
      const ctx = finalCanvas.getContext('2d');

      if (!ctx) throw new Error("No context");

      finalCanvas.width = listCanvas.width;
      finalCanvas.height = listCanvas.height; // Adjust height for QR code space

      // Draw the list on the final canvas
      ctx.drawImage(listCanvas, 0, 0);

      if (includeQRCode) {

        const themeSet = themes.find(t => t.slug === theme);
        const light = themeSet && hslToHex(themeSet.body);
        const dark = themeSet && hslToHex(themeSet.text);
        // Generate the QR code
        const qrCodeDataUrl = await QRCode.toDataURL(websiteUrl, { width: 200, color: {light, dark}});
        const qrImage = new Image();
        qrImage.src = qrCodeDataUrl;
        await qrImage.decode();

        // Draw the QR code on the final canvas
        ctx.drawImage(qrImage, finalCanvas.width - 220, 20); // Position QR code
      }

      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = finalCanvas.toDataURL('image/png');
      downloadLink.download = title + '.png';
      downloadLink.click();
    } catch (error) {
      console.error("Error generating list image:", error);
    }
  };

  return {createAndDownload};
}
