import { useNavigate, useParams } from "react-router";
import PageWrapper from "./PageWrapper";
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { DecoratedList, DecoratedListItem, ListItemType } from "../types/SharedTypes";
import { ArrowHeadLeftIcon, FeedIcon, LikeIcon, LikeIconSolid, LinkIcon, OptionsHorizontalIcon, SaveIcon, SaveIconSolid, DeleteIcon, ShareIcon, StyledOneIcon, ReportIcon } from "../assets/icons";
import useFullCustomTheme from "../hooks/useFullCustomTheme";
import { ToastContext } from "../contexts/ToastContext";
import { basicDateFormat, checkLinkAgainstWhitelist, sortListByPosition } from "../tools/Helpers";
import { useCreateLike, useCreateSave, useRemoveLike, useRemoveSave, useReportContent } from "../api/data-access/generalHooks";
import { getCategory } from "../assets/categories";
import GeneralConfirmationModal from "../components/shared/modals/GeneralConfirmationModal";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useGetListById, useGetListByIdOpen } from "../api/data-access";
import { UseQueryResult } from "@tanstack/react-query";
import ContextMenu, { ContextMenuContent } from "../components/shared/modals/ContextMenu";
import LightboxModal from "../components/shared/modals/LightboxModal";
import useDisplayServerError from "../hooks/useDisplayServerError";
import useAuthentication from "../hooks/useAuthentication";
import ShareListModal from "../components/shared/modals/ShareListModal";
import { AuthContext } from "../contexts/AuthContext";
import ReportModal from "../components/shared/modals/ReportModal";

export type ActiveIndex = number | null;

const List = ({auth = true}: {auth?: boolean}) => {

  //reset scroll to top when load
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return auth ? <AuthListContent /> : <NoAuthListContent />;
}

const NoAuthListContent = () => {
  const {id} = useParams();
  const list = useGetListByIdOpen(id || '');
  
  return <ListContent list={list} />
}

const AuthListContent = () => {
  const {id} = useParams();
  const list = useGetListById(id || '');
  
  return <ListContent list={list} />
}

type ListContentProps = {
  list: UseQueryResult<DecoratedList, Error>;
}

const ListContent = ({list}: ListContentProps) => {
  
  const [searchParams] = useSearchParams();
  
  const {isAuthenticated} = useAuthentication();
  
  const [activeIndex, setActiveIndex] = useState<ActiveIndex>(null);
  
  useFullCustomTheme(list.data?.theme || searchParams.get('theme')?.toString());
  useDocumentTitle(list.data?.title || "List", list.isSuccess);
  
  const onList = useMemo(() => list.isSuccess && sortListByPosition<ListItemType | DecoratedListItem<ListItemType>>(list.data.onList, list.data.ascending), [list]);

  if (list.isPending || !onList) return null;
  else if (list.isError || !list.isSuccess) return <p>Error</p>;
  return (
    <PageWrapper padding={false}>
      <ListHeader list={list.data} preventLike={!isAuthenticated || list.data.isAuth} allowEdit={list.data.isAuth && !list.data.isAutoGenerated } />
      <div className="list-table graphic">
        {onList.map((l, i) =>
          <ListItem
            key={l._id}
            preventSave={!isAuthenticated || list.data.isAuth}
            listItem={l}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            listItemIndex={i}
            ordered={list.data.ordered}
            ascending={list.data.ascending}
            isAutoGenerated={list.data.isAutoGenerated}
          /> 
        )}
      </div>
    </PageWrapper>
  )
}

type ListHeaderProps = {
  list: DecoratedList;
  preventLike: boolean;
  allowEdit: boolean;
}

const ListHeader = ({list, preventLike, allowEdit}: ListHeaderProps) => {
  
  const navigate = useNavigate();
  const [listLiked, setListLiked] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  
  const createLike = useCreateLike();
  const removeLike = useRemoveLike();
  
  useEffect(() => {
    setListLiked(!!list.likedByAuthUser);
  }, [list]);
  
  const handleToggleLike = () => {
    if (listLiked) {
      removeLike.mutate(list._id);
      setListLiked(false);
    }
    else {
      createLike.mutate(list._id);
      setListLiked(true);
    }
  }
  
  return (
    <>
      {shareModalVisible &&
        <ShareListModal list={list} setModalVisible={setShareModalVisible} />
      }
      <section className="item-list padding">
        <div className="right-item-layout">
          <div className="item-row">
            <a onClick={() => navigate(-1)} className="icon small">
              <ArrowHeadLeftIcon />
            </a>
            <div className="tag" data-themed={true}>
              <p className="subtitle-medium header">{getCategory(list.category)}</p>
            </div>
          </div>
          {!list.isAuth &&
            <Link to={`/user/${list.author.username}`}>
              <article className="item-row center-vertical card-header">
                <div className="item-list">
                  <h3 className="subtitle-large">{list.author.displayName}</h3>
                </div>
                <div className="user-avatar small">
                  <img src={list.author.picture} />
                </div>
              </article>
            </Link>
          }
          {allowEdit &&
            <Link to={`/creator/${list._id}`}>
              <button className="primary short">Edit</button>
            </Link>
          }
        </div>
        <div className="item-list small">
          <div className="item-row">
            <p className="subtitle-medium secondary">List</p>
            <p className="subtitle-medium">{basicDateFormat(list.createdAt)}</p>
          </div>
          {list.parentCollection &&
            <div className="item-row">
              <p className="subtitle-medium secondary">Collection</p>
              <Link className="underline" to={`/collection/${list.parentCollection}`}> <p className="subtitle-medium">View</p></Link>
            </div>
          }
          <div className="item-row">
            <p className="subtitle-medium secondary">Views</p>
            <p className="subtitle-medium">{list.views || 0}</p>
          </div>
        </div>
        <div className="right-item-layout center-horizontal">
          <h1 className="heading-large">{list.title}</h1>
          <div className="item-row">
            <button className="icon" onClick={() => setShareModalVisible(true)}>
              <ShareIcon />
            </button>
            {!preventLike &&
              <button className="icon" onClick={handleToggleLike}>
                {listLiked ? <LikeIconSolid /> : <LikeIcon />}
              </button>
            }
          </div>
        </div>
      </section>
    </>
  )
}

type ListItemProps = {
  activeIndex: ActiveIndex;
  listItem: ListItemType | DecoratedListItem<ListItemType>;
  setActiveIndex: Dispatch<SetStateAction<ActiveIndex>>;
  listItemIndex: number;
  ordered?: boolean;
  ascending?: boolean;
  preventSave?: boolean;
  customControl?: React.ReactNode;
  isAutoGenerated?: boolean;
  noHero?: boolean;
};

export const ListItem = ({listItem, activeIndex, setActiveIndex, listItemIndex, ordered = true, ascending = true, preventSave, customControl, isAutoGenerated, noHero}: ListItemProps) => {
  
  const [imageModalVisible, setImageModalVisible] = useState(false);

  const handleCardClick = (e: React.MouseEvent<HTMLElement>) => {    
    setActiveIndex(prev => prev === listItemIndex ? null : listItemIndex)
  }
  
  return (
    <>
      {imageModalVisible &&
        <LightboxModal closeModal={() => setImageModalVisible(false)} imageUrl={listItem.image} title={listItem.title} />
      }
      <div 
        className={`table-item item-list large ${ascending ? 'bottom-border' : 'top-border' }`}
        data-hero={!noHero && ordered && listItem.position === 1}
        data-thumb={!!listItem.image}
        data-numbered={ordered}
        key={listItem._id}
        role={"button"}
        onClick={handleCardClick}
      >
        {ordered && (listItem.position === 1 && !noHero 
          ? <div className="list-position"><StyledOneIcon /></div>
          : <p className="heading-small weight-light center list-position">{listItem.position}</p>
        )}
        <div className="item-list position-relative">
          <div className="item-row large min-width">
            {listItem.image &&
              <button className="empty darkness-hover" onClick={(e) => {
                e.stopPropagation();
                setImageModalVisible(true);
              }}>
                <div className="thumb large">
                  <img crossOrigin="anonymous" src={listItem.image + "?cache_bypass=1"} />
                </div>
              </button>
            }
            <div className="right-item-layout full-width">
              <div className="item-list min-width">
                <p className="heading-small weight-light ellipsis-block">{listItem.title}</p>
                <p className="subtitle-medium ellipsis secondary">{listItem.subtitle}</p>
              </div>
              {customControl && customControl}
            </div>
          </div>
        </div>
        <div className="table-item-info-container" aria-current={activeIndex === listItemIndex}>
          <div>
            <ListItemControls
              item={listItem}
              preventSave={preventSave}
              customControl={!!customControl}
              isAutoGenerated={isAutoGenerated}
            />
          </div>
        </div>
      </div>
    </>
  )
}

type ListItemControlsProps = {
  item: ListItemType | DecoratedListItem<ListItemType>;
  preventSave?: boolean;
  customControl?: boolean;
  isAutoGenerated?: boolean;
}

const ListItemControls = ({item, preventSave, customControl, isAutoGenerated}: ListItemControlsProps) => {

  const {requestToast} = useContext(ToastContext);
  const {authUserId} = useContext(AuthContext);

  const navigate = useNavigate();
  const {handleError} = useDisplayServerError();
  
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [saved, setSaved] = useState<boolean>('savedByAuthUser' in item ? item.savedByAuthUser : false);
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);

  const saveItem = useCreateSave();
  const unsaveItem = useRemoveSave();

  const handleMore = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOptionsMenuVisible(true);
  }

  const handleReport = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setReportModalVisible(true);
  }
  
  const handleSave = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    
    if (saved) {
      unsaveItem.mutate(item._id, {
        onSuccess: () => {
          setSaved(false);
          requestToast('Removed from saved.', 'success');
        },
        onError: (err) => handleError(err)
      });
    }
    else {
      saveItem.mutate(item._id, {
        onSuccess: () => {
          setSaved(true);
          requestToast('Saved for later.', 'success');
        },
        onError: (err) => handleError(err)
      });
    }
    setSaved(prev => !prev);
  }
  
  const handleRemoveSavedItem = () => {
    if (!item.meta?.originalListItem) return;
    unsaveItem.mutate(item.meta?.originalListItem, {
      onSuccess: () => requestToast('Removed item.'),
      onError: () => requestToast('Failed to remove.', 'error')
    });
  }
  
  const itemOptionsData: ContextMenuContent = {
    title: "Options",
    focusedItem: false,
    menuContent: [
      {
        title: "Report",
        icon: ReportIcon,
        handleClick: handleReport,
        visible: !isAutoGenerated && item.author !== authUserId
      },
      {
        title: "Go to Original List",
        icon: FeedIcon,
        handleClick: () => navigate(`/list/${item.meta?.originalList}`),
        visible: isAutoGenerated && !!item.meta?.originalList
      },
      {
        title: "Remove Save",
        icon: DeleteIcon,
        alert: true,
        handleClick: handleRemoveSavedItem,
        visible: isAutoGenerated 
      }
    ]
  }

  const moreHasOptions = isAutoGenerated || (!isAutoGenerated && item.author !== authUserId);
  const showSave = !customControl && !preventSave && !isAutoGenerated;
  const showToolbar = showSave || moreHasOptions || item.link;
  
  if (!item.link && !item.authorNotes) return <></>;
  return (
    <>
      {reportModalVisible && <ReportModal setModalVisible={setReportModalVisible} contentId={item._id} contentType={'ListItem'} />}
      {optionsMenuVisible && <ContextMenu menuData={itemOptionsData} setModalVisible={setOptionsMenuVisible} stopPropogationOnClose />}
      {linkModalVisible &&
        <GeneralConfirmationModal setModalVisible={setLinkModalVisible} onConfirm={() => window.open(item.link, '_blank')}>
          <div className="item-list xl">
            <div className="item-list small">
              <p className="heading-small spaced">By clicking this link you'll be leaving Peaklist.</p>
              <p className="subtitle-large spaced secondary">We can't guarantee anything good will come out of this.</p>
            </div>
            <a href={item.link} rel="noopener" target="_blank" className="link">
              <p className="subtitle-large weight-bold spaced break-anywhere">{item.link}</p>
            </a>
          </div>
        </GeneralConfirmationModal>
      }
      <div className="item-list large table-controls min-width">
        {showToolbar && 
          <div className="tile self-start item-row large padding-top-small padding-bottom-small padding-left-large padding-right-large round">
            {item.link &&
              <button className="icon-text-vert small" onClick={() => checkLinkAgainstWhitelist(item.link, setLinkModalVisible)}>
                <LinkIcon />
                <span className="subtitle-small weight-light">Link</span>
              </button>
            }
            {showSave && 
              <button className="icon-text-vert" onClick={handleSave}>
                {saved ? <SaveIconSolid /> : <SaveIcon />}
                <span className="subtitle-small weight-light">Save{+ saved ? "d" : ''}</span>
              </button>
            }
            {moreHasOptions &&
              <button className="icon-text-vert small" onClick={handleMore}>
                <OptionsHorizontalIcon />
                <span className="subtitle-small weight-light">More</span>
              </button>
            }
          </div>
        }
        {item.authorNotes &&
          <div className="item-list small">
            <p className="subtitle-large weight-bold secondary">Notes</p>
            <p className="subtitle-large spaced">{item.authorNotes}</p>
          </div>
        }
      </div>
    </>
  )
}

export default List;