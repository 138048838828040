import { useState } from "react";
import { useGetUserMedia } from "../api/data-access";
import LoadingContainer from "../components/shared/LoadingContainer";
import LightboxModal from "../components/shared/modals/LightboxModal";
import { MediaTile } from "../components/shared/modals/MediaSelectorModal"
import PageWrapper from "./PageWrapper";
import { Media as MediaType } from "../types/SharedTypes";

const Media = () => {

  const {isSuccess, isLoading, data: userMedia} = useGetUserMedia(undefined);

  const [showLightbox, setShowLightbox] = useState<MediaType|false>(false);

  if (isLoading) return <LoadingContainer />;
  else if (!isSuccess) return <PageWrapper><p className="body">Error</p></PageWrapper>
  return (
    <>
      {showLightbox &&
        <LightboxModal
          closeModal={() => setShowLightbox(false)}
          imageUrl={showLightbox.url}
          title={`_id: ${showLightbox._id} | parents (${showLightbox.parents.length}): ${showLightbox.parents.join(', ')}`}
        />
      }
      {}
      <PageWrapper padding={false} classNames="padding-large">
        <div className="item-list large">
          <h1 className="heading-medium">Media</h1>
          <div className="item-grid-wrap small">
            {userMedia.map(media =>
              <MediaTile key={media._id} detail media={media} onClick={() => setShowLightbox(media)} />
            )}
          </div>
        </div>
      </PageWrapper>
    </>
  )
}
export default Media;