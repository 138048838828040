import themes, { ThemeType } from "../../assets/themes";

type Props = {
  theme?: never;
  themeSlug: string | undefined;
} | {
  theme: ThemeType;
  themeSlug?: never;
}

const ThemeIndicator = ({theme, themeSlug}: Props) => {
  
  const currentTheme = theme || themes.find(theme => theme.slug === themeSlug) || themes[0];
  
  return (
    <div className="theme-indicator" style={{backgroundColor: currentTheme.body, border: `2px solid ${currentTheme.line}`}}>
      <div className="theme-highlight" style={{backgroundColor: currentTheme.highlight}} />
      <div className="theme-dot" style={{background: `linear-gradient(90deg, ${currentTheme.highlightText} 49%, ${currentTheme.text} 50%)`}} />
    </div>
  )
}
export default ThemeIndicator;