import { baseAPIUrl } from "../constants/urls";
import authClient from "./authInterceptor";
import { PopulatedUserFollows, User } from "../types/SharedTypes";

const userUrl = `${baseAPIUrl}/follows`;

const followsApi = authClient;

export const getFollowsByUser = async (userId: string) => {
  const res = await followsApi.get<PopulatedUserFollows>(`${userUrl}/follows/${userId}`);
  return res.data;
}

export const followUser = async (userId: string) => {
  const res = await followsApi.post<User>(`${userUrl}/follow/${userId}`);
  return res.data;
}
export const unfollowUser = async (userId: string) => {
  const res = await followsApi.delete<User>(`${userUrl}/unfollow/${userId}`);
  return res.data;
}