import { ChangeEvent } from "react";

type Props = {
  id: string;
  label: string;
  handleChange: (checked: boolean) => void;
  initialValue?: boolean;
}

const ToggleSwitch = ({handleChange, id, label, initialValue}: Props) => {
  return (
    <div className="item-row">
      <label className="switch-input">
        <input id={id} type="checkbox" defaultChecked={!!initialValue} onChange={e => handleChange(e.target.checked)} />
        <span className="switch-knob"></span>
      </label>
      <label className="subtitle-large" htmlFor={id}>{label}</label>
    </div>
  )
}
export default ToggleSwitch;