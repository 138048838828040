import { useContext, useEffect, useState } from "react";
import { ToastContext } from "../contexts/ToastContext";

type Props = (file?: File) => void;

const usePreviewImage = (setNewImage: Props) => {
  
  const [imagePreview, setImagePreview] = useState<string>();
  const {requestToast} = useContext(ToastContext);
  
  const clearImage = () => {
    setNewImage(undefined);
    setImagePreview(undefined);
  }
  
  const handleImage = (file: File) => {
    
    if (file.size > (1024 * 1024 * 2)) {
      requestToast("Images must be under 2MBs", 'warn');
      return;
    }
    
    if (imagePreview) URL.revokeObjectURL(imagePreview);
    
    const preview = URL.createObjectURL(file);
    setImagePreview(preview);
    setNewImage(file);
  }
  
  useEffect(() => {
    return () => {
      imagePreview && URL.revokeObjectURL(imagePreview);
    }
  }, []);
  
  return {clearImage, handleImage, imagePreview}
}
export default usePreviewImage;