import PageWrapper from "../../pages/PageWrapper";
import LoadingIcon from "./LoadingIcon";

const LoadingContainer = () => {
  return (
    <PageWrapper>
      <div className="item-list center-horizontal center-vertical full-height">
        <LoadingIcon />
      </div>
    </PageWrapper>
  )
}
export default LoadingContainer;