import Modal from "../../Modal";

type Props = {
  setModalVisible: (prev: boolean) => void;
  children?: React.ReactNode;
  onConfirm: () => void;
  confirmText?: string;
  alert?: boolean;
  isLoading?: boolean;
  disableConfirm?: boolean;
}

const GeneralConfirmationModal = ({setModalVisible, children, onConfirm, confirmText, alert, isLoading, disableConfirm}: Props) => {
  return (
    <Modal
      fixedBottomItem
      bottomPane
      setModalVisible={setModalVisible}
      controls
      isLoading={isLoading}
      modalTitle={'Wait a sec'}
      children={children &&
        <div className="item-list">
          {children}
        </div>
      }
      fixedBottomItemChildren={
        <div className="item-list padding-small">
          <button aria-disabled={disableConfirm} disabled={disableConfirm} className="primary" data-alert={alert} onClick={onConfirm}>{confirmText || "Continue"}</button>
          <button className={alert ? "primary" : "secondary"} onClick={() => setModalVisible(false)}>Nevermind</button>
        </div>
      }
    />
  )
}
export default GeneralConfirmationModal;