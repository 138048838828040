import { useEffect } from "react";

const useDisablePullToRefresh = () => {
  useEffect(() => {
    document.documentElement.style.overscrollBehavior = 'none';
    return () => {
      document.documentElement.style.overscrollBehavior = 'auto';
    }
  }, []);
}
export default useDisablePullToRefresh;