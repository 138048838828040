export const HomeIcon = () => 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M99.23,40.76L54.32,1.76c-2.49-2.14-6.17-2.13-8.65,.03L.76,40.77c-1.51,1.32-.6,3.86,1.38,3.86h6.06c1.18,0,2.14,.96,2.14,3.22v40.4c0,5.92,5.67,11.58,12.66,11.58h10.62c1.35,0,2.44-1.09,2.44-2.44v-27.8c0-4.17,3.38-7.55,7.55-7.55h12.8c4.17,0,7.55,3.38,7.55,7.55v27.8c0,1.35,1.09,2.44,2.44,2.44h10.69c6.99,0,12.66-5.67,12.66-12.66V46.78c0-1.18,.96-2.14,2.14-2.14h5.99c1.99,0,2.9-2.56,1.37-3.88Z" fillRule="evenodd" />
  </svg>
  
export const SaveIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M4.28,24c-.57,0-1.14-.15-1.66-.46-1.02-.6-1.63-1.66-1.63-2.84V4.43C.99,1.99,2.98,0,5.42,0h13.66c2.44,0,4.43,1.99,4.43,4.43V20.7c0,1.18-.61,2.25-1.63,2.84-1.02,.6-2.25,.61-3.28,.03l-6.3-3.54s-.08-.02-.12,0l-6.3,3.54c-.51,.29-1.06,.43-1.61,.43ZM5.42,2.03c-1.32,0-2.4,1.08-2.4,2.4V20.7c0,.65,.44,.98,.62,1.09,.19,.11,.68,.33,1.25,.01l6.3-3.54c.65-.37,1.46-.37,2.11,0l6.3,3.54c.57,.32,1.07,.1,1.26-.01,.19-.11,.62-.44,.62-1.09V4.43c0-1.32-1.08-2.4-2.4-2.4H5.42Z"/>
  </svg>
  
export const SaveIconSolid = () => 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M19.08,0H5.42C2.98,0,.99,1.99,.99,4.43V20.7c0,1.18,.61,2.25,1.63,2.84,.52,.3,1.09,.46,1.66,.46s1.11-.14,1.61-.43l6.3-3.54s.08-.02,.12,0l6.3,3.54c1.03,.58,2.26,.57,3.28-.03,1.02-.6,1.63-1.66,1.63-2.84V4.43C23.51,1.99,21.53,0,19.08,0Z"/>
  </svg>
  
export const LikeIconSolid = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M17.8,1.34h-.25c-1.19,0-2.28,.38-3.32,1.21-.85,.68-1.54,1.51-2.05,2.47l-.17,.31-.17-.31c-.51-.96-1.21-1.79-2.06-2.47-.98-.78-2.21-1.21-3.47-1.21h-.02C2.7,1.34,0,4.27,0,8.16,0,10.15,.72,11.91,2.35,13.87c1.52,1.83,3.64,3.64,6.32,5.92,.8,.68,1.8,1.53,2.81,2.42l.52,.45,.51-.44c.99-.87,1.95-1.68,2.83-2.43,2.57-2.19,4.8-4.09,6.32-5.92,1.62-1.96,2.35-3.72,2.35-5.71,0-3.89-2.66-6.83-6.2-6.83Z"/>
  </svg>

export const LikeIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M17.8,1.34h-.25c-1.19,0-2.28,.38-3.32,1.21-.85,.68-1.54,1.51-2.05,2.47l-.17,.31-.17-.31c-.51-.96-1.21-1.79-2.06-2.47-.98-.78-2.21-1.21-3.47-1.21h-.02C2.7,1.34,0,4.27,0,8.16c0,1.99,.72,3.75,2.35,5.71,1.52,1.83,3.64,3.64,6.32,5.92,.8,.68,1.8,1.53,2.81,2.42l.52,.45,.51-.44c.99-.87,1.95-1.68,2.83-2.43,2.57-2.19,4.8-4.09,6.32-5.92,1.62-1.96,2.35-3.72,2.35-5.71,0-3.89-2.66-6.83-6.2-6.83Zm3.97,6.83c0,1.45-.55,2.73-1.84,4.29-1.38,1.67-3.52,3.49-6,5.6l-.74,.63c-.39,.33-.79,.68-1.2,1.03-.69-.59-1.33-1.14-1.89-1.62l-.15-.13c-2.54-2.16-4.54-3.87-5.9-5.52-1.29-1.55-1.84-2.84-1.84-4.29,0-2.67,1.71-4.6,4.06-4.6h.02c.76,0,1.51,.26,2.1,.74,.61,.49,1.1,1.09,1.47,1.79l2.12,3.88,2.13-3.88c.37-.69,.86-1.29,1.47-1.78,.6-.48,1.35-.73,2.12-.74h0c2.35,0,4.06,1.93,4.06,4.6Z"/>
  </svg>

export const CollectionIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <rect x="0" width="17" height="24" rx="4" ry="4"/>
    <path d="M19,2.36c0-.75,.61-1.36,1.36-1.36,2.01,0,3.64,1.63,3.64,3.64v14.73c0,2.01-1.63,3.64-3.64,3.64-.75,0-1.36-.61-1.36-1.36V2.36Z"/>
  </svg>
    
export const ArrowIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <polygon points="99.23 46.35 14.76 46.35 45.45 15.66 40.28 10.49 .77 50 40.28 89.51 45.45 84.34 14.76 53.65 99.23 53.65 99.23 46.35"/>
  </svg>
  
export const ArrowRightIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <polygon points="0 12.89 20.59 12.89 13.11 20.37 14.37 21.63 24 12 14.37 2.37 13.11 3.63 20.59 11.11 0 11.11 0 12.89"/>
  </svg>

export const ArrowHeadDownIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M.18,7.09l1.53-1.39c.12-.1,.27-.16,.44-.16s.32,.06,.44,.16L12,14.31,21.41,5.7c.12-.1,.27-.16,.44-.16s.32,.06,.44,.16l1.53,1.39c.06,.05,.1,.11,.13,.18,.03,.07,.05,.14,.05,.22s-.02,.15-.05,.22c-.03,.07-.08,.13-.13,.18l-11.39,10.41c-.12,.1-.27,.16-.44,.16s-.32-.06-.44-.16L.18,7.89c-.06-.05-.1-.11-.13-.18-.03-.07-.05-.14-.05-.22s.02-.15,.05-.22,.08-.13,.13-.18Z"/>
  </svg>

export const ArrowHeadLeftIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M16.91,.18l1.39,1.53c.1,.12,.16,.27,.16,.44s-.06,.32-.16,.44L9.69,12l8.61,9.42c.1,.12,.16,.27,.16,.44s-.06,.32-.16,.44l-1.39,1.53c-.05,.06-.11,.1-.18,.13-.07,.03-.14,.05-.22,.05s-.15-.02-.22-.05c-.07-.03-.13-.08-.18-.13L5.7,12.43c-.1-.12-.16-.27-.16-.44s.06-.32,.16-.44L16.11,.18c.05-.06,.11-.1,.18-.13,.07-.03,.14-.05,.22-.05s.15,.02,.22,.05,.13,.08,.18,.13Z"/>
  </svg>

export const ArrowHeadRightIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M7.09,23.82l-1.39-1.53c-.1-.12-.16-.27-.16-.44s.06-.32,.16-.44L14.31,12,5.7,2.59c-.1-.12-.16-.27-.16-.44s.06-.32,.16-.44L7.09,.18c.05-.06,.11-.1,.18-.13C7.35,.02,7.42,0,7.49,0s.15,.02,.22,.05c.07,.03,.13,.08,.18,.13l10.41,11.39c.1,.12,.16,.27,.16,.44s-.06,.32-.16,.44L7.89,23.82c-.05,.06-.11,.1-.18,.13-.07,.03-.14,.05-.22,.05s-.15-.02-.22-.05-.13-.08-.18-.13Z"/>
  </svg>

export const ArrowHeadUpIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M23.82,16.91l-1.53,1.39c-.12,.1-.27,.16-.44,.16s-.32-.06-.44-.16L12,9.69,2.59,18.3c-.12,.1-.27,.16-.44,.16s-.32-.06-.44-.16l-1.53-1.39c-.06-.05-.1-.11-.13-.18C.02,16.65,0,16.58,0,16.51s.02-.15,.05-.22c.03-.07,.08-.13,.13-.18L11.57,5.7c.12-.1,.27-.16,.44-.16s.32,.06,.44,.16l11.38,10.41c.06,.05,.1,.11,.13,.18,.03,.07,.05,.14,.05,.22s-.02,.15-.05,.22-.08,.13-.13,.18Z"/>
  </svg>

export const OptionsVerticalIcon = () => 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M50,62.5c6.9,0,12.5-5.6,12.5-12.5s-5.6-12.5-12.5-12.5-12.5,5.6-12.5,12.5,5.6,12.5,12.5,12.5Z"/>
    <path d="M50,25c6.9,0,12.5-5.6,12.5-12.5S56.9,0,50,0s-12.5,5.6-12.5,12.5,5.6,12.5,12.5,12.5Z"/>
    <path d="M50,100c6.9,0,12.5-5.6,12.5-12.5s-5.6-12.5-12.5-12.5-12.5,5.6-12.5,12.5,5.6,12.5,12.5,12.5Z"/>
  </svg>
  
export const OptionsHorizontalIcon = () => 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M37.5,50A12.5,12.5,0,1,0,50,37.5,12.5,12.5,0,0,0,37.5,50Z" />
    <path d="M75,50A12.5,12.5,0,1,0,87.5,37.5,12.5,12.5,0,0,0,75,50Z" />
    <path d="M0,50A12.5,12.5,0,1,0,12.5,37.5,12.5,12.5,0,0,0,0,50Z" />
  </svg>
  
export const PeakLogo = ({crossorigin}: {crossorigin?: boolean}) => 
  <svg crossOrigin={crossorigin ? 'anonymous' : undefined} viewBox="0 0 107 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.98134 16.1924C4.87715 17.1521 6.54434 17.9396 8.73407 17.9396C13.2131 17.9396 15.8258 14.8881 15.8258 11.1969C15.8258 7.48099 13.2131 4.42953 8.73407 4.42953C6.54434 4.42953 4.87715 5.24161 3.98134 6.17673V4.70023H0V22H3.98134V16.1924ZM3.85693 11.123C3.85693 9.08054 5.62365 7.60403 7.91292 7.60403C10.1027 7.60403 11.944 8.88367 11.944 11.1969C11.944 13.5101 10.1027 14.7651 7.91292 14.7651C5.62365 14.7651 3.85693 13.3132 3.85693 11.2707V11.123Z" />
    <path d="M24.8819 14.8143C22.8414 14.8143 21.3235 13.8546 20.8756 12.2058H32.322V11.123C32.322 7.43177 29.5848 4.42953 24.8819 4.42953C20.2535 4.42953 16.9938 7.33333 16.9938 11.1723C16.9938 15.2081 20.2535 17.9396 24.9067 17.9396C28.3406 17.9396 31.1525 16.3154 32.098 14.0268L28.5895 12.7718C28.0172 14.0268 26.6237 14.8143 24.8819 14.8143ZM24.7574 7.48098C26.8476 7.48098 28.1416 8.71141 28.3158 9.79418H20.9752C21.5226 8.26846 23.0156 7.48098 24.7574 7.48098Z" />
    <path d="M38.8714 17.9396C41.1855 17.9396 43.0767 17.2752 44.2711 16.2416C44.9429 17.472 46.5355 18.2103 49.4468 17.6689V14.7651C48.0534 15.0604 47.7548 14.7405 47.7548 14.0515V9.32662C47.7548 6.0783 45.2913 4.42953 41.1109 4.42953C37.5028 4.42953 34.6163 5.97987 33.7952 8.31767L37.3784 9.2774C37.7765 8.26846 39.1451 7.5302 40.9367 7.5302C43.0518 7.5302 43.8978 8.31767 43.8978 9.30201V9.37584L38.6723 9.8434C35.5868 10.1387 33.3224 11.3937 33.3224 13.9284C33.3224 16.4877 35.6365 17.9396 38.8714 17.9396ZM43.8978 12.698C43.8978 14.1991 41.6334 15.0604 39.593 15.0604C38.0502 15.0604 37.1793 14.6175 37.1793 13.7808C37.1793 12.9195 37.876 12.5996 39.2446 12.4765L43.8978 12.009V12.698Z" />
    <path d="M54.7931 0H50.8117V17.6689H54.7931V14.5436L57.1819 12.5503L61.86 17.6689H66.7869L60.0683 10.1633L66.6127 4.70023H61.2379L54.7931 10.2125V0Z" />
    <path d="M71.6331 0H67.6518V17.6689H71.6331V0Z" />
    <path d="M74.0664 3.37137H78.1224V0H74.0664V3.37137ZM78.0726 4.70023H74.0913V17.6689H78.0726V4.70023Z" />
    <path d="M79.212 15.528C80.7299 17.0537 83.6413 17.9396 86.6771 17.9396C90.7828 17.9396 93.8684 16.4631 93.8684 13.4362C93.8684 10.4832 90.9321 9.91723 87.5729 9.52349C84.7362 9.20358 83.8652 9.12976 83.8652 8.41611C83.8652 7.75168 84.761 7.33333 86.4531 7.33333C88.4936 7.33333 90.2603 7.94855 91.4547 9.03132L93.52 6.6689C92.2012 5.41387 89.7377 4.42953 86.6522 4.42953C82.4966 4.42953 79.9337 6.20134 79.9337 8.85906C79.9337 11.6398 82.5464 12.255 85.6817 12.6488C88.618 12.9933 89.8621 12.9933 89.8621 13.8546C89.8621 14.6667 88.7673 15.0112 86.9757 15.0112C84.7362 15.0112 82.6708 14.3221 81.1032 12.8949L79.212 15.528Z" />
    <path d="M97.0715 13.4608C97.0715 16.6353 99.4354 17.8658 102.397 17.8658C104.238 17.8658 105.88 17.4966 107 17.0783L106.278 13.953C105.407 14.2483 104.462 14.4944 103.442 14.4944C101.774 14.4944 100.978 13.9776 100.978 12.5749V7.94855H106.428V4.70023H100.978V1.10738H97.0715V4.70023H94.0855V7.94855H97.0715V13.4608Z" />
  </svg>

export const PeakLogoSmall = () => 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.32 19.56">
    <path d="M0,19.56V.39H10.05v1.65h1.46c.92-.53,2.35-1,4.3-1.41C17.75,.21,20.53,0,24.15,0,27.4,0,30.4,.28,33.15,.83c2.76,.53,4.97,1.32,6.65,2.37s2.51,2.31,2.51,3.8v.44c0,1.49-.84,2.76-2.51,3.8s-3.89,1.85-6.65,2.4c-2.76,.53-5.75,.8-9,.8-2.43,0-4.49-.1-6.16-.3-1.62-.18-2.95-.42-3.97-.72-.97-.31-1.76-.62-2.35-.94h-1.46v7.08H0ZM21.07,11.41c3.19,0,5.81-.34,7.86-1.02,2.11-.7,3.16-1.71,3.16-3.03v-.28c0-1.32-1.05-2.32-3.16-3-2.11-.7-4.73-1.05-7.86-1.05s-5.75,.35-7.86,1.05c-2.11,.68-3.16,1.68-3.16,3v.28c0,1.32,1.05,2.33,3.16,3.03,2.11,.68,4.73,1.02,7.86,1.02Z"/>
    <path d="M47.32,19.56V.45h10.01V19.56h-10.01Z"/>
  </svg>

export const TrendingIcon = () => 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M23.12,9.83c-.67-.7-.99-1.67-.85-2.63,.23-1.66-.89-3.2-2.54-3.49-.96-.15-1.78-.75-2.23-1.61C16.71,.61,14.89,.02,13.38,.75c-.86,.44-1.89,.44-2.75,0-1.51-.73-3.33-.13-4.12,1.35-.45,.85-1.28,1.45-2.23,1.61-1.65,.29-2.77,1.84-2.54,3.49,.13,.96-.18,1.93-.85,2.63-1.17,1.21-1.17,3.13,0,4.33,.67,.69,.99,1.66,.85,2.62-.23,1.66,.89,3.21,2.54,3.5,.96,.15,1.78,.75,2.23,1.61,.78,1.5,2.61,2.1,4.12,1.35,.87-.42,1.88-.42,2.75,0,1.51,.73,3.33,.13,4.12-1.35,.45-.85,1.28-1.45,2.23-1.61,1.65-.29,2.77-1.84,2.54-3.5-.13-.96,.18-1.92,.85-2.62,1.17-1.21,1.17-3.13,0-4.33Zm-7.5,8.63h-6.63v-2.36h2.16v-7.44c-.11,.58-.3,1.03-.57,1.35-.26,.31-.55,.53-.87,.67-.31,.12-.59,.19-.85,.21-.24,.02-.41,.02-.48,.02v-3.05c.09,.03,.29,.04,.6,.02,.32-.02,.66-.08,1.01-.21,.37-.14,.69-.37,.96-.71,.29-.34,.45-.81,.48-1.42h2.48v10.56h1.72v2.36Z"/>
  </svg>

export const FeedIcon = () => 
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M20 2H4C2.89543 2 2 2.89543 2 4V7C2 8.10457 2.89543 9 4 9H20C21.1046 9 22 8.10457 22 7V4C22 2.89543 21.1046 2 20 2ZM4 0C1.79086 0 0 1.79086 0 4V7C0 9.20914 1.79086 11 4 11H20C22.2091 11 24 9.20914 24 7V4C24 1.79086 22.2091 0 20 0H4Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20 15H4C2.89543 15 2 15.8954 2 17V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V17C22 15.8954 21.1046 15 20 15ZM4 13C1.79086 13 0 14.7909 0 17V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V17C24 14.7909 22.2091 13 20 13H4Z"/>
  </svg>

export const RecommendIcon = () =>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2ZM7 0C3.13401 0 0 3.13401 0 7V17C0 20.866 3.13401 24 7 24H17C20.866 24 24 20.866 24 17V7C24 3.13401 20.866 0 17 0H7Z"/>
    <path d="M11.5245 5.46353C11.6742 5.00287 12.3259 5.00287 12.4755 5.46353L13.6839 9.18237C13.7508 9.38839 13.9428 9.52787 14.1594 9.52787H18.0696C18.554 9.52787 18.7554 10.1477 18.3635 10.4324L15.2001 12.7307C15.0248 12.8581 14.9515 13.0838 15.0184 13.2898L16.2268 17.0086C16.3764 17.4693 15.8492 17.8523 15.4573 17.5676L12.2939 15.2693C12.1187 15.1419 11.8814 15.1419 11.7061 15.2693L8.54268 17.5676C8.15082 17.8523 7.62358 17.4693 7.77326 17.0086L8.98159 13.2898C9.04852 13.0838 8.97519 12.8581 8.79995 12.7307L5.63651 10.4324C5.24465 10.1477 5.44604 9.52787 5.9304 9.52787H9.84063C10.0572 9.52787 10.2492 9.38839 10.3162 9.18237L11.5245 5.46353Z"/>
  </svg>

export const ImportIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M43.1,2.5V48.71L31.27,36.89a2.49,2.49,0,0,0-3.53,0l-6.22,6.22a2.49,2.49,0,0,0,0,3.53L48.23,73.36a2.5,2.5,0,0,0,3.54,0L78.48,46.64a2.49,2.49,0,0,0,0-3.53l-6.22-6.22a2.49,2.49,0,0,0-3.53,0L56.9,48.71V2.5A2.5,2.5,0,0,0,54.4,0H45.6A2.5,2.5,0,0,0,43.1,2.5Z"/>
    <path d="M84.75,100H15.25C6.84,100,0,92.91,0,84.2V72.05a3.6,3.6,0,0,1,3.59-3.61h0a3.61,3.61,0,0,1,3.6,3.61V84.2a8.35,8.35,0,0,0,8.06,8.58h69.5a8.35,8.35,0,0,0,8.06-8.58V72.05a3.61,3.61,0,0,1,3.6-3.61h0A3.6,3.6,0,0,1,100,72.05V84.2C100,92.91,93.16,100,84.75,100Z"/>
  </svg>

export const UploadImageIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M20.53,0H3.47C1.56,0,0,1.56,0,3.47V20.53c0,1.91,1.55,3.46,3.46,3.46H20.53c1.91,0,3.46-1.55,3.46-3.46V3.47C23.99,1.56,22.44,0,20.53,0Zm-3.43,22.03c-2.69,0-4.88-2.18-4.88-4.88s2.18-4.88,4.88-4.88,4.88,2.18,4.88,4.88-2.18,4.88-4.88,4.88Zm4.88-10.32l-4.51-4.51c-.2-.2-.53-.2-.74,0l-7.48,7.48c-.3,.3-.79,.3-1.09,0l-2.74-2.74c-.2-.2-.53-.2-.74,0l-2.65,2.65V3.47c0-.8,.65-1.45,1.45-1.45H20.53c.8,0,1.45,.65,1.45,1.45V11.71Z"/>
    <path d="M19.79,16.25l-2.52-2.52c-.09-.09-.24-.09-.33,0,0,0,0,0,0,0l-2.52,2.52c-.09,.09-.09,.24,0,.33,0,0,0,0,0,0l.59,.59c.09,.09,.24,.09,.33,0,0,0,0,0,0,0l1.12-1.12v4.37c0,.13,.11,.24,.24,.24h.83c.13,0,.24-.11,.24-.24v-4.37l1.12,1.12c.09,.09,.24,.09,.33,0h0s.59-.59,.59-.59c.09-.09,.09-.24,0-.33h0Z"/>
  </svg>
  
export const SettingsIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M98.42,62.72c-3.12-2.38-6.23-4.78-9.37-7.13a1.58,1.58,0,0,1-.78-1.66,27,27,0,0,0,0-7.82A1.49,1.49,0,0,1,89,44.55c3.23-2.43,6.44-4.9,9.64-7.36,1.53-1.18,1.75-2.23.78-3.86Q94.52,25,89.6,16.74a2.74,2.74,0,0,0-2.13-1.63,3.78,3.78,0,0,0-1.81.34c-3.8,1.49-7.61,3-11.41,4.51a1.48,1.48,0,0,1-1.16.06,1.86,1.86,0,0,1-.23-.13,34.93,34.93,0,0,0-7.42-4.24,1.58,1.58,0,0,1-.32-.17,2.57,2.57,0,0,1-.3-.36s0-.1-.07-.16a1.57,1.57,0,0,1-.07-.34c-.54-4-1.12-7.95-1.7-11.92C62.71.85,61.82,0,60,0c0,0-15.72,0-20,0-1.83,0-2.72.84-3,2.69-.58,4-1.16,7.94-1.7,11.92a1.11,1.11,0,0,1-.76,1,34.93,34.93,0,0,0-7.42,4.24,1.22,1.22,0,0,1-1.4.07l-11.42-4.5c-1.85-.73-2.93-.38-3.92,1.28Q5.49,25,.59,33.33c-1,1.63-.75,2.68.78,3.86,3.2,2.46,6.41,4.93,9.64,7.36a1.49,1.49,0,0,1,.72,1.56,27,27,0,0,0,0,7.82A1.58,1.58,0,0,1,11,55.59c-3.14,2.35-6.25,4.75-9.37,7.13C-.24,64.11-.44,65.05.73,67q4.79,8.1,9.58,16.18c1.08,1.82,2.09,2.16,4.08,1.38q5.66-2.22,11.3-4.46a1.46,1.46,0,0,1,1.61.17,32.92,32.92,0,0,0,7,4,1.48,1.48,0,0,1,1,1.35c.5,3.94,1.1,7.87,1.68,11.81A2.66,2.66,0,0,0,39.13,100H60.87A2.66,2.66,0,0,0,63,97.46c.58-3.94,1.18-7.87,1.68-11.81a1.48,1.48,0,0,1,1-1.35,32.92,32.92,0,0,0,7-4A1.39,1.39,0,0,1,73.76,80a2.62,2.62,0,0,1,.48.15l11.32,4.45a4.86,4.86,0,0,0,1.37.37c1.17.15,1.94-.38,2.76-1.75Q94.48,75.13,99.27,67C100.44,65.05,100.24,64.11,98.42,62.72ZM50,68.25A18.25,18.25,0,1,1,68.25,50,18.25,18.25,0,0,1,50,68.25Z" fillRule="evenodd"/>
  </svg>
  
export const CheckIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M8.35,20.12L.45,12.22c-.23-.23-.23-.61,0-.84l1.44-1.44c.23-.23,.61-.23,.84,0l6.2,6.2L21.26,3.81c.23-.23,.61-.23,.84,0l1.44,1.44c.23,.23,.23,.61,0,.84l-14.02,14.02c-.33,.33-.86,.33-1.18,0Z"/>
  </svg>

export const MinusIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <rect x=".99" y="10.38" width="22.03" height="3.23" rx=".58" ry=".58"/>
  </svg>

export const SearchIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M99,94,72.27,67.32a40.73,40.73,0,1,0-5,5L94,99A3.48,3.48,0,0,0,99,99h0A3.48,3.48,0,0,0,99,94ZM41.09,74.83A33.79,33.79,0,1,1,65,64.93,33.65,33.65,0,0,1,41.09,74.83Z"/>
  </svg>
  
export const AscendingIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M6.4,20.25V7.81l2.1,2.09s.09,.07,.14,.1c.05,.02,.11,.03,.17,.03s.12-.01,.17-.03c.05-.02,.1-.06,.14-.1l1.1-1.1s.07-.09,.1-.14c.02-.05,.03-.11,.03-.17s-.01-.12-.03-.17c-.02-.05-.06-.1-.1-.14L5.49,3.44s-.09-.07-.14-.1c-.05-.02-.11-.03-.17-.03s-.12,.01-.17,.03c-.05,.02-.1,.06-.14,.1L.13,8.17s-.07,.09-.1,.14C.01,8.37,0,8.43,0,8.49s.01,.12,.03,.17c.02,.05,.05,.1,.1,.14l1.1,1.1s.09,.07,.14,.1c.05,.02,.11,.03,.17,.03s.12-.01,.17-.03c.05-.02,.1-.06,.14-.1l2.1-2.09v12.44c0,.12,.05,.23,.13,.31,.08,.08,.2,.13,.31,.13h1.56c.12,0,.23-.05,.31-.13,.08-.08,.13-.2,.13-.31Z"/>
    <path d="M9.86,18.48h13.88c.14,0,.26,.12,.26,.26v1.7c0,.14-.12,.26-.26,.26H9.86c-.14,0-.26-.12-.26-.26v-1.7c0-.14,.12-.26,.26-.26Z"/>
    <path d="M12.08,11.83h11.66c.14,0,.26,.12,.26,.26v1.7c0,.14-.12,.26-.26,.26H12.08c-.14,0-.26-.12-.26-.26v-1.7c0-.14,.12-.26,.26-.26Z"/>
    <path d="M14.29,5.19h9.45c.14,0,.26,.12,.26,.26v1.7c0,.14-.12,.26-.26,.26H14.29c-.14,0-.26-.12-.26-.26v-1.7c0-.14,.12-.26,.26-.26Z"/>
  </svg>
  
export const CollapseIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M11.1,6.23V.28c0-.16,.13-.28,.28-.28h1.23c.16,0,.28,.13,.28,.28V6.23l1.26-1.26c.11-.11,.29-.11,.4,0l.87,.87c.11,.11,.11,.29,0,.4l-3.26,3.26c-.09,.09-.25,.09-.34,0l-3.26-3.26c-.11-.11-.11-.29,0-.4l.87-.87c.11-.11,.29-.11,.4,0l1.26,1.26Z" fill-rule="evenodd"/>
    <path d="M12.9,17.77v5.94c0,.16-.13,.28-.28,.28h-1.23c-.16,0-.28-.13-.28-.28v-5.94l-1.26,1.26c-.11,.11-.29,.11-.4,0l-.87-.87c-.11-.11-.11-.29,0-.4l3.26-3.26c.09-.09,.25-.09,.34,0l3.26,3.26c.11,.11,.11,.29,0,.4l-.87,.87c-.11,.11-.29,.11-.4,0l-1.26-1.26Z" fill-rule="evenodd"/>
    <path d="M23.72,12.9H.28c-.16,0-.28-.13-.28-.28v-1.23c0-.16,.13-.28,.28-.28H23.72c.16,0,.28,.13,.28,.28v1.23c0,.16-.13,.28-.28,.28Z" fill-rule="evenodd"/>
  </svg>
  
export const FlaggedIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M20,2h-4.82c-.35-.31-.81-.5-1.32-.5h-3.71c-.51,0-.97,.19-1.32,.5H4c-1.1,0-2,.9-2,2v3c0,1.1,.9,2,2,2h4.27c.14,0,.26,.11,.27,.25l.1,1.46c.01,.16-.11,.29-.27,.29H4C1.79,11,0,9.21,0,7v-3C0,1.79,1.79,0,4,0h16c2.21,0,4,1.79,4,4v3c0,2.21-1.79,4-4,4h-4.38c-.16,0-.28-.13-.27-.29l.1-1.46c0-.14,.13-.25,.27-.25h4.27c1.1,0,2-.9,2-2v-3c0-1.1-.9-2-2-2Z" fillRule="evenodd"/>
    <path d="M4,13h4.56c.14,0,.26,.11,.27,.25l.1,1.46c.01,.16-.11,.29-.27,.29H4c-1.1,0-2,.9-2,2v3c0,1.1,.9,2,2,2h6.06c.57,.32,1.23,.5,1.94,.5s1.36-.18,1.94-.5h6.06c1.1,0,2-.9,2-2v-3c0-1.1-.9-2-2-2h-4.66c-.16,0-.28-.13-.27-.29l.1-1.46c0-.14,.13-.25,.27-.25h4.56c2.21,0,4,1.79,4,4v3c0,2.21-1.79,4-4,4H4C1.79,24,0,22.21,0,20v-3C0,14.79,1.79,13,4,13Z" fillRule="evenodd"/>
    <path d="M10.29,3.5h3.42c.16,0,.28,.13,.27,.29l-.63,10.45c0,.15-.13,.26-.28,.26h-2.15c-.15,0-.27-.11-.28-.26l-.63-10.45c0-.16,.12-.29,.27-.29Z" fillRule="evenodd"/>
    <circle cx="12" cy="18.5" r="2"/>
  </svg>
  
export const GrabIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <circle cx="4" cy="4" r="4"/>
    <circle cx="4" cy="20" r="4"/>
    <circle cx="20" cy="4" r="4"/>
    <circle cx="20" cy="20" r="4"/>
  </svg>
  
export const LinkIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M2.67,17c0,1.33,.5,2.17,1.33,3,.83,.83,1.83,1.17,3,1.33,1.17,.17,2.33-.33,3.17-1.17l4-4,1.83,1.83-4,4c-1.33,1.33-3,2-5,2s-3.67-.67-5-2C.67,20.67,0,19,0,17c0-2,.67-3.67,2-5l4-4,2,2L4,14c-1,.67-1.5,1.83-1.33,3Zm6.33,0l-2-2L15,7l2,2-8,8ZM12,2C13.33,.67,15,0,17,0c2,0,3.67,.67,5,2,1.33,1.33,2,3,2,5s-.67,3.67-2,5l-4,4-2-2,4-4c.83-.83,1.17-1.83,1.33-3,0-1.33-.5-2.17-1.33-3-.83-.83-1.83-1.17-3-1.33-1.33,0-2.17,.5-3,1.33l-4,4-2-2L12,2Z"/>
  </svg>

export const AddIcon = () =>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 0H10.5V10.5H0V13.5H10.5V24H13.5V13.5H24V10.5H13.5V0Z" />
  </svg>
  
export const DeleteIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M21.98,4.54c-.16-1.2-1.19-2.11-2.41-2.11h-2.58s-.47-1.31-.73-1.7C15.89,.18,15.15,0,14.49,0h-4.97c-.66,0-1.41,.18-1.78,.73-.26,.38-.73,1.7-.73,1.7h-2.58c-1.21,0-2.25,.91-2.41,2.11-.15,1.13,.52,2.21,1.57,2.59v13.59c0,.88,.33,1.73,.9,2.33,.57,.61,1.38,.95,2.21,.96h10.6c.83,0,1.64-.35,2.21-.95,.57-.6,.9-1.45,.9-2.33V7.13c1.05-.39,1.72-1.46,1.57-2.59ZM8.94,1.92c.12-.17,.36-.23,.58-.23,0,0,4.99,0,4.99,0,.21,0,.44,.06,.56,.23,.08,.11,.23,.5,.23,.5h-6.6s.15-.39,.23-.5Zm9.78,18.79c0,.89-.62,1.59-1.42,1.59H6.7c-.79,0-1.42-.7-1.42-1.59V7.25h13.43v13.46Zm.86-15.16H4.43c-.4,0-.72-.32-.72-.72s.32-.72,.72-.72h15.15c.4,0,.72,.32,.72,.72s-.32,.72-.72,.72Z"/>
    <path d="M8.74,18.16c.34,.34,.89,.34,1.23,0l2.15-2.15,1.9,1.9c.34,.34,.89,.34,1.23,0s.34-.89,0-1.23l-1.9-1.9,1.9-1.9c.34-.34,.34-.89,0-1.23s-.89-.34-1.23,0l-1.9,1.9-2.15-2.15c-.34-.34-.89-.34-1.23,0-.34,.34-.34,.89,0,1.23l2.15,2.15-2.15,2.15c-.34,.34-.34,.89,0,1.23Z"/>
  </svg>
  
export const EditIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M0,19.43v3.75C0,23.63,.37,24,.82,24h3.75c.28,0,.54-.11,.74-.31l14.27-14.27c.1-.1,.1-.26,0-.36l-4.64-4.64c-.1-.1-.26-.1-.36,0L.31,18.69C.11,18.89,0,19.15,0,19.43Z"/>
    <path d="M23.61,3.5L20.5,.39c-.52-.52-1.37-.52-1.89,0l-2.26,2.26c-.1,.1-.1,.26,0,.36l4.64,4.64c.1,.1,.26,.1,.36,0l2.26-2.26c.52-.52,.52-1.37,0-1.89Z"/>
  </svg>
    
export const VisibleIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M22.8,9.59c-.74-.82-1.77-1.66-3-2.41-2.59-1.6-5.58-2.6-7.8-2.6s-5.21,1-7.8,2.6c-1.23,.76-2.26,1.59-3,2.41-.78,.88-1.2,1.71-1.2,2.41s.41,1.54,1.2,2.41c.74,.82,1.77,1.65,3,2.41,2.59,1.6,5.58,2.6,7.8,2.6s5.21-1,7.8-2.6c1.23-.76,2.26-1.59,3-2.41,.78-.88,1.2-1.71,1.2-2.41s-.42-1.54-1.2-2.41Zm-1.7,3.3c-.58,.65-1.47,1.36-2.5,2-2.22,1.37-4.8,2.26-6.6,2.26s-4.38-.89-6.6-2.26c-1.03-.64-1.92-1.35-2.5-2-.49-.54-.59-.85-.61-.86,.02-.07,.13-.38,.61-.92,.58-.65,1.47-1.36,2.5-2,2.22-1.37,4.81-2.26,6.6-2.26s4.38,.89,6.6,2.26c1.03,.64,1.92,1.35,2.5,2,.43,.48,.57,.78,.6,.89-.04,.11-.17,.41-.6,.89Z"/>
    <path d="M12,9.14c-1.58,0-2.86,1.28-2.86,2.86s1.28,2.86,2.86,2.86,2.86-1.28,2.86-2.86-1.28-2.86-2.86-2.86Z"/>
  </svg>
  
export const HiddenIcon = () => 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M2.05,.35C1.58-.12,.82-.12,.35,.35-.12,.82-.12,1.58,.35,2.05L21.95,23.65c.47,.47,1.23,.47,1.7,0,.47-.47,.47-1.23,0-1.7l-4.82-4.82c.89-.54,1.74-1.2,2.51-1.97l2.31-2.31c.47-.47,.47-1.23,0-1.7l-2.31-2.31c-3.75-3.75-9.19-4.77-13.87-3.07L2.05,.35Zm7.34,7.34l1.06,1.06c.47-.22,1-.35,1.55-.35,1.99,0,3.6,1.61,3.6,3.6,0,.56-.13,1.08-.35,1.55l1.82,1.82c.92-.49,1.79-1.13,2.57-1.9l1.47-1.47-1.47-1.47c-2.78-2.78-6.69-3.73-10.25-2.84h0Z" fillRule="evenodd"/>
    <path d="M3.76,7.86c-.38,.3-.74,.63-1.09,.98L.35,11.15c-.47,.47-.47,1.23,0,1.7l2.31,2.31c3.26,3.26,7.8,4.46,12,3.6l-2.14-2.14c-2.93,.14-5.92-.91-8.16-3.15l-1.47-1.47,1.47-1.47c.35-.35,.72-.67,1.11-.97l-1.71-1.71Z"/>
  </svg>

export const PublishIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M18.52,0H5.48C2.93,0,.86,2.07,.86,4.62v14.76c0,2.55,2.07,4.62,4.62,4.62h13.04c2.55,0,4.62-2.07,4.62-4.62V4.62c0-2.55-2.07-4.62-4.62-4.62Zm.11,8.71l-7.96,7.96c-.11,.11-.25,.17-.4,.17s-.29-.06-.4-.17l-4.49-4.49c-.17-.17-.17-.44,0-.61l.82-.82c.17-.17,.44-.17,.61,0l3.46,3.46,6.94-6.94c.17-.17,.44-.17,.61,0l.82,.82c.17,.17,.17,.44,0,.61Z"/>
  </svg>

export const PublishPersonalIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12,6.23c-1.41,0-2.59,1.15-2.89,2.68h5.78c-.3-1.53-1.48-2.68-2.89-2.68Z"/>
    <path d="M13.71,12.7c0-.95-.77-1.71-1.71-1.71s-1.71,.77-1.71,1.71c0,.62,.33,1.15,.82,1.46l-.13,2.85c-.03,.3,.2,.55,.5,.55h1.05c.3,0,.53-.26,.5-.55l-.13-2.86c.49-.3,.82-.84,.82-1.45Z"/>
    <path d="M18.52,0H5.48C2.93,0,.86,2.07,.86,4.62v14.76c0,2.55,2.07,4.62,4.62,4.62h13.04c2.55,0,4.62-2.07,4.62-4.62V4.62c0-2.55-2.07-4.62-4.62-4.62Zm.12,12.26v5.41c0,1.09-.88,1.97-1.97,1.97H7.33c-1.09,0-1.97-.88-1.97-1.97v-6.79c0-1.05,.83-1.91,1.87-1.96,.34-2.57,2.33-4.57,4.77-4.57s4.43,1.99,4.77,4.57c1.04,.05,1.87,.9,1.87,1.96v1.37Z"/>
  </svg>

export const DraftIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M13.37,8.46s-.12-.05-.17,0l-6.66,6.66c-.09,.09-.14,.22-.14,.34v1.75c0,.21,.17,.38,.38,.38h1.75c.13,0,.25-.05,.34-.14l6.66-6.66s.05-.12,0-.17l-2.17-2.17Z"/>
    <path d="M15.97,6.58c-.24-.24-.64-.24-.88,0l-1.06,1.06s-.05,.12,0,.17l2.17,2.17s.12,.05,.17,0l1.06-1.06c.24-.24,.24-.64,0-.88l-1.45-1.45Z"/>
    <path d="M17.87,23.89h-4.56v-1.83h4.51l.05,1.83Zm-6.48,0H6.88v-1.83h4.51v1.83Zm-6.66-.2c-1.78-.51-3.18-1.93-3.65-3.72l1.77-.47c.31,1.17,1.23,2.1,2.39,2.43l-.5,1.76Zm15.39-.32l-.8-1.65c1.09-.53,1.83-1.6,1.94-2.8l1.82,.16c-.16,1.84-1.3,3.48-2.96,4.29ZM2.73,17.83H.9v-4.51h1.83v4.51Zm20.37-.75h-1.83v-4.51h1.83v4.51ZM2.73,11.4H.9V6.9h1.83v4.51Zm20.37-.75h-1.83V6.14h1.83v4.51ZM2.74,5.06l-1.82-.17c.17-1.84,1.32-3.47,2.99-4.27l.79,1.65c-1.09,.52-1.84,1.59-1.95,2.79Zm18.41-.58c-.32-1.17-1.24-2.09-2.4-2.42l.49-1.76c1.78,.5,3.19,1.91,3.68,3.7l-1.76,.48Zm-4.06-2.54h-4.51V.11h4.51V1.94Zm-6.43,0H6.18l-.03-1.83h4.51V1.94Z"/>
  </svg>
  
export const CloseIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <polygon points="24 21.54 14.37 11.91 23.91 2.37 21.63 .09 12.09 9.63 2.46 0 0 2.46 9.63 12.09 .09 21.63 2.37 23.91 11.91 14.37 21.54 24 24 21.54" />
  </svg>
  
export const PinIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M21.68,7.49l-5.19-5.19c-.34-.34-.78-.51-1.22-.51-.52,0-1.04,.23-1.38,.69l-7.71,6.94-3.1,.76c-1.27,.36-1.68,1.96-.75,2.89l3.69,3.69c-.08,.01-.16,.04-.22,.11l-3.63,3.63c-.39,.39-.39,1.02,0,1.41s1.02,.39,1.41,0l3.63-3.63c.06-.06,.09-.14,.11-.22l3.6,3.6c.35,.35,.79,.51,1.22,.51,.73,0,1.44-.46,1.67-1.25l.75-3.09,6.95-7.73c.84-.63,.92-1.86,.18-2.61Zm-1.67,1.27l-6.95,7.73-.34,.38-.12,.49-.62,2.55-7.91-7.91,2.58-.63,.49-.12,.37-.34L15.22,3.97l.06-.05,4.78,4.78-.05,.06Z"/>
  </svg>  

export const PinIconSolid = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M21.68,7.49l-5.19-5.19c-.74-.74-1.97-.66-2.61,.18l-7.71,6.94-3.1,.76c-1.27,.36-1.68,1.96-.75,2.89l3.7,3.7c-.09,.01-.17,.04-.23,.11l-3.63,3.63c-.39,.39-.39,1.02,0,1.41s1.02,.39,1.41,0l3.63-3.63c.07-.07,.1-.15,.11-.23l3.61,3.61c.93,.93,2.52,.52,2.89-.75l.75-3.09,6.95-7.73c.84-.63,.92-1.86,.18-2.61Z"/>
  </svg>

export const ReportIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M11.2,14.73h1.6c.11,0,.2-.08,.21-.19l.47-7.79c0-.12-.09-.22-.2-.22h-2.55c-.12,0-.21,.1-.2,.22l.47,7.79c0,.11,.1,.19,.21,.19Z" fillRule="evenodd" />
    <circle cx="12" cy="17.71" r="1.49"/>
    <path d="M12,3.38c.95,0,1.78,.46,2.28,1.27l7.32,11.9c.51,.84,.54,1.85,.06,2.7s-1.35,1.37-2.33,1.37H4.68c-.98,0-1.85-.51-2.33-1.37-.48-.86-.46-1.87,.06-2.7L9.72,4.65c.5-.81,1.33-1.27,2.28-1.27m0-2c-1.53,0-3.07,.74-3.98,2.22L.7,15.5c-1.92,3.11,.32,7.12,3.98,7.12h14.64c3.66,0,5.9-4.01,3.98-7.12L15.98,3.6c-.91-1.48-2.45-2.22-3.98-2.22h0Z"/>
  </svg>

export const InfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M50,0C22.39,0,0,22.39,0,50s22.39,50,50,50,50-22.39,50-50S77.61,0,50,0Zm0,93c-23.71,0-43-19.29-43-43S26.29,7,50,7s43,19.29,43,43-19.29,43-43,43Z" />
    <path d="M54.57,22.71c-4.18,0-7.49,3.24-7.49,7.34,0,3.67,2.74,6.48,6.7,6.48,4.25,0,7.49-3.24,7.49-7.27,0-3.75-2.74-6.55-6.7-6.55Z" />
    <path d="M58.39,40.93h-16.63v.14c1.8,1.3,2.67,2.23,2.67,4.03,0,.86-.22,1.94-.65,3.6l-4.68,18.65c-.22,.94-.36,1.73-.36,2.59,0,4.18,2.81,7.34,8.35,7.34,3.38,0,7.27-1.08,10.73-4.75v-.14c-1.51,.65-2.81,1.01-3.82,1.01-1.8,0-2.88-.94-2.88-2.66,0-.43,.07-.94,.22-1.51l7.06-28.3Z" />
  </svg>
);
export const ShareIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M76.17,64.84a17.49,17.49,0,0,0-13.75,6.74l-27.9-14.3a16.45,16.45,0,0,0,.64-4.35,17.36,17.36,0,0,0-1.09-6L63.19,29.3a17.48,17.48,0,1,0-4.6-11.72,17.13,17.13,0,0,0,1,5.7L30.38,41a17.42,17.42,0,0,0-12.8-5.68,17.58,17.58,0,1,0,13.9,28.24L59.29,77.84a17,17,0,0,0-.7,4.58A17.58,17.58,0,1,0,76.17,64.84Z" />
  </svg>
);
export const StyledOneIcon = () => (
  <svg width="53" height="92" viewBox="0 0 53 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9629 47.0703C18.6517 47.0703 21.0443 46.8197 23.1406 46.3184C25.237 45.8171 26.2852 45.0651 26.2852 44.0625C26.2852 43.4245 26.0801 42.9004 25.6699 42.4902C22.89 43.4928 19.8594 43.9941 16.5781 43.9941C12.112 43.9941 8.35221 42.4674 5.29883 39.4141C2.24544 36.3151 0.71875 31.8717 0.71875 26.084C0.71875 22.2559 1.51628 18.5417 3.11133 14.9414C8.85352 14.2578 13.3197 13.1185 16.5098 11.5234C20.9759 9.24479 24.804 5.57617 27.9941 0.517578H40.2305C44.1497 0.517578 47.0436 1.24674 48.9121 2.70508C51.0996 4.48242 52.1934 7.60417 52.1934 12.0703V84.668C49.8691 86.8099 47.1576 88.4505 44.0586 89.5898C41.0052 90.6836 37.8607 91.2305 34.625 91.2305C29.293 91.2305 24.9635 89.9544 21.6367 87.4023C17.8542 84.5312 15.9629 80.3385 15.9629 74.8242V47.0703Z" />
  </svg>
);