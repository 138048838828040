import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { ArrowRightIcon, CheckIcon, SearchIcon } from "../../assets/icons";

type Props = {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  onSubmit: () => void;
  classNames?: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

const SearchBar = ({searchQuery, setSearchQuery, onSubmit, classNames, autoFocus, disabled}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  
  const handleEnterKey = (code: KeyboardEvent<HTMLInputElement>['key']) => {
    if (searchQuery.length < 3) return;
    if (code === 'Enter') {
      onSubmit();
    }
  }
  
  return (
    <div className={`search-container ${classNames || ''}`} aria-selected={isFocused}>
      <SearchIcon />
      <input 
        type={"text"}
        autoFocus={autoFocus}
        placeholder={"Search"}
        value={searchQuery} 
        onChange={(e) => setSearchQuery(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyDown={(e) => handleEnterKey(e.key)}
        disabled={disabled}
      />
      {searchQuery.length > 2 && 
        <button className="icon escape-padding" onClick={onSubmit}>
          <ArrowRightIcon />
        </button>
      }
    </div>
  )
}
export default SearchBar;