import { Auth0ContextInterface, useAuth0, User } from "@auth0/auth0-react";

type AuthReturn = Omit<Auth0ContextInterface<User>, "getAccessTokenSilently"> & {
  getAccessTokenSilently: () => Promise<string>
};

const useAuthentication = (): AuthReturn => {

  const {
    error,
    isAuthenticated,
    isLoading,
    user,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    getIdTokenClaims,
    loginWithRedirect,
    loginWithPopup,
    logout,
    handleRedirectCallback
  } = useAuth0();

  const isDev = process.env.REACT_APP_DEV === 'true';

  // Override values in development
  return {
    error: isDev ? undefined : error,
    isAuthenticated: isDev ? true : isAuthenticated,
    isLoading: isDev ? false : isLoading,
    user: isDev ? { name: 'Dev User', email: 'devuser@example.com' } : user,
    getAccessTokenSilently: isDev
      ? async () => 'dev-access-token'
      : getAccessTokenSilently,
    getAccessTokenWithPopup: isDev
      ? async () => 'dev-access-token'
      : getAccessTokenWithPopup,
    getIdTokenClaims: isDev
      ? async () => ({ __raw: 'dev-id-token' })
      : getIdTokenClaims,
    loginWithRedirect,
    loginWithPopup,
    logout: isDev ? async () => undefined : logout,
    handleRedirectCallback: isDev 
      ? async () => ({appState: undefined})
      : handleRedirectCallback
  };
};

export default useAuthentication;