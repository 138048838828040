import { Categories, CollectionPeriods, PopulatedList, User } from "../types/SharedTypes"
import { fallbackImage } from "./urls"

export const localPrefKeys = {
  disableThemeInEditor: 'disabled_theme_in_editor',
  forceDarkTheme: 'force_dark_theme'
}
export const localStorageKeys = {
  listCreatorDraft: 'list_creator_draft',
  localPreferences: 'local_prefs',
  intentToSaveDemo: 'intent_to_save_demo'
}

export const searchAttributions: {[key: string]: {title: string, link: string}} = {
  manga: {
    title: 'Mangadex',
    link: "https://mangadex.org"
  },
  movies: {
    title: 'TMDB',
    link: "https://www.themoviedb.org/"
  },
  tvshows: {
    title: 'TMDB',
    link: "https://www.themoviedb.org/"
  },
  anime: {
    title: 'TMDB',
    link: "https://www.themoviedb.org/"
  },
  books: {
    title: "Google Books",
    link: "https://books.google.ca/"
  },
  music: {
    title: "Spotify",
    link: "https://spotify.com"
  }
}

export const smartPeriodSingular = (period?: CollectionPeriods, cap?: boolean) => {
  if (period === 'weekly') return cap ? 'Week' : 'week';
  else if (period === 'monthly') return cap ? 'Month' : 'month';
  else if (period === 'seasonal') return cap ? 'Season' : 'season';
  else if (period === 'yearly') return cap ? 'Year' : 'year';
  else return '';
}

const demoUser: User = {
  _id: 'demouser',
  username: "demouser",
  displayName: "Demo User",
  picture: fallbackImage,
  banner: fallbackImage,
  biography: "demo bio",
  pinned: undefined,
  verified: false
}

export const demoListData: PopulatedList = {
  _id: 'demo',
  createdAt: new Date().toString(),
  updatedAt: new Date().toString(),
  isDraft: true,
  isPublic: false,
  isAutoGenerated: false,
  ascending: true,
  ordered: true,
  description: "All my favourite things in one place",
  title: "My demo list",
  category: "movies" as Categories,
  subCategories: [],
  theme: 'default',
  thumbnailEntry: 3,
  image: undefined,
  views: 0,
  entriesCount: 0,
  type: 'list' as const,
  author: demoUser
};