import { useNavigate, useParams } from "react-router";
import PageWrapper from "./PageWrapper";
import { useFollowUser, useGetPopularPostsByUser, useGetPopularPostsByUserOpen, useGetPublicPostsByUser, useGetUserByUsername, useGetUserByUsernameOpen, useUnfollowUser } from "../api/data-access";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import useHideHeader from "../hooks/useHideHeader";
import { AddIcon, ArrowIcon, LinkIcon, OptionsVerticalIcon, ReportIcon, SettingsIcon } from "../assets/icons";
import { Link } from "react-router-dom";
import ListFeedCard from "../components/shared/cards/ListFeedCard";
import { DecoratedUser, ListPostType } from "../types/SharedTypes";
import LoadingIcon from "../components/shared/LoadingIcon";
import LoadingContainer from "../components/shared/LoadingContainer";
import EditProfileModal from "../components/profile/EditProfileModal";
import GeneralConfirmationModal from "../components/shared/modals/GeneralConfirmationModal";
import CollectionFeedCard from "../components/shared/cards/CollectionFeedCard";
import FeedCard from "../components/shared/cards/FeedCard";
import FollowsModal from "../components/shared/modals/FollowsModal";
import { ToastContext } from "../contexts/ToastContext";
import { checkLinkAgainstWhitelist } from "../tools/Helpers";
import ContextMenu, { ContextMenuContent } from "../components/shared/modals/ContextMenu";
import ReportModal from "../components/shared/modals/ReportModal";

const fallbackImage = "https://images.pexels.com/photos/733995/pexels-photo-733995.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2";

const Profile  = ({auth = true}: {auth?: boolean}) => {
  const {username: username} = useParams();
  return auth ? <AuthContent username={username} /> : <NoAuthContent username={username} />
}

const NoAuthContent = ({username}: {username?: string}) => {
  
  const {data: user, isPending, isError, isSuccess} = useGetUserByUsernameOpen(username || "", (!!username));
  const {data: topPosts, isPending: topPostsPending, isSuccess: topPostsSuccess} = useGetPopularPostsByUserOpen(user?._id || "", (isSuccess));
  
  if (isPending || topPostsPending) return <LoadingContainer />;
  else if ((isError || !isSuccess || !topPostsSuccess)) return <p>Error</p>;
  return (
    <PageWrapper padding={false}>
      <UserProfileHeader user={user} isCurrentUser={false} preventControls />
      {topPosts.length > 0 &&
        <section data-padding={true} className="item-list large bottom-border secondary-border">
          <h2 className="heading-small weight-light">Popular</h2>
          <SliderListFeed posts={topPosts} />
        </section>
      }
      <div className="padding">
        <div className="tile padding-small">
          <p className="body center">{`Login to see more posts from ${user.displayName}`}</p>
        </div>
      </div>
    </PageWrapper>
  )
}

const AuthContent = ({username}: {username?: string}) => {
  
  useHideHeader();
  
  const {authUserId} = useContext(AuthContext);
  const {requestToast} = useContext(ToastContext);
  
  const {data: user, isPending, isError, isSuccess} = useGetUserByUsername(username || "", (!!username));
  const {data: userPosts, isPending: postsPending, isError: postsError, isSuccess: postsSuccess} = useGetPublicPostsByUser(user?._id || "", (isSuccess));
  const {data: topPosts, isPending: topPostsPending, isSuccess: topPostsSuccess} = useGetPopularPostsByUser(user?._id || "", (isSuccess));

  const [currentTab, setCurrentTab] = useState<'recent'|'popular'>('recent');
  
  const handlePinToast = () => {
    requestToast('Hit the three dots on any list to pin it!');
  }
  
  const isCurrentUser = isSuccess ? user._id === authUserId : false;  
  
  if (isPending || postsPending || topPostsPending) return <LoadingContainer />;
  else if ((isError || !isSuccess) || (postsError || !postsSuccess) || (!topPostsSuccess)) return <p>Error</p>;
  return (
    <PageWrapper padding={false}>
      <UserProfileHeader user={user} isCurrentUser={isCurrentUser} />
      {(user.pinned || isCurrentUser) &&
        <section data-padding={true} className="item-list large bottom-border secondary-border">
          <h2 className="heading-small weight-light">Pinned</h2>
          {isCurrentUser && !user.pinned &&
            <button className="full-width secondary icon-text xs" onClick={handlePinToast}>
              <AddIcon />
              Pin a List or Collection
            </button>
          }
          {user.pinned &&
            <div className="escape-container padding-small-horizontal">
              {user.pinned.type === 'list' && <ListFeedCard listId={user.pinned._id} hideHeader={user.pinned.author === user._id} small isPinned isInFeed={false} themedHeader  />}
              {user.pinned.type === 'collection' && <CollectionFeedCard collectionId={user.pinned._id} hideHeader={user.pinned.author === user._id} small isPinned isInFeed={false} themedHeader returnRecentInSmartSet />}
            </div>
          }
        </section>
      }
      <section data-padding={true} className="item-list large bottom-border secondary-border">
        {/* <TabBar unstyled tabData={[{title: "Recent", onClick: () => setCurrentTab('recent'),}, {title: "Popular", onClick: () => setCurrentTab('popular')}]} /> */}
        <h2 className="heading-small weight-light">Popular</h2>
        <SliderListFeed posts={topPosts} />
        {topPosts.length === 0 && 
          <p className="body secondary">{`Nothing to show. ${isCurrentUser ? 'Create your first list now!' : ''}`}</p>
        }
      </section>
      <section data-padding={true} className="item-list large">
        <h2 className="heading-small weight-light">All Lists</h2>
        <div className="escape-container padding-small-horizontal item-list">
          {userPosts.map(post =>
            <FeedCard 
              post={post}
              key={post._id}
              small
              hideHeader
              returnRecentInSmartSet
            />  
          )}
        </div>
        {userPosts.length === 0 && 
          <p className="body secondary">{`Nothing to show. ${isCurrentUser ? 'Create your first list now!' : ''}`}</p>
        }
      </section>
    </PageWrapper>
  )
}

type UserHeaderProps = {
  user: DecoratedUser;
  isCurrentUser: boolean;
  preventControls?: boolean;
}

const UserProfileHeader = ({user, isCurrentUser, preventControls}: UserHeaderProps) => {
  
  const navigate = useNavigate();
  
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [followsModalVisible, setFollowsModalVisible] = useState(false);
  const [optionsModalVisible, setOptionsModalVisible] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);

  const itemOptionsData: ContextMenuContent = {
    title: "Options",
    focusedItem: false,
    menuContent: [
      {
        title: "Report User",
        icon: ReportIcon,
        handleClick: () => setReportModalVisible(true)
      },
    ]
  }
  
  return (
    <>
      {reportModalVisible && <ReportModal setModalVisible={setReportModalVisible} contentId={user._id} contentType={"User"} />}
      {optionsModalVisible && <ContextMenu menuData={itemOptionsData} setModalVisible={setOptionsModalVisible} />}
      {linkModalVisible &&
        <GeneralConfirmationModal setModalVisible={setLinkModalVisible} onConfirm={() => window.open(user.link, '_blank')}>
          <div className="item-list xl">
            <div className="item-list small">
              <p className="heading-small spaced">By clicking this link you'll be leaving Peaklist.</p>
              <p className="subtitle-large spaced secondary">We can't guarantee anything good will come out of this.</p>
            </div>
            <a href={user.link} rel="noopener" target="_blank" className="link">
              <p className="subtitle-large weight-bold spaced break-anywhere">{user.link}</p>
            </a>
          </div>
        </GeneralConfirmationModal>
      }
      {!preventControls && followsModalVisible && <FollowsModal setModalVisible={setFollowsModalVisible} userId={user._id} />}
      <div className="banner">
        <div className="banner-overlay" data-padding={true}>
          <div className="right-item-layout center-horizontal justify-start">
            <div className="tag item-row" data-white={true}>
              <a onClick={() => navigate(-1)} className="icon small">
                <ArrowIcon />
              </a>
              <p className="subtitle-large">@{user.username}</p>
            </div>
            {isCurrentUser &&
              <Link to={"/settings"}>
                <button className="icon small always-white shadow">
                  <SettingsIcon />
                </button>
              </Link>
            }
          </div>
        </div>
        <img src={user?.banner || fallbackImage} alt={user.displayName} />
      </div>
      
      <section data-padding={true} className="item-list large bottom-border secondary-border">
        <article className="item-row large align-top profile-header">
          <div className="user-avatar large">
            <img src={user.picture} />
          </div>
          <div className="item-list">
            <div className="item-row">
              <h1 className="heading-medium weight-light">{user.displayName}</h1>
              {isCurrentUser && <p className="subtitle-medium">You</p>}
            </div>
            <div className="item-row">
              <p className="subtitle-medium secondary">{user.listCount + user.collectionCount} Posts</p>
              <p className="subtitle-medium secondary">•</p>
              <button className="text" onClick={() => !preventControls && setFollowsModalVisible(true)}>
                <p className="subtitle-medium secondary">{user.followers} Followers</p>
              </button>
            </div>
          </div>
        </article>
        {!preventControls &&
          <>{isCurrentUser 
            ? <AuthedControls user={user} /> 
            : <Controls userId={user._id} isFollowing={user.authIsFollowing} openOptions={() => setOptionsModalVisible(true)} />
          }</>
        }
        {user.biography &&
          <p className="body spaced">{user.biography}</p>
        }
        {user.link &&
          <button className="text icon-text small" onClick={() => user.link && checkLinkAgainstWhitelist(user.link, setLinkModalVisible)}>
            <div className="item-row min-width">
              <LinkIcon />
              <p className="subtitle-large weight-bold ellipsis">{user.link}</p>
            </div>
          </button>
        }
      </section>
    </>
  )
}

type SliderListFeedProps = {
  posts: ListPostType[];
}

const SliderListFeed = ({posts}: SliderListFeedProps) => {
  
  return (
    <div className="slider">
      <div className="slider-inner">
        {posts.map((post, i) => 
          <React.Fragment key={post._id}>
            {post.type === 'list'
              ? <ListFeedCard list={post} hideHeader />
              : <CollectionFeedCard collection={post} hideHeader returnRecentInSmartSet />
            }
            {i === posts.length - 1 && posts.length > 1 && 
              <div className="slider-end-block" />
            }
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

const AuthedControls = ({user}: {user: DecoratedUser}) => {
  
  const [editModalVisible, setEditModalVisible] = useState(false);
  
  return (
    <>
      {editModalVisible && <EditProfileModal user={user} setModalVisible={setEditModalVisible} />}
      <button className="full-width primary" onClick={() => setEditModalVisible(true)}>
        Edit Profile
      </button>
    </>
  )
}

type ControlsProps = {
  userId: string;
  isFollowing: boolean;
  openOptions: () => void;
}

const Controls = ({userId, isFollowing, openOptions}: ControlsProps) => {
  
  const followUser = useFollowUser();
  const unfollowUser = useUnfollowUser();
  
  useEffect(() => {
    console.log(followUser.isSuccess);
  }, [followUser.isSuccess]);
  
  const handleToggleFollow = () => {
    isFollowing
      ? unfollowUser.mutate(userId)
      : followUser.mutate(userId);
  }
  
  const followStateLoading = followUser.isPending || unfollowUser.isPending;
  
  return (
    <div className="right-item-layout">
      <button className="full-width primary" data-loading={followStateLoading} aria-disabled={followStateLoading} onClick={handleToggleFollow}>
        {followStateLoading && <LoadingIcon small />}
        {isFollowing ? "Following" : "Follow"}
      </button>
      <button className="icon small" onClick={openOptions}>
        <OptionsVerticalIcon />
      </button>
    </div>
  )
}

export default Profile;