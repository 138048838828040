import { useState } from "react";
import { PeakLogoSmall } from "../../assets/icons";
import SearchBar from "../shared/SearchBar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import useAuthentication from "../../hooks/useAuthentication";

const HeaderNavigationBar = ({auth}: {auth: boolean}) => {
  return (
    <div className="header-bar">
      <div className={`${auth ? "left-item-layout" : 'right-item-layout'} large center-horizontal`}>
        <Link to={'/'}>
          <PeakLogoSmall />
        </Link>
        {auth ? <AuthContents /> : <NoAuthContents />}
      </div>
    </div>
  )
}

const NoAuthContents = () => {
  
  const { loginWithRedirect } = useAuthentication();
  
  return (
    <div className="item-row">
      <button className="subtle" onClick={() => loginWithRedirect({appState: {returnTo: window.location.pathname}})}>
        Login
      </button>
      <button className="primary" onClick={() => loginWithRedirect({authorizationParams: {screen_hint: "signup"}})}>
        Signup
      </button>
    </div>
  )
}

const AuthContents = () => {
  
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  
  const handleSubmit = () => {
    setSearchQuery('');
    navigate(`/search?initialQuery=${searchQuery}`);
  }
  
  return <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} onSubmit={handleSubmit} />;
}

export default HeaderNavigationBar;