import { baseAPIUrl } from "../constants/urls";
import authClient, {openClient} from "./authInterceptor";
import { CreateListPayload, UpdateListPayload, ListPostType, BaseListType, CreateCollectionPayload, BaseCollectionType, UpdateCollectionPayload, DecoratedList, DecoratedCollection, PopulatedListPostType, PagedResponse, PagedRequest, ListPostOf, CreateSmartCollectionPayload, UpdateSmartCollectionPayload, Categories, ListPostTypes } from "../types/SharedTypes";
import { createPagedRequestQueries } from "../tools/Helpers";
import { FeedFilterOptions } from "./data-access";

const listsUrl = `${baseAPIUrl}/lists`;
const smartCollectionUrl = `${baseAPIUrl}/smartcollection`;
const openListsUrl = listsUrl + '/open';

const openListsApi = openClient;
const listsApi = authClient;

const getFilterQueries = (filter?: FeedFilterOptions, start: boolean = true) => {
  if (!filter) return "";
  return (start ? "?=" : "") + (filter.category ? `&category=${filter.category}` : '') + (filter.type ? `&type=${filter.type}` : '') + (filter.period ? `&period=${filter.period}` : '');
}

//OPEN
export const getPostFeedOpen = async ({pageParam, category}: {pageParam: PagedRequest, category?: string}) => {
  
  const path = createPagedRequestQueries(pageParam) + (category ? `&category=${category}` : '');
  
  const res = await openListsApi.get<PagedResponse<PopulatedListPostType>>(openListsUrl + path);
  return res.data;
}

export const getListByIdOpen = async (id: string) => {
  const res = await openListsApi.get<DecoratedList>(`${openListsUrl}/${id}`);
  return res.data;
}
export const getCollectionByIdOpen = async (id: string) => {
  const res = await openListsApi.get<DecoratedCollection>(`${openListsUrl}/${id}`);
  return res.data;
}

export const getPopularPostsByUserOpen = async (userId: string) => {
  const res = await listsApi.get<ListPostType[]>(`${openListsUrl}/popular/${userId}`);
  return res.data;
}

//AUTHED
export const getPostFeed = async ({pageParam, filters}: {pageParam: PagedRequest, filters: FeedFilterOptions}) => {
  
  const path = createPagedRequestQueries(pageParam) + getFilterQueries(filters, false);
  
  const res = await listsApi.get<PagedResponse<PopulatedListPostType>>(listsUrl + path);
  return res.data;
}
export const getFollowingPostFeed = async ({pageParam, filters}: {pageParam: PagedRequest, filters: FeedFilterOptions}) => {
  
  const path = createPagedRequestQueries(pageParam) + getFilterQueries(filters, false);
  
  const res = await listsApi.get<PagedResponse<PopulatedListPostType>>(listsUrl + "/following" + path);
  return res.data;
}

export const getUserPublicPosts = async (filters?: FeedFilterOptions) => {
  const res = await listsApi.get<ListPostType[]>(listsUrl + "/user/public" + getFilterQueries(filters));
  return res.data;
}

export const getUserPrivatePosts = async (filters?: FeedFilterOptions) => {
  const res = await listsApi.get<ListPostType[]>(listsUrl + "/user/private" + getFilterQueries(filters));
  return res.data;
}

export const getUserAutoPosts = async () => {
  const res = await listsApi.get<ListPostType[]>(listsUrl + "/user/auto");
  return res.data;
}

export const getUserDraftPosts = async (filters?: FeedFilterOptions) => {
  const res = await listsApi.get<ListPostType[]>(listsUrl + "/user/draft" + getFilterQueries(filters));
  return res.data;
}
export const getAllUserLists = async () => {
  const res = await listsApi.get<ListPostOf<'list'>[]>(listsUrl + "/user/lists");
  return res.data;
}
export const getPeriodsBySet = async (setId: string) => {
  const res = await listsApi.get<ListPostOf<'collection'>[]>(`${listsUrl}/user/sets/periods/${setId}`);
  return res.data;
}

export const getPublicPostsByUser = async (userId: string) => {
  const res = await listsApi.get<ListPostType[]>(`${listsUrl}/public/${userId}`);
  return res.data;
}
export const getPopularPostsByUser = async (userId: string) => {
  const res = await listsApi.get<ListPostType[]>(`${listsUrl}/popular/${userId}`);
  return res.data;
}

export const getListsToImportForCollection = async (id: string) => {
  const res = await listsApi.get<ListPostType[]>(`${listsUrl}/import/collection/${id}`);
  return res.data;
}

export const getListById = async (id: string) => {
  const res = await listsApi.get<DecoratedList>(`${listsUrl}/${id}`);
  return res.data;
}
export const getCollectionById = async (id: string, returnRecent?: boolean) => {
  const res = await listsApi.get<DecoratedCollection>(`${listsUrl}/${id}`, {params: {returnRecent}});
  return res.data;
}

export const createNewList = async (postObj: CreateListPayload) => {
  const res = await listsApi.post<BaseListType>(listsUrl + "/create/list", postObj);
  return res.data;
}
export const createNewCollection = async (postObj: CreateCollectionPayload) => {
  const res = await listsApi.post<BaseCollectionType>(listsUrl + "/create/collection", postObj);
  return res.data;
}
export const createNewSmartCollection = async (postObj: CreateSmartCollectionPayload) => {
  const res = await listsApi.post<string>(smartCollectionUrl, postObj);
  return res.data;
}

export const updateList = async ({id, update}: UpdateListPayload) => {
  const res = await listsApi.put<BaseListType>(`${listsUrl}/list/${id}`, update);
  return res.data;
}
export const updateCollection = async ({id, update}: UpdateCollectionPayload) => {
  const res = await listsApi.put<BaseCollectionType>(`${listsUrl}/collection/${id}`, update);
  return res.data;
}

export const updateSmartCollection = async ({id, update}: UpdateSmartCollectionPayload) => {
  await listsApi.put<void>(`${smartCollectionUrl}/${id}`, update);
}
export const updateCollectionsInSet = async ({id, update}: UpdateCollectionPayload) => {
  await listsApi.put<void>(`${listsUrl}/collection/smart/${id}`, update);
}

export const updatePostVisibility = async ({id, update}: {id: string, update: 'personal'|'public'}) => {
  await listsApi.put<void>(`${listsUrl}/visibility/${id}/${update}`);
}
export const revertPostToDraft = async ({id}: {id: string}) => {
  await listsApi.put<void>(`${listsUrl}/visibility/${id}/draft`);
}

export const importListsToColleciton = async ({id, lists}: {id: string, lists: string[]}) => {
  await listsApi.put<void>(`${listsUrl}/import/collection/${id}`, {listIds: lists});
}

export const deleteList = async ({listId, deleteSmartSet}: {listId: string, deleteSmartSet?: boolean}): Promise<void> => {
  const res = await listsApi.delete<void>(`${listsUrl}/${listId}`, {params: {deleteSmartSet}});
  return res.data;
}