import { QueryKey, UseQueryOptions, useInfiniteQuery } from "@tanstack/react-query";
import { PagedRequest, PagedResponse } from "../../types/SharedTypes";

type Enabled = UseQueryOptions["enabled"];

type InfiniteQueryParams<T> = {
  queryKey: QueryKey,
  queryFn: (payload: any) => Promise<PagedResponse<T>>,
  extraQueryFnParams?: any,
  pageSize?: number,
  enabled: Enabled
};

export const useInfiniteQueryWrapper = <T, >({
  queryKey,
  queryFn,
  extraQueryFnParams,
  pageSize,
  enabled
}: InfiniteQueryParams<T>) => useInfiniteQuery({
  queryKey,
  queryFn: ({pageParam}) => queryFn({pageParam, ...extraQueryFnParams}),
  initialPageParam: {
    fromId: undefined,
    limitOlder: pageSize || 10,
    limitNewer: undefined,
    includeFrom: false
  } as PagedRequest,
  getNextPageParam: (lastPage) => lastPage.atEnd 
    ? undefined 
    : ({
      fromId: lastPage.nextFromId,
      limitOlder: pageSize || 10,
      limitNewer: undefined,
      includeFrom: false
  }),
  getPreviousPageParam: (lastPage) => lastPage.atEnd 
    ? undefined 
    : ({
      fromId: lastPage.nextFromId,
      limitOlder: pageSize || 10,
      limitNewer: undefined,
      includeFrom: false
  }),
  enabled
});