import { useState, useContext, useEffect, DragEvent } from 'react';
import { ToastContext } from '../../../contexts/ToastContext';
import { UploadImageIcon } from '../../../assets/icons';

type Props = {
  title: string;
  uniqueID: string;
  mimeTypes: string[];
  getFiles: (files: File[]) => void;
  uploadMultiple?: boolean;
  label?: string;
  subtitle?: string;
  icon?: JSX.Element;
  hero?: boolean;
  selectFromMediaBrowser?: boolean;
  required?: boolean;
};

const UploadInput = ({
  title,
  label,
  uniqueID,
  mimeTypes,
  getFiles,
  uploadMultiple,
  subtitle,
  icon,
  hero
}: Props) => {

  const [dragOver, setDragOver] = useState(false);
  const [inputAccepts, setInputAccepts] = useState('');

  const { requestToast } = useContext(ToastContext);

  const handleFileDrop = async (ev: DragEvent<HTMLLabelElement> | DragEvent<HTMLInputElement>) => {
    ev.preventDefault();
    const fileArr: File[] = [];
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        const item = ev.dataTransfer.items[i];

        // Handle files dropped from local system
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (!file) continue;

          // Check file size
          if (file.size > MAX_FILE_SIZE) {
            requestToast(`File ${file.name} is larger than 2MB and will be skipped.`, "warn");
            continue;
          }

          const valid = verifyMimeTypes(file);
          if (!valid) {
            setDragOver(false);
            return;
          }
          fileArr.push(file);
        }

        // Handle images dropped from other browser tabs
        if (item.kind === 'string' && item.type === 'text/uri-list') {
          const url = await new Promise<string>((resolve) =>
            item.getAsString(resolve)
          );
          try {
            const response = await fetch(url);
            const blob = await response.blob();

            // Check blob size
            if (blob.size > MAX_FILE_SIZE) {
              requestToast(`Image is too large (greater than 2MB)`, "warn");
              continue;
            }

            const file = new File([blob], 'image-from-url', { type: blob.type });
            const valid = verifyMimeTypes(file);
            if (!valid) {
              setDragOver(false);
              return;
            }
            fileArr.push(file);
          } catch (error) {
            console.error('Failed to fetch dropped image:', error);
          }
        }
      }
    }

    if (fileArr.length > 0) {
      sendFiles(fileArr.filter(file => !!file));
    }
  };

  const verifyMimeTypes = (file: File) => {
    const incomingMime = file.type;
    let matched = false;
    
    //loop through allowed mimeTypes and reject if it doesnt match
    for (const mime of mimeTypes) {
      const mimeSplit = mime.split('/');
      const incomingMimeSplit = incomingMime.split('/');

      if (
        mimeSplit[0] === incomingMimeSplit[0] &&
        (mimeSplit[1] === incomingMimeSplit[1] || incomingMimeSplit[1] === `x-${mimeSplit[1]}`)
      ) {
        matched = true;
        return matched;
      }
    }
    if (!matched) {
      requestToast('File type not supported.', 'warn');
    }
    return matched;
  };

  const handleDragOver = (ev: DragEvent<HTMLLabelElement>) => {
    ev.preventDefault();
  };
  const sendFiles = (files: FileList | File[] | null) => {
    let valid = false;
    if (!files) return;
    for (const file of files) {
      if (!file) continue;
      verifyMimeTypes(file) && (valid = true);
    }

    setDragOver(false);
    if (!valid) return;
    getFiles([...files]);
  };

  useEffect(() => {
    setInputAccepts(mimeTypes.join(', '));
  }, [mimeTypes]);

  return (
    <>
      <div className={`file-input min-width item-list small ${hero ? 'hero' : ''}`}>
        <div className="file-input-button">
          <label
            htmlFor={uniqueID}
            className="file-input-large"
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
            onDragEnter={() => setDragOver(true)}
            onDragExit={() => setDragOver(false)}
            data-drag-over={dragOver}
          >
            {icon ? icon : <UploadImageIcon />}
            <div className="item-list small">
              <span className={`${hero ? 'heading-medium' : ''}`}>
                {dragOver ? "Dropping file.." : title}
              </span>
              {subtitle && <span className="heading-small weight-light">{subtitle}</span>}
            </div>
          </label>
        </div>
        <input
          className="droppable"
          type="file"
          id={uniqueID}
          accept={inputAccepts}
          onDrop={handleFileDrop}
          onChange={(ev) => sendFiles(ev.target.files)}
          multiple={uploadMultiple}
        />
        <label className="ellipsis secondary">{label || ''}</label>
      </div>
    </>
  );
};
export default UploadInput;
