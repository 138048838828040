import { baseAPIUrl } from "../constants/urls";
import { AutoPopulateListItem, AutoPopulateListItemResults, MovieResults, PopulatedListPostType, SearchBooksPayload, SearchMangaPayload, SearchTMDBPayload, User } from "../types/SharedTypes";
import createAxios from "./authInterceptor";

const searchUrl = `${baseAPIUrl}/search`;

const searchApi = createAxios;

export const searchUsers = async (payload: {query: string}) => {
  const res = await searchApi.get<User[]>(searchUrl + '/users', {params: {query: payload.query}});
  return res.data;
}
export const searchLists = async (payload: {query: string, category?: string}) => {
  const res = await searchApi.get<PopulatedListPostType[]>(searchUrl + '/lists', {params: {query: payload.query, category: payload.category}});
  return res.data;
}

export const searchTMDB = async (payload: SearchTMDBPayload) => {
  const res = await searchApi.get<MovieResults>(searchUrl + '/tmdb', {params: {
    query: payload.query, 
    page: payload.page, 
    searchIn: payload.searchIn, 
    adult: payload.adult
  }});
  return res.data;
}
export const getTMDBListItem = async (payload: {id: string, searchIn: SearchTMDBPayload['searchIn']}) => {
  const res = await searchApi.get<AutoPopulateListItem>(`${searchUrl}/tmdb/info/${payload.id}`, {params: {searchIn: payload.searchIn}});
  return res.data;
}

export const searchManga = async (payload: SearchMangaPayload) => {
  const res = await searchApi.get<AutoPopulateListItemResults>(searchUrl + '/manga', {params: {
    query: payload.query, 
    page: payload.page,
    adult: payload.adult
  }});
  return res.data;
}

export const searchBooks = async (payload: SearchBooksPayload) => {
  const res = await searchApi.get<AutoPopulateListItemResults>(searchUrl + '/books', {params: {
    query: payload.query
  }});
  return res.data;
}

export const searchMusic = async (payload: SearchBooksPayload) => {
  const res = await searchApi.get<AutoPopulateListItemResults>(searchUrl + '/music', {params: {
    query: payload.query
  }});
  return res.data;
}