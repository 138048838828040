import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react'
import Modal from '../Modal';
import { AddIcon, ArrowIcon, CheckIcon, CloseIcon, InfoIcon, MinusIcon } from '../../assets/icons';
import { useGetAllUserLists, useGetListById } from '../../api/data-access';
import LoadingIcon from '../shared/LoadingIcon';
import { sortListByPosition } from '../../tools/Helpers';
import { DecoratedListItem, ListItemType } from '../../types/SharedTypes';
import { ActiveIndex, ListItem } from '../../pages/List';
import { CreatorContext } from '../../contexts/CreatorContext';

type Props = {
  setModalVisible: (prev: boolean) => void;
}

const ImportListItemsModal = ({setModalVisible}: Props) => {
  
  const {importBatchItems} = useContext(CreatorContext);
  const {data, isSuccess, isPending} = useGetAllUserLists();
  
  const [selectedList, setSelectedList] = useState<string>();
  const [selectedItems, setSelectedItems] = useState<ListItemType[]>([]);
  
  const handleImportItems = () => {
    if (selectedItems.length === 0) return;
    importBatchItems(selectedItems);
    setModalVisible(false);
  }
  
  if (isPending) return <LoadingIcon />
  else if (!isSuccess) return <p>Error</p>;
  return (
    <Modal 
      controls
      modalTitle='Import List Items'
      scroll 
      setModalVisible={setModalVisible} 
      fixedBottomItem 
      removePadding
      fixedBottomItemChildren={
        <div className='padding-small item-row'>
          <button className='icon' onClick={() => setSelectedList(undefined)}><ArrowIcon /></button>
          <button className='primary full-width' aria-disabled={selectedItems.length === 0} onClick={handleImportItems}>Add Items</button>
        </div>
      }
    >
      <div className="item-list no-gap">
        <div className='item-row no-gap padding-small padding-large-left bottom-border'>
          <div className='icon-display padding-small-horizontal'>
            <InfoIcon />
          </div>
          <p className='body'>
            Imported list items are duplicated and will remain in their original list.
          </p>
        </div>
        {!selectedList && 
          <div className='item-list padding-small'>
            {data.map(list =>
              <button key={list._id} className='empty darkness-hover' onClick={() => setSelectedList(list._id)}>
                <div className="tile item-row padding-small">
                  {list.image &&
                    <div className='thumb'>
                      <img src={list.image} />
                    </div>
                  }
                  <div className='item-list'>
                    <p className="heading-medium weight-light">{list.title}</p>
                    <p className="subtitle-medium secondary">Entries: {list.entriesCount || "Unknown"}</p>
                  </div>
                </div>
              </button>
            )}
          </div>
        }
        {selectedList && <ListItems selectedItems={selectedItems} setSelectedItems={setSelectedItems} listId={selectedList} />}
      </div>
    </Modal>
  )
}

const ListItems = ({listId, selectedItems, setSelectedItems}: {
  listId: string,
  selectedItems: ListItemType[],
  setSelectedItems: Dispatch<SetStateAction<ListItemType[]>>
}) => {
  const {data: list, isSuccess, isPending} = useGetListById(listId, !!listId);
  const onList = useMemo(() => isSuccess && sortListByPosition<ListItemType | DecoratedListItem<ListItemType>>(list.onList, list.ascending), [list]);
  
  const [activeIndex, setActiveIndex] = useState<ActiveIndex>(null);
  
  const toggleItem = (newItem: ListItemType) => {
    if (selectedItems.find(item => item._id === newItem._id)) {
      setSelectedItems(prev => prev.filter(item => item._id !== newItem._id));
    }
    else setSelectedItems(prev => [...prev, newItem]);
  }
    
  if (isPending) return <LoadingIcon />
  else if (!isSuccess || !onList) return <p>Error</p>;
  return (
    <div className='item-list'>
      <div className="list-table">
        {onList.map((l, i) =>
          <ListItem
            key={l._id}
            listItem={l}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            listItemIndex={i}
            ordered={list.ordered}
            noHero
            ascending={list.ascending}
            customControl={
              <button className="icon xs" onClick={(e) => {
                e.stopPropagation();
                toggleItem(l);
              }}>
                {selectedItems.find(item => item._id === l._id) ? <MinusIcon /> : <AddIcon />}
              </button>
            }
          /> 
        )}
      </div>
    </div>
  )
}

export default ImportListItemsModal;
