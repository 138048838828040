import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import { ToastContext } from '../../contexts/ToastContext';
import { CheckIcon, CloseIcon, FlaggedIcon, InfoIcon, ReportIcon } from '../../assets/icons';

const Toast = () => {
  const {toastConfig} = useContext(ToastContext);

  const Icon =
    toastConfig.state === 'success' ? CheckIcon : toastConfig.state === 'error' ? CloseIcon : toastConfig.state === 'warn' ? ReportIcon : InfoIcon;

  if (!toastConfig.visible || !toastConfig.content) return null;
  return ReactDOM.createPortal(
    <div
      className="toast"
      data-error={toastConfig.state === 'error'}
      data-success={toastConfig.state === 'success'}
      data-warn={toastConfig.state === 'warn'}
    >
      <div className="item-row">
        <div className="icon small">
          <Icon />
        </div>
        {toastConfig.content && toastConfig.content}
      </div>
    </div>,
    document.querySelector('#toast-root')!
  );
};

export default Toast;
