const LoadingIcon = ({small}: {small?: boolean}) => {
  return (
    <div className="lds-ring" data-small={small}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
export default LoadingIcon;
