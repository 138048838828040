import { useState } from "react";
import Modal from "../../Modal";
import TabBar from "../../navigation/TabBar";
import { Link } from "react-router-dom";
import { User } from "../../../types/SharedTypes";
import { useGetUserFollows } from "../../../api/data-access";
import LoadingIcon from "../LoadingIcon";
import { CloseIcon } from "../../../assets/icons";

type Props = {
  setModalVisible: (prev: boolean) => void;
  userId: string;
}

const FollowsModal = ({setModalVisible, userId}: Props) => {
  
  const [currentTab, setCurrentTab] = useState<'following' | 'followers'>('following');
  
  const {data, isSuccess} = useGetUserFollows(userId);
  
  const currentFeed = (currentTab === 'followers' ? data?.followers : data?.following) || [];

  return (
    <Modal
      setModalVisible={setModalVisible}
      removePadding
      bottomPane
      fullHeightBottomPane
      scroll
      children={
        <>{!isSuccess ? 
            <div className="item-row center-vertical center-horizontal">
              <LoadingIcon />
            </div>
          :
            <div className="item-list no-gap">
              <div className="padding sticky">
                <TabBar 
                  tabData={[
                    {title: 'Following', onClick: () => setCurrentTab("following")},
                    {title: 'Followers', onClick: () => setCurrentTab("followers")}
                  ]}
                  floatRightElement={
                    <button className="icon xs escape-padding" onClick={() => setModalVisible(false)}>
                      <CloseIcon />
                    </button>
                  }
                  unstyled
                />
              </div>
              <div className="padding-small item-list">
                {currentFeed.map(follow => {
                  const displayUser = currentTab === 'followers' ? follow.user : follow.following;
                  if (typeof displayUser === 'string') return null;
                  return <UserItem key={follow._id} user={displayUser} onClick={() => setModalVisible(false)} />
                })}
              </div>
            </div>
          }
        </>
      }
    />
  )
}

export const UserItem = ({user, onClick}: {user: User, onClick?: () => void}) => {
  return (
    <button className="empty darkness-hover item-list">
      <Link to={`/user/${user.username}`} className="tile padding-small full-width" onClick={onClick}>
        <article className="item-row center-vertical">
          <div className={`user-avatar`}>
            <img src={user?.picture} />
          </div>
          <div className={`item-list`}>
            <div className="item-row">
              <p className={'heading-small'}>{user.displayName}</p>
            </div>
            <p className="subtitle-medium">{`@${user.username}`}</p>
          </div>
        </article>
      </Link>
    </button>
  )
}

export default FollowsModal;