import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { cacheKeys } from "../../constants/cache-keys";
import { createNewMedia, deleteMedia, getUserMedia } from "../mediaApi";
import { createLike, getUserLikes, removeLike } from "../likesApi";
import { createSave, removeSave } from "../savesApi";
import { getTMDBListItem, searchBooks, searchLists, searchManga, searchMusic, searchTMDB, searchUsers } from "../searchApi";
import { ReportPayload, SearchBooksPayload, SearchMangaPayload, SearchTMDBPayload } from "../../types/SharedTypes";
import { reportContent } from "../reportApi";

type Enabled = UseQueryOptions["enabled"];

//media
export const useGetUserMedia = (filterId?: string, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.media, 'user', filterId], queryFn: () => getUserMedia(filterId), enabled
});

export const useCreateMedia = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createNewMedia, 
    onSuccess: () => queryClient.invalidateQueries({
      queryKey: [cacheKeys.media]
    })
  });
};

export const useDeleteMedia = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteMedia, 
    onSuccess: (variables) => variables.success && queryClient.invalidateQueries({
      queryKey: [cacheKeys.media]
    })
  });
}

//likes
export const useGetUserLikes = (enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.likes, 'user'],
  queryFn: getUserLikes,
  enabled
});

export const useCreateLike = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: createLike,
    onSuccess: (_, postId) => {
      queryClient.invalidateQueries({queryKey: [cacheKeys.likes, 'user']});
      queryClient.setQueryData([cacheKeys.lists, postId], (prev) => prev 
        ? {...prev, likedByAuthUser: true} : prev
      )
    }
  });
};
export const useRemoveLike = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: removeLike,
    onSuccess: (_, postId) => {
      queryClient.invalidateQueries({queryKey: [cacheKeys.likes, 'user']});
      queryClient.setQueryData([cacheKeys.lists, postId], (prev) => prev 
        ? {...prev, likedByAuthUser: false} : prev
      )
    }
  });
};

//saves
export const useCreateSave = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: createSave,
    onSuccess: (res) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.lists, "user", 'auto']
        }),
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.lists, res.listId]
        }),
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.lists, res.collectionId]
        })
      ]);
    } 
  });
};
export const useRemoveSave = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: removeSave,
    onSuccess: (res) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.lists, "user", 'auto']
        }),
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.lists, res.listId]
        }),
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.lists, res.collectionId]
        })
      ]);
    } 
  });
};

//report
export const useReportContent = () => {
  return useMutation({
    mutationFn: reportContent,
  });
};

//search
export const useSearchUsers = (payload: {query: string}, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.search, 'users', payload.query],
  queryFn: () => searchUsers(payload),
  enabled
});
export const useSearchLists = (payload: {query: string, category?: string}, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.search, 'lists', payload.query, payload.category],
  queryFn: () => searchLists(payload),
  enabled
});

//movies/tv/anime
export const useSearchTMDB = (payload: SearchTMDBPayload, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.search, 'tmdb', payload.searchIn, payload.query, payload.page],
  queryFn: () => searchTMDB(payload),
  enabled
});
export const useGetTMDBListItem = (payload: {id: string, searchIn: SearchTMDBPayload["searchIn"]}, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.search, 'tmdb', payload.searchIn, payload.id],
  queryFn: () => getTMDBListItem(payload),
  enabled
});

//manga
export const useSearchManga = (payload: SearchMangaPayload, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.search, 'manga', payload.query, payload.page],
  queryFn: () => searchManga(payload),
  enabled
});

//books
export const useSearchBooks = (payload: SearchBooksPayload, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.search, 'books', payload.query],
  queryFn: () => searchBooks(payload),
  enabled
});

//music
export const useSearchMusic = (payload: SearchBooksPayload, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.search, 'music', payload.query],
  queryFn: () => searchMusic(payload),
  enabled
});