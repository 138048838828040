import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { cacheKeys } from "../../constants/cache-keys";
import { followUser, getFollowsByUser, unfollowUser } from "../followsApi";
import { User } from "../../types/SharedTypes";

type Enabled = UseQueryOptions["enabled"];

export const useGetUserFollows = (userId: string, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.follows, userId],
  queryFn: () => getFollowsByUser(userId),
  enabled
});

export const useFollowUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: followUser,
    onSuccess: (user: User) => {
      if (user) return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.follows, user._id]
        }),
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.user, user.username]
        }),
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.user, user._id]
        }),
      ]);
    }
  });
};

export const useUnfollowUser = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: unfollowUser,
    onSuccess: (user: User) => {
      if (user) return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.follows, user._id]
        }),
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.user, user.username]
        }),
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.user, user._id]
        }),
      ]);
    }
  });
};