import { useContext, useEffect } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import { localPrefKeys } from "../constants/general";
import { getLocalPref } from "../tools/Helpers";

const useFullCustomTheme = (theme?: string, useLocalPreferences?: boolean) => {
  
  const {setNewTheme, setFullPage} = useContext(ThemeContext);
  const disableThemeInEditor = getLocalPref(localPrefKeys.disableThemeInEditor)
  
  useEffect(() => {
    if (useLocalPreferences && disableThemeInEditor) return;
    
    setNewTheme(theme || "default");
    setFullPage(true);
  }, [theme]);
  
  useEffect(() => {
    return () => {
      setFullPage(false);
    }
  }, []);
}
export default useFullCustomTheme;