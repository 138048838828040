import { useEffect, useRef } from "react";
import { DecoratedList } from "../../../types/SharedTypes";
import Modal from "../../Modal";
import { InfoIcon, PeakLogo } from "../../../assets/icons";
import TextInput from "../inputs/TextInput";
import { useGenerateListImage } from "../../../hooks/useGenerateListImage";
import { useSpecialThemed } from "../../../hooks/useThemed";
import { basicDateFormat } from "../../../tools/Helpers";
import { getCategory } from "../../../assets/categories";
import { ListItem } from "../../../pages/List";

type Props = {
  list: DecoratedList;
  setModalVisible: (prev: boolean) => void;
}

const ShareListModal = ({list, setModalVisible}: Props) => {

  const {createAndDownload} = useGenerateListImage();
  const themeOverride = useSpecialThemed(list.theme);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const handleShare = async () => {
    
    if (!imageRef.current) return;
    const transform = imageRef.current.style.transform;
    imageRef.current.style.transform = "unset";
    await createAndDownload(
      "sharePoint",
      `https://peaklist.app/list/${list._id}`,
      list.isPublic,
      `${list.title} - ${list.author.username}`,
      list.theme
    );
    imageRef.current.style.transform = transform;
  }

  const scaleToFitScreen = () => {
    if (wrapperRef.current && imageRef.current && parentRef.current) {
      const scaleFactor = (wrapperRef.current.offsetWidth - 28) / imageRef.current.offsetWidth;
      imageRef.current.style.transform = `scale(${scaleFactor})`;
      
      const scaledWidth = imageRef.current.offsetWidth * scaleFactor;
      const scaledHeight = imageRef.current.offsetHeight * scaleFactor;

      parentRef.current.style.width = `${scaledWidth}px`;
      parentRef.current.style.height = `${scaledHeight}px`;
    }
  };

  useEffect(() => {
    scaleToFitScreen();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', scaleToFitScreen);
    return () => window.removeEventListener('resize', scaleToFitScreen);
  }, []);

  const dummyItemProps = {
    preventSave: true,
    activeIndex: null,
    setActiveIndex: () => null,
    ordered: list.ordered,
    ascending: true,
    isAutoGenerated: false,
  }

  const numberOfItemsShown = (list.onList.length > 5 ? 5 : list.onList.length) + (list.ordered ? 0 : 1);

  const firstColCondition = (i: number) => list.ordered
    ? i === 0
    : i < 3
  const secondColCondition = (i: number) => list.ordered
    ? i > 0 && i < 5
    : i > 2 && i < 6

  return (
    <Modal
      setModalVisible={setModalVisible}
      modalTitle="Share"
      scrollRef={wrapperRef}
      removePadding
      scroll
      controls
      children={
        <div className="item-list large padding-small">
          <div className="item-list">
            {!list.isPublic && 
              <div className="tile item-row padding-small" data-warn={true}>
                <div className="icon-display small">
                  <InfoIcon />
                </div>
                <p className="body">This list is not public and cannot be accessed by others.</p>
              </div>
            }
            <p className="subtitle-large weight-bold secondary indent">Share Link</p>
            <TextInput
              id={"copyLink"}
              value={`https://peaklist.app/list/${list._id}`}
              onChange={(val) => null}
              secondary
              copyButton
            />
          </div>
          <div className="item-list">
          <p className="subtitle-large weight-bold secondary indent">Share Image</p>
          <button className="primary" onClick={handleShare}>Download Image</button>
            <div ref={parentRef} className="padding-top-small">
              <div ref={imageRef} className="share-preview border" data-outline={true} style={themeOverride} data-themed={true} id={"sharePoint"}>
                <div className="right-item-layout padding-large">
                  <div className="item-list">
                    <div className="logo">
                      <PeakLogo crossorigin />
                    </div>
                    <p className="subtitle-large secondary">https://peaklist.app</p>
                    <p className="subtitle-large secondary">{basicDateFormat(list.createdAt)}</p>
                  </div>
                </div>
                <div className="padding item-list remove-padding-top">
                  <div className="right-item-layout xl">
                    <div className="item-row">
                      <p className="heading-medium indent ellipsis-block">{list.title}</p>
                      <p className="heading-medium weight-light secondary">@{list.author.username}</p>
                    </div>
                    <div className="item-row padding-right-small">
                      <div className="tag" data-themed={true} data-secondary={true}>
                        <p className="subtitle-medium header">{getCategory(list.category)}</p>
                      </div>
                      <p className="subtitle-large secondary">{numberOfItemsShown}/{list.onList.length} Items</p>
                    </div>
                  </div>
                  <div className="list-table graphic">
                    <div className="item-row no-gap">
                      {list.ordered &&
                        <ListItem
                          {...dummyItemProps}
                          listItem={list.onList[0]}
                          listItemIndex={0}
                        />
                      }
                      {!list.ordered && 
                        <div className="item-list no-gap">
                          {list.onList.map((item, i) => firstColCondition(i) &&
                            <ListItem
                              key={i}
                              {...dummyItemProps}
                              listItem={item}
                              listItemIndex={i}
                            />
                          )}
                        </div>
                      }
                      <div className="item-list no-gap">
                        {list.onList.map((item, i) => secondColCondition(i) &&
                          <ListItem
                            key={i}
                            {...dummyItemProps}
                            listItem={item}
                            listItemIndex={i}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}
export default ShareListModal;