import { useNavigate, useParams } from "react-router";
import PageWrapper from "./PageWrapper";
import { Link, useSearchParams } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useCreateLike, useRemoveLike } from "../api/data-access/generalHooks";
import { SpecialThemeConfig, useSpecialThemed } from "../hooks/useThemed";
import { fallbackImage } from "../constants/urls";
import { getCategory } from "../assets/categories";
import ListFeedCard from "../components/shared/cards/ListFeedCard";
import { CollectionUpdatePayload, DecoratedCollection, ListVisibilityKey, SmartCollection } from "../types/SharedTypes";
import { AddIcon, ArrowHeadLeftIcon, ArrowIcon, ArrowRightIcon, DeleteIcon, DraftIcon, HiddenIcon, ImportIcon, PublishIcon, PublishPersonalIcon, RecommendIcon, SettingsIcon, VisibleIcon } from "../assets/icons";
import { useGetCollectionById, useGetCollectionByIdOpen, useGetPeriodsBySet } from "../api/data-access";
import { UseQueryResult } from "@tanstack/react-query";
import ContextMenu, { ContextMenuContent } from "../components/shared/modals/ContextMenu";
import ThemeIndicator from "../components/createList/ThemeIndicator";
import EditCollectionModal from "../components/listCreator/EditCollectionModal";
import { ThemeModal } from "./ListCreator";
import CreateListModal from "../components/createList/CreateListModal";
import ImportListModal from "../components/listCreator/ImportListModal";
import useHandleUpdateCollection from "../hooks/useHandleUpdateCollection";
import { smartPeriodSingular } from "../constants/general";
import { basicDateFormat, timeUntil } from "../tools/Helpers";
import LoadingIcon from "../components/shared/LoadingIcon";
import Modal from "../components/Modal";

const Collection = ({auth = true}: {auth?: boolean}) => {
  return auth ? <AuthCollectionContent /> : <NoAuthCollectionContent />;
}

const NoAuthCollectionContent = () => {
  const {id} = useParams();
  const collection = useGetCollectionByIdOpen(id || '', undefined);
  
  return <CollectionContent collection={collection} />
}

const AuthCollectionContent = () => {
  const {id} = useParams();
  const [searchParams] = useSearchParams();
  
  const collection = useGetCollectionById(id || '', !!searchParams.get('returnRecent'), undefined);
  
  return <CollectionContent collection={collection} />
}

type ListContentProps = {
  collection: UseQueryResult<DecoratedCollection, Error>;
}

const CollectionContent = ({collection}: ListContentProps) => {

  const [searchParams] = useSearchParams();
  const {authUserId} = useContext(AuthContext);
  
  const [createListVisible, setCreateListVisible] = useState(false);
  const [allPeriodsVisible, setAllPeriodsVisible] = useState(false);
  const [visibilityControlVisible, setVisibilityControlVisible] = useState(false);
  
  const themeOverride = useSpecialThemed(collection.data?.theme || searchParams.get('theme')?.toString() || 'default');
  const isAuth = collection.isSuccess && authUserId === collection.data.author._id;
  
  return (
    <PageWrapper preventOutline fixedHeight>
      {allPeriodsVisible && collection.isSuccess && collection.data.smartCollection &&
        <AllPeriodsModal setModalVisible={setAllPeriodsVisible} smartSet={collection.data?.smartCollection} />
      }
      <CollectionCardWrapper 
        collectionRes={collection} 
        themeOverride={themeOverride} 
        allowEdit={isAuth && !collection.data.isAutoGenerated}
        createListOptionsVisible={createListVisible}
        setCreateListOptionsVisible={setCreateListVisible}
        visibilityControlVisible={visibilityControlVisible}
        setVisibilityControlVisible={setVisibilityControlVisible}
      >
        {collection.isLoading &&
          <div className="item-list center-vertical center-horizontal">
            <LoadingIcon />
          </div>
        }
        {collection.isSuccess &&
          <>
            <div className="item-list large padding-small">
              <div className="right-item-layout padding-top-small">
                <div className="item-list ">
                  <p className="indent subtitle-medium weight-bold">Description</p>
                  <p className="indent body">{collection.data.description}</p>
                </div>
                {isAuth && !collection.data.isAutoGenerated &&
                  <button className="short primary icon-text xs" onClick={() => setVisibilityControlVisible(true)}>
                    {collection.data.isDraft ? <HiddenIcon /> : (collection.data.isPublic ? <PublishIcon /> : <PublishPersonalIcon />)}
                    {<p className="body">{collection.data.isDraft ? "Draft" : collection.data.isPublic ? "Published" : "Saved"}</p>}
                  </button>
                }
              </div>
              <div className="item-list large">
                {collection.data.publishedLists.length > 0 &&
                  <div className="item-list">
                    {collection.data.publishedLists.map(list =>
                      <ListFeedCard key={list._id} list={list} hideHeader small />
                    )}
                  </div>
                }
                {isAuth && collection.data.draftLists && collection.data.draftLists.length > 0 &&
                  <div className="item-list">
                    <p className="indent subtitle-medium weight-bold">Drafts <span className="weight-light secondary">(only visible to you)</span></p>
                    {collection.data.draftLists.map(list =>
                      <ListFeedCard key={list._id} list={list} hideHeader small link={`/creator/${list._id}`} />
                    )}
                  </div>
                }
                {collection.data.publishedLists.length === 0 && (!collection.data.draftLists || collection.data.draftLists.length === 0) &&
                  <p className="indent subtitle-medium secondary center">No lists in collection.</p>
                }
                {isAuth && !collection.data.isAutoGenerated && 
                  <button className="subtle icon-text xxs" onClick={() => setCreateListVisible(true)}>
                    <AddIcon />
                    Add List
                  </button>
                }
              </div>
            </div>
            {collection.data.smartCollection ?
              <div className="item-list padding-small">
                {collection.data.smartCollection && collection.data.smartCollection.renewAt && !collection.data.nextCollection && isAuth &&
                  <p className="subtitle-small secondary center weight-light">
                    {collection.data.smartCollection.isDisabled 
                      ? "Period creation disabled"
                      : `Next period in ${timeUntil(collection.data.smartCollection?.renewAt)}`
                    }
                  </p>
                }
                <div className="item-row large full-width center-horizontal padding-horizontal">
                  <Link 
                    aria-disabled={!collection.data.prevCollection}
                    to={`/collection/${collection.data.prevCollection || collection.data._id}?theme=${collection.data.theme}`}
                    replace
                  >
                    <button className="icon small">
                      <ArrowIcon />
                    </button>
                  </Link>
                  <div className="full-width bottom-border dotted" />
                  <CurrentPeriodButton setVisible={setAllPeriodsVisible} createdAt={collection.data.createdAt} />
                  <div className="full-width bottom-border dotted" />
                  <Link 
                    aria-disabled={!collection.data.nextCollection}
                    to={`/collection/${collection.data.nextCollection || collection.data._id}?theme=${collection.data.theme}`}
                    replace
                  >
                    <button className="icon small">
                      <ArrowRightIcon />
                    </button>
                  </Link>
                </div>
              </div>
              :
              <div className="item-list center-horizontal padding-xs">
                <CurrentPeriodButton createdAt={collection.data.createdAt} />
              </div>
            }
          </>
        }
      </CollectionCardWrapper>
    </PageWrapper>
  )
}

const CurrentPeriodButton = ({setVisible, createdAt}: {setVisible?: (prev: boolean) => void, createdAt: string}) => {
  
  const {authUserId} = useContext(AuthContext);

  if (!setVisible || !authUserId) return (
    <div className="tag prevent-shrink" data-secondary={true} data-small={true} data-themed={true}>
      <p className="subtitle-medium header">{basicDateFormat(createdAt)}</p>
    </div>
  )
  return (
    <button className="empty darkness-hover prevent-shrink" onClick={() => setVisible(true)}>
      <div className="tag prevent-shrink" data-secondary={true} data-small={true} data-themed={true}>
        <p className="subtitle-medium header">{basicDateFormat(createdAt)}</p>
      </div>
    </button>
  )
}

type WrapperProps = {
  children: React.ReactNode;
  themeOverride: SpecialThemeConfig | undefined;
  collectionRes: UseQueryResult<DecoratedCollection, Error>;
  createListOptionsVisible: boolean;
  setCreateListOptionsVisible: (prev: boolean) => void;
  visibilityControlVisible: boolean;
  setVisibilityControlVisible: (prev: boolean) => void;
  updatedCollection?: CollectionUpdatePayload;
  edit?: boolean;
  editToolbar?: React.ReactNode;
  preventLike?: boolean;
  allowEdit?: boolean;
}

export const CollectionCardWrapper = ({
  children, themeOverride, collectionRes, updatedCollection, edit, editToolbar, preventLike,
  allowEdit, createListOptionsVisible, setCreateListOptionsVisible, visibilityControlVisible, setVisibilityControlVisible
}: WrapperProps) => {
  
  const navigate = useNavigate();
  
  const createLike = useCreateLike();
  const removeLike = useRemoveLike();

  const image = updatedCollection?.image || (collectionRes.isSuccess ? collectionRes.data.image : null);
  
  return (
    <div className="card-full-outer">
      <div className="card-full" style={themeOverride} data-themed={true} data-edit={!!edit}>
        {collectionRes.isLoading &&
          <div className="card-header bottom-border" data-has-img={false} />
        }
        {collectionRes.isError &&
          <p className="body secondary">Error</p>
        }
        {collectionRes.isSuccess &&
          <div className="card-header bottom-border" data-has-img={!!image}>
            {image &&
              <div className="card-thumb right-border">
                <img src={image || fallbackImage} />
              </div>
            }
            <div className="item-list large space-around padding-small">
              {!edit && 
                <div className="right-item-layout small align-start">
                  <Link to={`/user/${collectionRes.data.author.username}`} className="min-width">
                    <article className="item-row center-vertical">
                      <div className={`user-avatar`}>
                        <img src={collectionRes.data.author?.picture} />
                      </div>
                      <div className={`item-list small min-width`}>
                        <p className={"subtitle-large weight-bold ellipsis"}>{collectionRes.data.author.displayName}</p>
                        <p className="subtitle-medium ellipsis">{`@${collectionRes.data.author.username}`}</p>
                      </div>
                    </article>
                  </Link>
                  {allowEdit &&
                    <CollectionEditSelection
                      collection={collectionRes.data}
                      createListOptionsVisible={createListOptionsVisible}
                      setCreateListOptionsVisible={setCreateListOptionsVisible}
                      visibilityControlVisible={visibilityControlVisible}
                      setVisibilityControlVisible={setVisibilityControlVisible}
                    />
                  }
                </div>
              }
              <div className={'item-list'}>
                <div className="right-item-layout small">
                  <div className="item-row med">
                    <button className="icon small escape-padding" onClick={() => navigate(-1)}><ArrowHeadLeftIcon /></button>
                    <div className="tag" data-themed={true}>
                      <p className="subtitle-medium header">{
                        getCategory(updatedCollection?.category || collectionRes.data.category, collectionRes.data.isAutoGenerated)
                      }</p>
                    </div>
                    {collectionRes.data.smartCollection && <div className="tag" data-themed={true}>
                      <p className="subtitle-medium header">{collectionRes.data?.smartCollection.period}</p>
                    </div>}
                  </div>
                  {editToolbar && edit && editToolbar}
                </div>
                <p className="heading-large">{updatedCollection?.title || collectionRes.data.title}</p>
              </div>
            </div>
          </div>
        }
        {children}
      </div>
    </div>
  )
}

type CollectionEditSelectionProps = {
  collection: DecoratedCollection;
  createListOptionsVisible: boolean;
  setCreateListOptionsVisible: (prev: boolean) => void;
  visibilityControlVisible: boolean;
  setVisibilityControlVisible: (prev: boolean) => void;
}

const CollectionEditSelection = ({collection, createListOptionsVisible, setCreateListOptionsVisible, visibilityControlVisible, setVisibilityControlVisible}: CollectionEditSelectionProps) => {
  
  const [themeModalVisible, setThemeModalVisible] = useState(false);
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const [createListModalVisible, setCreateListModalVisible] = useState(false);
  const [importModalVisible, setImportModalVisible] = useState(false);
  
  const {updateCollectionSimple, isLoading} = useHandleUpdateCollection();
  
  const relativeTitle = `${smartPeriodSingular(collection.smartCollection?.period, true)} of ${basicDateFormat(collection.createdAt)}`;
  
  const createMenuContent: ContextMenuContent['menuContent'] = [
    {
      title: 'Add New List',
      icon: AddIcon,
      handleClick: () => setCreateListModalVisible(true)
    },
    {
      title: 'Import List',
      icon: ImportIcon,
      handleClick: () => setImportModalVisible(true)
    }
  ];
  
  const contextMenuContent: ContextMenuContent = {
    focusedItem: {
      title: collection.title,
      image: collection.image || fallbackImage,
      subtitle: relativeTitle
    },
    title: `Edit Collection`,
    menuContent: [
      {
        title: collection.isDraft
          ? `Publish Current ${smartPeriodSingular(collection.smartCollection?.period, true)} as ${collection.isPublic ? "Public" : "Personal"}`
          : `Revert Current ${smartPeriodSingular(collection.smartCollection?.period, true)} to Draft`,
        handleClick: () => handleUpdateVisibility(collection.isDraft ? "publish" : "draft"),
        promptLoading: true,
        icon: collection.isDraft ? collection.isPublic ? PublishIcon : PublishPersonalIcon : DraftIcon,
        visible: !!collection.smartCollection
      },
      {
        title: collection.isDraft ? `Publish as ${collection.isPublic ? "Public" : "Personal"}` : "Revert to Draft",
        handleClick: () => handleUpdateVisibility(collection.isDraft ? "publish" : "draft"),
        promptLoading: true,
        icon: collection.isDraft ? collection.isPublic ? PublishIcon : PublishPersonalIcon : DraftIcon,
        visible: !collection.smartCollection
      }
    ]
  };
  
  const handleUpdateTheme = async (theme: string) => {
    const res = await updateCollectionSimple({theme}, collection._id);
    if (res) setThemeModalVisible(false);
  }

  const handleUpdateVisibility = async (visibility: ListVisibilityKey) => {
    await updateCollectionSimple({isDraft: visibility === 'draft'}, collection._id);
    setVisibilityControlVisible(false);
  }
  
  return (
    <>
      {createListOptionsVisible && 
        <ContextMenu setModalVisible={setCreateListOptionsVisible} menuData={{
          ...contextMenuContent, 
          menuContent: createMenuContent
        }} />
      }
      {visibilityControlVisible &&
        <ContextMenu setModalVisible={setVisibilityControlVisible} menuData={contextMenuContent} />
      }
      {createListModalVisible && <CreateListModal setModalVisible={setCreateListModalVisible} forCollection={collection._id} />}
      {importModalVisible && <ImportListModal collectionId={collection._id} setModalVisible={setImportModalVisible} />}
      {settingsModalVisible && collection && 
        <EditCollectionModal
          setModalVisible={setSettingsModalVisible}
          collection={collection}
        />
      }
      {themeModalVisible && 
        <ThemeModal
          setModalVisible={setThemeModalVisible}
          updateTheme={() => null}
          currentThemeSlug={collection?.theme}
          handleSave={handleUpdateTheme}
          isLoading={isLoading}
        />
      }
      <div className="item-row">
        <div className="item-row small">
          <button className="icon" onClick={() => setThemeModalVisible(true)}>
            <ThemeIndicator themeSlug={collection.theme} />
          </button>
          <button className="icon" onClick={() => setSettingsModalVisible(true)}>
            <SettingsIcon />
          </button>
        </div>
      </div>
    </>
  )
}

const AllPeriodsModal = ({smartSet, setModalVisible}: {smartSet: SmartCollection, setModalVisible: (prev: boolean) => void}) => {
  
  const {data, isSuccess, isLoading} = useGetPeriodsBySet(smartSet._id);
  
  return (
    <Modal
      setModalVisible={setModalVisible}
      modalTitle={`All Periods in Set`}
      controls
      removePadding
      isLoading={isLoading}
      scroll
    >
      <div className="item-list no-gap">
        {isSuccess && data.map(item =>
          <button key={item._id} className="empty item-list">
            <Link
              to={`/collection/${item._id}?theme=${item.theme}`}
              className="bottom-border secondary-border padding item-row large full-width"
              onClick={() => setModalVisible(false)}
            >
              <div className="thumb small square">
                <img src={item.image || fallbackImage} />
              </div>
              <div className="item-list med">
                <div className="item-row large">
                  <p className="body ellipsis">{`${smartPeriodSingular(smartSet.period, true)} of ${basicDateFormat(item.createdAt)}`}</p>
                  {item.isDraft && 
                    <div className="icon xsmall" title={"Draft"}>
                      <HiddenIcon />
                    </div>
                  }
                  <div className="item-row">
                    {!item.isDraft &&
                      <div className="icon xsmall" title={item.isPublic ? "Published" : "Published Personal"}>
                        {item.isPublic ? <PublishIcon /> : <PublishPersonalIcon /> }
                      </div>
                    }
                    {!item.originalCollection && 
                      <div className="icon xsmall" title={"Original"}>
                        <RecommendIcon />
                      </div>
                    }
                  </div>
                </div>
                <p className="body secondary ellipsis">{`${item.entriesCount} Published Lists`}</p>
              </div>
            </Link>
          </button>
        )}
      </div>
    </Modal>
  )
}

export default Collection;