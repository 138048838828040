import { CollectionPeriods } from "../types/SharedTypes";

export const collectionPeriods: {value: CollectionPeriods, label: string}[] = [
  {value: 'weekly', label: "Weekly"},
  {value: 'monthly', label: "Monthly"},
  {value: 'seasonal', label: "Seasonal"},
  {value: 'yearly', label: "Yearly"}
]

export const getMaxKeyForPeriod = (period: CollectionPeriods) => {
  if (period === 'weekly') return 7;
  else if (period === 'monthly') return 28;
  else if (period === 'seasonal') return 1;
  else if (period === 'yearly') return 365;
  else return 1;
}