import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { cacheKeys } from "../../constants/cache-keys";
import { getAuthUser, getUserById, getUserByUsername, getUserByUsernameOpen, getUserData, updateUserInfo, updateUserPinned } from "../userApi";
import { useEffect } from "react";
import { User } from "../../types/SharedTypes";

type Enabled = UseQueryOptions["enabled"];

//OPEN
export const useGetUserByUsernameOpen = (username: string, enabled?: Enabled) => {  
  const query = useQuery({
    queryKey: [cacheKeys.user, username], 
    queryFn: () => getUserByUsernameOpen(username),
    enabled
  });
  
  return query;
};

//AUTH
export const useGetAuthUser = (enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.user, "auth"], 
  queryFn: getAuthUser, 
  enabled
});

//when a user is fetched by one param, it will automatically update the other (id vs username)
export const useGetUserById = (id: string, enabled?: Enabled) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [cacheKeys.user, id], 
    queryFn: () => getUserById(id),
    enabled
  });
  
  useEffect(() => {
    if (query.data) {
      queryClient.setQueryData([cacheKeys.user, query.data.username], query.data);
    }
  }, [query.data]);
  
  return query;
};
export const useGetUserByUsername = (username: string, enabled?: Enabled) => {
  const queryClient = useQueryClient();
  
  const query = useQuery({
    queryKey: [cacheKeys.user, username], 
    queryFn: () => getUserByUsername(username),
    enabled
  });
  
  useEffect(() => {
    if (query.data) {
      queryClient.setQueryData([cacheKeys.user, query.data._id], query.data);
    }
  }, [query.data]);
  
  return query;
};

export const useUpdateUserInfo = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUserInfo,
    onSuccess: (user: User) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.user, 'auth']
        }),
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.user, user._id]
        }),
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.user, user.username]
        })
      ]);
    }
  });
};

export const useUpdateUserPinned = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: updateUserPinned,
    onSuccess: (user: User) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.user, 'auth']
        }),
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.user, user._id]
        }),
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.user, user.username]
        })
      ]);
    }
  })
};

export const useGetUserData = () => {  
  return useMutation({
    mutationFn: getUserData,
  });
};