import { baseAPIUrl } from "../constants/urls";
import { DeleteMediaResponse, Media } from "../types/SharedTypes";
import createAxios from "./authInterceptor";

const mediaUrl = `${baseAPIUrl}/media`;

const mediaApi = createAxios;

export const getUserMedia = async (filterId?: string) => {
  const res = await mediaApi.get<Media[]>(`${mediaUrl}/user`, {params: {filterId}});
  return res.data;
}

export const createNewMedia = async (media: FormData) => {
  const res = await mediaApi.post<Media>(mediaUrl + "/create", media, {headers: {'Content-Type': 'multipart/form-data'}});
  return res.data;
}

export const deleteMedia = async ({mediaId, force}: {mediaId: string, force?: boolean}) => {
  const res = await mediaApi.delete<DeleteMediaResponse>(`${mediaUrl}/${mediaId}`, {params: {force}});
  return res.data;
}