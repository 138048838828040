import React, {createContext, useEffect, useState} from "react";
import useAuthentication from "../hooks/useAuthentication";

type AuthContextValue = {
  authUserId: string | undefined
}

export const AuthContext = createContext<AuthContextValue>({
  authUserId: undefined
});

export const clearLocalAuth = () => {
  localStorage.removeItem("user");
  window.location.assign('/');
}

export const AuthContextProvider = ({children}: {children: React.ReactNode}) => {
  
  const {isAuthenticated, getIdTokenClaims} = useAuthentication();
  
  const [authUserId, setAuthId] = useState<string>();
  
  useEffect(() => {
    const get = async () => {
      if (process.env.REACT_APP_DEV === "true") {
        setAuthId(process.env.REACT_APP_DEVID);
      } else {
        const value = await getIdTokenClaims();
        if (!value) return;
        const userId = value["https://peaklist.app/mongoUserId"];
        setAuthId(userId);
      }
    }
    isAuthenticated && get();
  }, [isAuthenticated, getIdTokenClaims]);
  
  
  return (
    <AuthContext.Provider value={{
      authUserId
    }}>
      {children}
    </AuthContext.Provider>
  )
}