import Modal from "../../Modal";

type Props = {
  closeModal: () => void;
  imageUrl?: string;
  title?: string;
  children?: React.ReactNode;
  closeText?: string
  actionButton?: React.ReactNode;
}

const LightboxModal = ({closeModal, children, closeText, imageUrl, title, actionButton}: Props) => {
  return (
    <Modal 
      fullscreen
      children={
        <div className="bottom-item-layout no-gap full-height min-height">
          <div className="padding-small min-height">
            {children ? children : 
              <div className="item-list center-vertical full-height center-horizontal">
                <img className="pretty" src={imageUrl} />
                <p className="body indent secondary">{title}</p>
              </div>
            }
          </div>
          <div className="item-list padding-small">
            {actionButton && actionButton}
            <button className="secondary" onClick={closeModal}>{closeText || "Close"}</button>
          </div>
        </div>
      }
    />
  )
}
export default LightboxModal;