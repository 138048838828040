import { useState } from "react";
import { FeedFilterOptions, useGetUserPublicPosts } from "../../api/data-access";
import LoadingIcon from "../../components/shared/LoadingIcon";
import FeedCard from "../../components/shared/cards/FeedCard";
import { UserListsFilters } from "../UserLists";

const PublicTab = () => {
  
  const [filters, setFilters] = useState<FeedFilterOptions>({
    category: undefined,
    type: undefined,
    period: undefined
  });

  const {data, isSuccess} = useGetUserPublicPosts(filters);
  
  return (
    <section data-padding-small={true}>
      <div className="item-list xl">
        <UserListsFilters filters={filters} setFilters={setFilters} />
        <div className="item-list large">
          <p className="indent subtitle-large">Your Public Collections and Lists</p>
          {isSuccess
            ? <div className="item-list">
              {data.map((l) => 
                <FeedCard key={l._id} post={l} small returnRecentInSmartSet />
              )}
            </div>
            :
            <div className="item-row center-horizontal padding">
              <LoadingIcon />
            </div>
          }
          {isSuccess && data.length === 0 && 
            <p className="subtitle-large secondary center">
              Nothing to show
            </p>
          }
        </div>
      </div>
    </section>
  )
}
export default PublicTab;