import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { cacheKeys } from "../../constants/cache-keys";
import { createNewList, getPostFeed, getPublicPostsByUser, getUserDraftPosts, getUserPrivatePosts, getUserPublicPosts, createNewCollection, updateCollection, updateList, getListById, getCollectionById, getUserAutoPosts, getPopularPostsByUser, getAllUserLists, getPostFeedOpen, getListByIdOpen, getCollectionByIdOpen, getFollowingPostFeed, deleteList, updatePostVisibility, revertPostToDraft, importListsToColleciton, getListsToImportForCollection, createNewSmartCollection, updateCollectionsInSet, updateSmartCollection, getPeriodsBySet, getPopularPostsByUserOpen } from "../listsApi";
import { Categories, CollectionPeriods, DecoratedFeedListPostType, ListPostTypes, PopulatedListPostType } from "../../types/SharedTypes";
import { useInfiniteQueryWrapper } from "./helperHooks";

type Enabled = UseQueryOptions["enabled"];

const getFilterCacheKeys = (filters?: FeedFilterOptions) => {
  if (!filters) return {category: "all", type: "all", period: "all"};
  return {category: filters?.category || 'all', type: filters?.type || 'all', period: filters.period || 'all'};
}

//OPEN
export const useGetPostFeedOpen = (filter?: FeedFilterOptions, enabled?: Enabled) => useInfiniteQueryWrapper<PopulatedListPostType>({
  queryKey: [cacheKeys.lists, filter?.category || 'all'],
  queryFn: getPostFeedOpen,
  extraQueryFnParams: {category: filter?.category},
  pageSize: 10,
  enabled
});

export const useGetListByIdOpen = (id: string, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.lists, id], queryFn: () => getListByIdOpen(id), enabled
});
export const useGetCollectionByIdOpen = (id: string, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.lists, id], queryFn: () => getCollectionByIdOpen(id), enabled
});
export const useGetPopularPostsByUserOpen = (userId: string, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.lists, userId, 'popular'], queryFn: () => getPopularPostsByUserOpen(userId), enabled
});

export type FeedFilterOptions = {category?: Categories, type?: ListPostTypes, period?: CollectionPeriods};

//AUTH
export const useGetPostFeed = (filters?: FeedFilterOptions, enabled?: Enabled) => useInfiniteQueryWrapper<DecoratedFeedListPostType>({
  queryKey: [cacheKeys.lists, 'all', getFilterCacheKeys(filters)],
  queryFn: getPostFeed,
  extraQueryFnParams: {filters},
  pageSize: 10,
  enabled
});
export const useGetFollowingPostFeed = (filters?: FeedFilterOptions, enabled?: Enabled) => useInfiniteQueryWrapper<DecoratedFeedListPostType>({
  queryKey: [cacheKeys.lists, 'following', getFilterCacheKeys(filters)],
  queryFn: getFollowingPostFeed,
  extraQueryFnParams: {filters},
  pageSize: 10,
  enabled
});

export const useGetUserPublicPosts = (filters?: FeedFilterOptions, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.lists, "user", "public", getFilterCacheKeys(filters)],
  queryFn: () => getUserPublicPosts(filters),
  enabled
});
export const useGetUserPrivatePosts = (filters?: FeedFilterOptions, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.lists, "user", "private", getFilterCacheKeys(filters)],
  queryFn: () => getUserPrivatePosts(filters),
  enabled
});
export const useGetUserDraftPosts = (filters?: FeedFilterOptions, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.lists, "user", "draft", getFilterCacheKeys(filters)],
  queryFn: () => getUserDraftPosts(filters),
  enabled
});
export const useGetUserAutoPosts = (enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.lists, "user", 'auto'], queryFn: getUserAutoPosts, enabled
});
export const useGetAllUserLists = (enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.lists, "user"], queryFn: getAllUserLists, enabled
});
export const useGetPeriodsBySet = (setId: string, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.lists, 'periods', setId], queryFn: () => getPeriodsBySet(setId), enabled
});

export const useGetPublicPostsByUser = (userId: string, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.lists, userId], queryFn: () => getPublicPostsByUser(userId), enabled
});
export const useGetPopularPostsByUser = (userId: string, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.lists, userId, 'popular'], queryFn: () => getPopularPostsByUser(userId), enabled
});

export const useGetListsToImportForCollection = (postId: string, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.lists, postId, 'imports'], queryFn: () => getListsToImportForCollection(postId), enabled
});

export const useGetListById = (id: string, enabled?: Enabled) => useQuery({
  queryKey: [cacheKeys.lists, id],
  queryFn: () => getListById(id),
  enabled
});
export const useGetCollectionById = (id: string, returnRecent?: boolean, enabled?: Enabled) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: returnRecent ? [cacheKeys.lists, "recent", id] : [cacheKeys.lists, id],
    queryFn: async () => {
      const res = await getCollectionById(id, returnRecent);
      queryClient.setQueryData([cacheKeys.lists, res._id], res);
      return res;
    },
    enabled
  });
}

export const useCreateNewList = () => {

  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: createNewList,
    onSuccess: (res, variables) => {
      if (variables.parentCollection) {
        return queryClient.invalidateQueries({
          queryKey: [cacheKeys.lists, variables.parentCollection]
        })
      }
      if (variables?.isDraft || !variables?.isPublic) {
        return queryClient.invalidateQueries({
          queryKey: [cacheKeys.lists, "user"]
        })
      }
      return queryClient.invalidateQueries({
        queryKey: [cacheKeys.lists]
      })
    }
  });
};
export const useUpdateList = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateList,
    onSuccess: (result, variables) => {
      //if list is private, we dont need to invalidate all lists
      if (result.parentCollection) {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: [cacheKeys.lists, variables.id]
          }),
          queryClient.invalidateQueries({
            queryKey: [cacheKeys.lists, result.parentCollection]
          })
        ]);
      }
      if (variables.update.info?.isDraft || !variables.update.info?.isPublic) {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: [cacheKeys.lists, variables.id]
          }),
          queryClient.invalidateQueries({
            queryKey: [cacheKeys.lists, "user"]
          })
        ]);
      }
      return queryClient.invalidateQueries({
        queryKey: [cacheKeys.lists]
      })
    }
  });
};

export const useCreateNewCollection = () => {

  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: createNewCollection,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [cacheKeys.lists, "user"]
      })
    }
  })
};
export const useUpdateCollection = () => {

  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: updateCollection,
    onSuccess: (result, variables) => {
      if (variables.update?.isDraft || !variables.update?.isPublic) {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: [cacheKeys.lists, variables.id]
          }),
          queryClient.invalidateQueries({
            queryKey: [cacheKeys.lists, "user"]
          }),
          result?.originalCollection
            ? queryClient.invalidateQueries({
              queryKey: [cacheKeys.lists, 'recent', result.originalCollection]
            }) : undefined,
          result.smartCollection
            ? queryClient.invalidateQueries({
              queryKey: [cacheKeys.lists, 'periods', result.smartCollection]
            }) : undefined
        ]);
      }
      return queryClient.invalidateQueries({
        queryKey: [cacheKeys.lists]
      })
    }
  });
};
export const useUpdateCollectionsInSmartSet = () => {

  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: updateCollectionsInSet,
    onSuccess: (result, variables) => {
      return queryClient.invalidateQueries({
        queryKey: [cacheKeys.lists]
      })
    }
  });
};

export const useCreateSmartCollection = () => {
  return useMutation({
    mutationFn: createNewSmartCollection
  })
};

export const useUpdateSmartCollection = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateSmartCollection,
    onSuccess: () => queryClient.invalidateQueries({
      queryKey: [cacheKeys.lists]
    })
  })
};

export const useUpdatePostVisibility = () => {

  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: updatePostVisibility,
    onSuccess: () => queryClient.invalidateQueries({
      queryKey: [cacheKeys.lists]
    })
  });
};

export const useRevertPostToDraft = () => {

  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: revertPostToDraft,
    onSuccess: () => queryClient.invalidateQueries({
      queryKey: [cacheKeys.lists]
    })
  });
};

export const useImportListsToCollection = () => {

  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: importListsToColleciton,
    onSuccess: () => queryClient.invalidateQueries({
      queryKey: [cacheKeys.lists]
    })
  });
};

export const useDeleteList = () => {

  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: deleteList,
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.lists]
        }),
        queryClient.invalidateQueries({
          queryKey: [cacheKeys.user] //invalidate users to update their pinned
        })
      ]);
      
    }
  });
};